import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './DeliveryAreaSelect.module.css'
import { Dropdown, Menu, Tag } from 'antd'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { usePrevious } from '../../hooks/usePrevious'
import { RightOutlined, CloseCircleFilled } from '@ant-design/icons'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import axios from 'axios'

const DeliveryAreaSelect = ({
	title,
	deliveryPartnerId,
	value,
	onChange,
	disabled,
	style,
	address,
	popupContainer
}) => {
	const [, setIsLoadingDeliveryAreaSuggestion] = useState(false)
	const [areaSearchTerm, setAreaSearchTerm] = useState('')
	const debouncedAreaSearchTerm = useDebounceValue(areaSearchTerm, 750)
	const [, setIsLoadingDeliveryAreaSuggestions] = useState(false)
	const [deliveryCitySuggestions, setDeliveryCitySuggestions] = useState([])
	const [selectedCity, setSelectedCity] = useState(value?.city || null)
	const [selectedZone, setSelectedZone] = useState(value?.zone || null)
	const [selectedArea, setSelectedArea] = useState(value?.area || null)
	const previousValue = usePrevious(value)
	const initialAreaRef = useRef()
	const firstRunRef = useRef(true)
	let controller = new AbortController()

	useEffect(() => {
		if (deliveryPartnerId) {
			getDeliveryAreaSuggestions()
		}
	}, [deliveryPartnerId])

	useEffect(() => {
		getDeliveryAreaSuggestions()
	}, [selectedArea, selectedZone, selectedCity])

	useEffect(() => {
		initialAreaRef.current = value
		if (
			previousValue?.city?.value !== value?.city?.value
			|| previousValue?.zone?.value !== value?.zone?.value
			|| previousValue?.area?.value !== value?.area?.value
		) {
			setSelectedCity(value?.city || null)
			setSelectedZone(value?.city ? value?.zone || null : null)
			setSelectedArea(value?.zone ? value?.area || null : null)
		}
	}, [value])

	useEffect(() => {
		// Prevent running on first render in the scenario that we are editing an order that has a custom delivery area set.
		if (firstRunRef.current) {
			firstRunRef.current = false
			if (initialAreaRef.current) {
				return
			}
		}
		if (address) {
			getDeliveryAreaSuggestion()
		}
	}, [address, deliveryPartnerId])

	useEffect(() => {
		if (debouncedAreaSearchTerm) {
			if (!debouncedAreaSearchTerm?.trim() || debouncedAreaSearchTerm.length < 3) {
				getDeliveryAreaSuggestions()
				return
			}
			getDeliveryAreaSuggestions(debouncedAreaSearchTerm)
		} else {
			getDeliveryAreaSuggestions()
		}
	}, [debouncedAreaSearchTerm])

	const getSuggestionValue = (suggestion) => {
		let value = ''
		if (suggestion.city_id) {
			value += suggestion.city_id
		}
		if (suggestion.zone_id) {
			value += `-${suggestion.zone_id}`
		}
		if (suggestion.area_id) {
			value += `-${suggestion.area_id}`
		}
		return value
	}

	const getDeliveryAreaSuggestion = async () => {
		try {
			if (!address) {
				return
			}
			if (!deliveryPartnerId) {
				return
			}
			setIsLoadingDeliveryAreaSuggestion(true)
			const { data } = await DeliveryPartners.getDeliveryAreaSuggestion(deliveryPartnerId, address)
			onApplyDeliveryAreaSuggestion(data)
		} catch (e) {
			console.error(e)
		} finally {
			setIsLoadingDeliveryAreaSuggestion(false)
		}
	}

	const onApplyDeliveryAreaSuggestion = (deliveryAreaSuggestion) => {
		if (!deliveryAreaSuggestion) {
			return
		}
		let city = null
		if (deliveryAreaSuggestion.district_id) {
			city = { value: deliveryAreaSuggestion?.district_id, label: deliveryAreaSuggestion?.district_name }
			setSelectedCity(city)
		} else {
			setSelectedCity(null)
		}
		let zone = null
		if (deliveryAreaSuggestion.zone_id) {
			zone = { value: deliveryAreaSuggestion?.zone_id, label: deliveryAreaSuggestion?.zone_name }
			setSelectedZone(zone)
		} else {
			setSelectedZone(null)
		}
		let area = null
		if (deliveryAreaSuggestion.area_id) {
			area = { value: deliveryAreaSuggestion?.area_id, label: deliveryAreaSuggestion?.area_name }
			setSelectedArea(area)
		} else {
			setSelectedArea(null)
		}

		onChange({
			city,
			zone,
			area
		})
	}

	const getDeliveryAreaSuggestions = async (searchTerm) => {
		try {
			if (!deliveryPartnerId) {
				return
			}

			controller.abort()
			controller = new AbortController()

			setIsLoadingDeliveryAreaSuggestions(true)
			const { data } = await DeliveryPartners.getDeliveryAreaSuggestions(deliveryPartnerId, {
				searchTerm,
				cityId: selectedCity?.value,
				zoneId: selectedZone?.value
			}, controller.signal)
			const formattedData = data.map(datum => {
				return {
					city_id: selectedCity?.value,
					city_name: selectedCity?.label,
					zone_id: selectedZone?.value,
					zone_name: selectedZone?.label,
					...datum
				}
			})
			setDeliveryCitySuggestions(formattedData)
		} catch (e) {
			if (!axios.isCancel(e)) {
				setDeliveryCitySuggestions([])
			}
			console.error(e)
		} finally {
			setIsLoadingDeliveryAreaSuggestions(false)
		}
	}

	const onAreaSelected = (suggestion) => {
		let selectedCity = null
		if (suggestion.city_id) {
			selectedCity = { value: suggestion.city_id, label: suggestion.city_name }
			setSelectedCity(selectedCity)
		}
		let selectedZone = null
		if (suggestion.zone_id) {
			selectedZone = { value: suggestion.zone_id, label: suggestion.zone_name }
			setSelectedZone(selectedZone)
		}
		let selectedArea = null
		if (suggestion.area_id) {
			selectedArea = { value: suggestion.area_id, label: suggestion.area_name }
			setSelectedArea(selectedArea)
		}

		onChange({
			city: selectedCity,
			zone: selectedZone,
			area: selectedArea
		})
		setAreaSearchTerm('')
	}

	const onClear = () => {
		setSelectedCity(null)
		setSelectedZone(null)
		setSelectedArea(null)
		onChange({
			city: null,
			zone: null,
			area: null
		})
	}

	const renderAreaSuggestions = () => {
		return (
			<Menu className={styles.suggestions}>
				{
					deliveryCitySuggestions.map((suggestion) => {
						const value = getSuggestionValue(suggestion)
						return (
							<Menu.Item
								key={value}
								onClick={() => onAreaSelected(suggestion)}
							>
								<div className={styles.deliveryAreaOption}>
									{
										suggestion.city_id &&
										<div className={styles.deliveryAreaOptionValue}>
											{suggestion.city_name}
										</div>
									}
									{
										suggestion.zone_id &&
										<div className={styles.deliveryAreaOptionValue}>
											{
												suggestion.city_id &&
												<RightOutlined />
											}
											{suggestion.zone_name}
										</div>
									}
									{
										suggestion.area_id &&
										<div className={styles.deliveryAreaOptionValue}>
											{
												suggestion.zone_id &&
												<RightOutlined />
											}
											{suggestion.area_name}
										</div>
									}
								</div>
							</Menu.Item>
						)
					})
				}
			</Menu>
		)
	}

	return (
		<div>
			<div className={styles.deliverySelectContainer} style={style}>
				{
					title ?
						<div className={styles.title}>{title}</div> : null
				}
				<Dropdown
					overlay={renderAreaSuggestions()}
					trigger={['click']}
					getPopupContainer={popupContainer ? popupContainer : () => document.querySelector(`.${styles.deliverySelectContainer}`)}
					placement='bottomCenter'
					disabled={disabled}
				>
					<div className={styles.selectsContainer}>
						<div
							className={styles.inputContainer}
						>
							{
								selectedCity && selectedCity?.value &&
								<Tag
									style={{ marginRight: 0 }}
									closable
									onClose={() => {
										setSelectedCity(null)
										setSelectedZone(null)
										setSelectedArea(null)
										onChange({
											city: null,
											zone: null,
											area: null
										})
									}}
								>
									{selectedCity.label}
								</Tag>
							}
							{
								selectedZone && selectedZone?.value &&
								<RightOutlined />
							}
							{
								selectedZone && selectedZone?.value &&
								<Tag
									style={{ marginRight: 0 }}
									closable
									onClose={() => {
										setSelectedZone(null)
										setSelectedArea(null)
										onChange({
											city: selectedCity,
											zone: null,
											area: null
										})
									}}
								>
									{selectedZone.label}
								</Tag>
							}
							{
								selectedArea && selectedArea?.value &&
								<RightOutlined />
							}
							{
								selectedArea && selectedArea?.value &&
								<Tag
									style={{ marginRight: 0 }}
									closable
									onClose={() => {
										setSelectedArea(null)
										onChange({
											city: selectedCity,
											zone: selectedZone,
											area: null
										})
									}}
								>
									{selectedArea.label}
								</Tag>
							}
							<input
								className={styles.input}
								onChange={(e) => setAreaSearchTerm(e.target.value)}
								value={areaSearchTerm}
								disabled={disabled}
							/>
							{
								selectedCity || selectedZone || selectedArea ?
									<div
										className={styles.clearIcon}
										onClick={onClear}
									>
										<CloseCircleFilled />
									</div> : null
							}
						</div>
					</div>
				</Dropdown>
			</div>
		</div>
	)
}

DeliveryAreaSelect.propTypes = {
	title: PropTypes.node,
	deliveryPartnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	value: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	address: PropTypes.string,
	popupContainer: PropTypes.func
}

DeliveryAreaSelect.defaultProps = {
	title: '',
	deliveryPartnerId: null,
	value: null,
	onChange: () => {},
	disabled: false,
	style: {},
	address: null,
	popupContainer: null
}

export default DeliveryAreaSelect
