import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Tag, notification } from 'antd'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import useSearchParams from '../../hooks/useSearchParams'
import { customerTypes, roles, sources as sourcesOptions } from '../../utils/constants'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import CustomSelectSecondary from '../custom-select-secondary'
import DropdownOverlay from '../dropdown-overlay'
import Input from '../input'
import moment from 'moment'
import styles from './ECommerceCustomerFilter.module.css'
import { fetchCustomerTags } from '../../store/distributors/actions'
import { Users } from '../../services/api/users'
import NumberInput from '../number-input'
import { Distributors } from '../../services/api/distributors'
import { isNotEmpty } from '../../utils'
import { Products } from '../../services/api/products'

const ECommerceCustomerFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const dispatch = useDispatch()
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter, isSearchApplied } = useSearchParams()
	const { permissions } = useSelector(state => state.authReducer)
	const [minOrderFrequency, setMinOrderFrequency] = useState(searchParams.minOrderFrequency)
	const [maxOrderFrequency, setMaxOrderFrequency] = useState(searchParams.maxOrderFrequency)
	const [minSmsCount, setMinSmsCount] = useState(searchParams.minSmsCount)
	const [maxSmsCount, setMaxSmsCount] = useState(searchParams.maxSmsCount)
	const [minSalesAmount, setMinSalesAmount] = useState(searchParams.minSalesAmount)
	const [maxSalesAmount, setMaxSalesAmount] = useState(searchParams.maxSalesAmount)
	const [filteredDistrict, setFilteredDistrict] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [tag, setTag] = useState(searchParams.customerTagId)
	const [creationFromDate, setCreationFromDate] = useState(searchParams.creationFromDate)
	const [creationToDate, setCreationToDate] = useState(searchParams.creationToDate)
	const [lastOrderFromDate, setLastOrderFromDate] = useState(searchParams.lastOrderFromDate)
	const [lastOrderToDate, setLastOrderToDate] = useState(searchParams.lastOrderToDate)
	const [noOrderSinceDate, setNoOrderSinceDate] = useState(searchParams.noOrderSinceDate)
	const [sources, setSources] = useState(searchParams.sources || [])
	const [isCustomerTagOptionsLoading, setIsCustomerTagOptionsLoading] = useState(false)
	const [customerTagOptions, setCustomerTagOptions] = useState([])
	const [isDistrictsLoading, setIsDistrictsLoading] = useState(false)
	const [districts, setDistricts] = useState([])
	const [isLoadingAccountManagers, setIsLoadingAccountManagers] = useState(false)
	const [accountManagers, setAccountManagers] = useState([])
	const [accountManager, setAccountManager] = useState(null)
	const [products, setProducts] = useState([])
	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState(searchParams.productId ? { value: searchParams.productId } : null)

	useEffect(() => {
		if (!isFilterDropdownOpen) {
			return
		}
		getDistricts()
		getAccountManagers()
		getProducts()
		if (permissions.viewCustomerTags) {
			getCustomerTags()
		}
	}, [isFilterDropdownOpen, permissions])

	useEffect(() => {
		if (shouldResetFilter) {
			resetFilter()
		}
	}, [shouldResetFilter])

	const getProducts = async () => {
		setIsLoadingProducts(true)
		const { data } = await Products.index({ })
		setProducts(data.results)
		setIsLoadingProducts(false)
	}

	const searchProducts = value => {
		return Products.index({ searchTerm: value })
	}

	const getAccountManagers = async () => {
		setIsLoadingAccountManagers(true)
		try {
			const { data } = await Users.index({ role: roles.SALES_MANAGER })
			setAccountManagers(data?.results?.length ? data.results : [])
			if (searchParams.accountManagerId && data?.results?.length) {
				const accountManager = data.results.find(({ id }) => id === searchParams.accountManagerId)
				setAccountManager(
					accountManager ?
						{
							key: accountManager.id,
							value: accountManager.id,
							label: accountManager.name,
							data: accountManager
						} :
						null
				)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Get Account Managers',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAccountManagers(false)
		}
	}

	const getDistricts = async () => {
		setIsDistrictsLoading(true)
		try {
			const { data } = await Distributors.districts()
			setDistricts(data?.length ? data.map(result => ({ value: result, label: result })) : [])
			if (searchParams.district && data?.length) {
				const filteredDistrict = data.find(result => result === searchParams.district)
				setFilteredDistrict(
					filteredDistrict ?
						{
							key: filteredDistrict,
							value: filteredDistrict,
							label: filteredDistrict,
							data: filteredDistrict
						} :
						null
				)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Get Districts',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsDistrictsLoading(false)
		}
	}

	const getCustomerTags = async () => {
		setIsCustomerTagOptionsLoading(true)
		try {
			const customerTags = await dispatch(fetchCustomerTags({ includeDefaultTags: true }))
			const customerTagOptions = customerTags?.map(({ id, name }) => ({ value: id, label: name })) || []
			setCustomerTagOptions(customerTagOptions)
		} catch (e) {
			notification.error({
				message: 'Unable to Get Customer Tags',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCustomerTagOptionsLoading(false)
		}
	}

	const isFilterEnabled = () => {
		return searchTerm.trim() ||
        creationFromDate ||
        creationToDate ||
        lastOrderFromDate ||
        lastOrderToDate ||
        noOrderSinceDate ||
        minOrderFrequency ||
        minSalesAmount ||
        maxOrderFrequency ||
        (minOrderFrequency === 0 && maxOrderFrequency === 0) ||
        maxSalesAmount ||
        filteredDistrict?.value ||
        tag ||
        accountManager ||
        selectedProduct ||
        sources.length > 0 ||
        minSmsCount ||
        maxSmsCount ||
        (minSmsCount === 0 && maxSmsCount === 0)
	}

	const handleApply = async () => {
		const filters = {
			searchTerm,
			customerTagId: tag,
			district: filteredDistrict?.value,
			minOrderFrequency,
			maxOrderFrequency,
			minSalesAmount,
			maxSalesAmount,
			minSmsCount,
			maxSmsCount,
			types: [customerTypes.E_COMMERCE_CUSTOMER, customerTypes.DISTRIBUTOR, customerTypes.RETAILER]
		}
		if (creationFromDate && creationToDate) {
			filters.fromDate = moment(creationFromDate).startOf('day').toDate()
			filters.toDate = moment(creationToDate).endOf('day').toDate()
		}
		if (lastOrderFromDate && lastOrderToDate) {
			filters.lastOrderFromDate = moment(lastOrderFromDate).startOf('day').toDate()
			filters.lastOrderToDate = moment(lastOrderToDate).endOf('day').toDate()
		}
		if (noOrderSinceDate) {
			filters.noOrderSinceDate = moment(noOrderSinceDate).startOf('day').toDate()
		}
		if (accountManager) {
			filters.accountManagerId = accountManager.value
		}
		if (selectedProduct) {
			filters.productId = selectedProduct.value
		}
		if (sources.length) {
			filters.sources = sources
		}
		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setMinOrderFrequency(null)
		setMaxOrderFrequency(null)
		setMinSmsCount(null)
		setMaxSmsCount(null)
		setMinSalesAmount(null)
		setMaxSalesAmount(null)
		setFilteredDistrict(null)
		setAccountManager(null)
		setCreationFromDate(null)
		setCreationToDate(null)
		setLastOrderFromDate(null)
		setLastOrderToDate(null)
		setNoOrderSinceDate(null)
		setTag(null)
		setSelectedProduct(null)
		setSources([])
		clearFilter()
	}

	const searchDistrict = async searchTerm => {
		return { data: districts.filter(district => district.label.toLowerCase().includes(searchTerm.toLowerCase())) }
	}

	const searchAccountManager = async searchTerm => await Users.index({ searchTerm, role: roles.SALES_MANAGER })

	return (
		<DropdownOverlay>
			<div className={styles.inputGrid}>
				<div style={{ marginBottom: 12 }}>
					<p className={styles.title}>Sources</p>
					<Checkbox.Group
						className={styles.inputGroup}
						value={sources}
						onChange={sources => {
							setSources(sources)
						}}
					>
						{
							sourcesOptions.map((source) => {
								return (
									<Checkbox
										key={source.value}
										className={styles.inputTitle}
										value={source.value}
									>
										{source.label}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</div>
				<CustomSelectSecondary
					style={{ background: '#F7F7F7', borderRadius: 0 }}
					title='Customer Tag'
					allowClear
					options={customerTagOptions}
					value={tag}
					onChange={setTag}
					loading={isCustomerTagOptionsLoading}
				/>
				<CustomSearchableSelectSecondary
					placeholder='District'
					defaultOptions={districts}
					valueIndex='value'
					labelIndex='label'
					allowClear={true}
					onClear={() => setFilteredDistrict(null)}
					value={filteredDistrict}
					onChange={option => setFilteredDistrict(option)}
					searchTask={searchDistrict}
					isLoading={isDistrictsLoading}
				/>
				<CustomSearchableSelectSecondary
					placeholder='Area Manager'
					defaultOptions={accountManagers}
					valueIndex='id'
					labelIndex='name'
					allowClear={true}
					onClear={() => setAccountManager(null)}
					value={accountManager}
					onChange={option => setAccountManager(option)}
					searchTask={searchAccountManager}
					isLoading={isLoadingAccountManagers}
				/>
			</div>
			<div>
				<p className={styles.title}>Product</p>
				<CustomSearchableSelectSecondary
					placeholder='Product Name, SKU, or Internal ID'
					isLoading={isLoadingProducts}
					defaultOptions={products}
					searchTask={searchProducts}
					customLabel={(data) => data.name}
					customDescription={(data) => {
						return (
							<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
								<div>{data.sku || 'No SKU'}</div>
								{
									data.parentProductId &&
                                    <Tag bordered={false} className={styles.productTag}>
                                        Variant
                                    </Tag>
								}
								{
									data.variants?.length > 0 &&
                                    <Tag bordered={false} className={styles.productTag}>
                                        Base Product
                                    </Tag>
								}
								{
									!data.variants?.length && !data.parentProductId &&
                                    <Tag bordered={false} className={styles.productTag}>
                                        Simple Product
                                    </Tag>
								}
							</div>
						)
					}}
					valueIndex='id'
					onChange={setSelectedProduct}
					onClear={() => setSelectedProduct(null)}
					value={selectedProduct}
					allowClear={true}
				/>
			</div>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ flex: 1 }}
						type='date'
						title='Start Date'
						value={creationFromDate ? moment(creationFromDate) : null}
						onChange={(date) => setCreationFromDate(date?.toDate())}
					/>
					<Input
						style={{ flex: 1 }}
						type='date'
						title='End Date'
						value={creationToDate ? moment(creationToDate) : null}
						onChange={(date) => setCreationToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(creationFromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Last Order Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ flex: 1 }}
						type='date'
						title='Start Date'
						value={lastOrderFromDate ? moment(lastOrderFromDate) : null}
						onChange={(date) => setLastOrderFromDate(date?.toDate())}
					/>
					<Input
						style={{ flex: 1 }}
						type='date'
						title='End Date'
						value={lastOrderToDate ? moment(lastOrderToDate) : null}
						onChange={(date) => setLastOrderToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(lastOrderFromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>No Order Since</p>
				<Input
					type='date'
					title='No Order Since'
					tooltip='Customer who have not placed order since this date.'
					value={noOrderSinceDate ? moment(noOrderSinceDate) : null}
					onChange={(date) => setNoOrderSinceDate(date?.toDate() || null)}
				/>
			</div>
			<div>
				<p className={styles.title}>Order Frequency</p>
				<div className={styles.inputFlex}>
					<NumberInput
						title='Min'
						value={minOrderFrequency}
						onChange={value => {
							if (value < 0) {
								setMinOrderFrequency(0)
								return
							}
							if (value > maxOrderFrequency && isNotEmpty(maxOrderFrequency)) {
								setMaxOrderFrequency(value)
							}
							setMinOrderFrequency(value)
						}}
					/>
					<NumberInput
						title='Max'
						min={minOrderFrequency}
						value={maxOrderFrequency}
						onChange={value => {
							if (value < 0) {
								setMaxOrderFrequency(0)
								return
							}
							setMaxOrderFrequency(value)
						}}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Promotional SMS Frequency</p>
				<div className={styles.inputFlex}>
					<NumberInput
						title='Min'
						value={minSmsCount}
						onChange={value => {
							if (value < 0) {
								setMinSmsCount(0)
								return
							}
							if (value > maxSmsCount && isNotEmpty(maxSmsCount)) {
								setMaxSmsCount(value)
							}
							setMinSmsCount(value)
						}}
					/>
					<NumberInput
						title='Max'
						min={minSmsCount}
						value={maxSmsCount}
						onChange={value => {
							if (value < 0) {
								setMaxSmsCount(0)
								return
							}
							setMaxSmsCount(value)
						}}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Sales Amount</p>
				<div className={styles.inputFlex}>
					<NumberInput
						title='Min'
						value={minSalesAmount}
						onChange={value => {
							if (value < 0) {
								setMinSalesAmount(0)
								return
							}
							if (value > maxSalesAmount && isNotEmpty(maxSalesAmount)) {
								setMaxSalesAmount(value)
							}
							setMinSalesAmount(value)
						}}
					/>
					<NumberInput
						title='Max'
						min={minSalesAmount}
						value={maxSalesAmount}
						onChange={value => {
							if (value < 0) {
								setMaxSalesAmount(0)
								return
							}
							setMaxSalesAmount(value)
						}}
					/>
				</div>
			</div>

			<div className={styles.buttons}>
				{
					(isFilterEnabled() || isSearchApplied) &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

ECommerceCustomerFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

export default ECommerceCustomerFilter
