import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import Page from '../../components/page'
import styles from './Invoice.module.css'
require('./Invoice.less')
import { SalesOrders } from '../../services/api/sales-orders'
import { getCurrencyCode, getFormattedCurrency, isNotEmpty } from '../../utils'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Alert, Button, Checkbox, Form, Image, Input, notification, Radio, Row, Select, Space, Table, Tooltip } from 'antd'
import ReactToPrint from 'react-to-print'
import { Invoices } from '../../services/api/firebase'
import { InvoiceScanTypes, InvoiceTypes } from '../../utils/constants'
import { SALES_ORDER_COMPLETED_STATUS } from '../sales-orders'
import ImageUpload from '../../components/image-upload'
import { VolumeUnits } from '../../utils/units'
import TextInput from '../../components/text-input'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { usePage } from '../../hooks/usePage'
import Barcode from 'react-barcode'
import QRCode from 'react-qr-code'

export const INVOICE_TYPES = {
	SALES_ORDER: 'sales-order',
	SALES_ORDERS: 'sales-orders',
	DELIVERY_PLAN_ITEM: 'delivery-plan-item'
}

export const POSInvoiceTypes = {
	POS_STICKER: 'pos_sticker',
	THREE_IN_ROLLING_PAPER: 'three_inch_rolling_paper'
}

export const LabelSizes = {
	FOUR_BY_TWO: '4x2',
	FOUR_BY_THREE: '4x3',
	FOUR_BY_FOUR: '4x4',
	FOUR_BY_SIX: '4x6',
	FIVE_BY_THREE: '5x3',
	SIX_BY_THREE: '6x3'
}

export const RowTypes = {
	FIXED: 'fixed',
	DYNAMIC: 'dynamic'
}

export const DateTypes = {
	CREATION: 'creation',
	SHIPPING: 'shipping'
}

export const ProductColumns = {
	SKU: 'sku',
	NAME: 'name'
}

const rowOptions = [
	{
		label: '5 Rows',
		value: 5
	},
	{
		label: '8 Rows',
		value: 8
	},
	{
		label: '10 Rows',
		value: 10
	}
]

const productSpecificationOptions = [
	{
		label: 'Weight',
		value: 'weight'
	},
	{
		label: 'Volume',
		value: 'volume'
	}
]

const invoiceColumns = [
	{
		title: 'SL',
		key: 'sl',
		width: 20,
		align: 'center',
		render: (item, record, index) => {
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{item.sl ? item.sl : index + 1}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'left' }}>Image</div>,
		key: 'image',
		render: item => {
			return (
				item.imageUrls?.[0] &&
				<Image
					width={32}
					height={32}
					src={item.imageUrls?.[0]}
					placeholder={true}
					style={{ objectFit: 'contain' }}
					preview={false}
					referrerPolicy='no-referrer'
				/>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'left' }}>SKU</div>,
		key: 'sku',
		render: item => {
			return (
				<div className={styles.itemName}>
					{item.sku}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'left' }}>Item Name</div>,
		key: 'itemName',
		render: item => {
			return (
				<div className={styles.itemName}>
					{item.name}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'left' }}>Weight</div>,
		key: 'weight',
		render: item => {
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{item.weight}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'left' }}>Volume</div>,
		key: 'volume',
		render: item => {
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{item.volume}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'right' }}>{`Unit Price (${getCurrencyCode()})`}</div>,
		key: 'unitPrice',
		width: 100,
		render: item => {
			return (
				<div style={{ fontSize: 10, textAlign: 'right' }}>
					{item.price !== undefined && getFormattedCurrency(item.price, false)}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'center' }}>Qty</div>,
		key: 'quantity',
		width: 60,
		render: item => {
			return (
				<div style={{ fontSize: 10, textAlign: 'center' }}>
					{item.quantity}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'right' }}>{`Amount (${getCurrencyCode()})`}</div>,
		key: 'amount',
		width: 200,
		render: item => {
			return (
				<div style={{ fontSize: 10, textAlign: 'right' }}>
					{item.amount !== undefined && getFormattedCurrency(item.amount, false)}
					{
						item.discountAmount > 0 &&
						<span>
							{` - ${getFormattedCurrency(item.discountAmount, false)}`}
							<span style={{ fontSize: 8, marginLeft: 2 }}>disc.</span>
							{` = ${getFormattedCurrency(item.amount - item.discountAmount, false)}`}
						</span>
					}
				</div>
			)
		}
	}
]

const posInvoiceColumns = [
	{
		title: <div style={{ textAlign: 'left' }}>Product</div>,
		key: 'itemName',
		render: item => {
			const {
				isSkuColumnEnabled,
				isItemNameColumnEnabled
			} = item
			return (
				<div>
					{
						isSkuColumnEnabled &&
						<div className={styles.posItemName} style={{ fontWeight: 'bold', fontStyle: 'italic', maxWidth: item.maxWidth, fontSize: item.fontSize }}>
							{item.sku}
						</div>
					}
					{
						isItemNameColumnEnabled &&
						<div className={styles.posItemName} style={{ fontWeight: 'normal', maxWidth: item.maxWidth, fontSize: item.fontSize }}>
							{item.name}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'center' }}>Qty</div>,
		key: 'quantity',
		render: item => {
			return (
				<div style={{ fontSize: item.fontSize, textAlign: 'center', color: 'black' }}>
					{item.quantity}
				</div>
			)
		}
	},
	{
		title: <div style={{ textAlign: 'right' }}>Price</div>,
		key: 'amount',
		render: item => {
			return (
				<div style={{ fontSize: item.fontSize, textAlign: 'right', color: 'black' }}>
					{item.amount !== undefined && getFormattedCurrency(item.amount, false)}
					{
						item.discountAmount > 0 &&
						<span>
							{` - ${getFormattedCurrency(item.discountAmount, false)}`}
							<span style={{ fontSize: 8, marginLeft: 2 }}>disc.</span>
							{` = ${getFormattedCurrency(item.amount - item.discountAmount, false)}`}
						</span>
					}
				</div>
			)
		}
	}
]

const summaryColumns = [
	{
		title: 'Summary',
		key: 'summary',
		width: 160,
		render: item => {
			return (
				item.title === 'Sub Total' || item.title === 'Grand Total' || item.title === 'Due Amount' ?
					<div style={{ fontSize: 10, textAlign: 'right', fontWeight: 'bold', fontFamily: 'sans-serif' }}>
						{item.title}
					</div> :
					<div style={{ fontSize: 10, textAlign: 'right' }}>
						{item.title}
					</div>
			)
		}
	},
	{
		title: 'Amount',
		key: 'amount',
		width: 200,
		render: item => {
			return (
				item.title === 'Sub Total' || item.title === 'Grand Total' || item.title === 'Due Amount' ?
					<div style={{ fontSize: 10, textAlign: 'right', fontWeight: 'bold', fontFamily: 'sans-serif' }}>
						{item.amount}
					</div> :
					<div style={{ fontSize: 10, textAlign: 'right' }}>
						{item.amount}
					</div>
			)
		}
	}
]

const InvoicePreviewPage = () => {
	const router = useRouter()
	const { query } = router
	const { id } = query
	const { userProfile, companyDetails, companyId, permissions } = useSelector(state => state.authReducer)
	const { setBackPageLink } = usePage()
	const [recipientName, setRecipientName] = useState('')
	const [recipientPhone, setRecipientPhone] = useState('')
	const [recipientAddress, setRecipientAddress] = useState('')
	const [orderDate, setOrderDate] = useState(new Date())
	const [creationDate, setCreationDate] = useState(new Date())
	const [logoUrl, setLogoUrl] = useState('')
	const [referenceNumber, setReferenceNumber] = useState('')
	const [discount, setDiscount] = useState(0)
	const [discountAmount, setDiscountAmount] = useState(0)
	const [advanceAmount, setAdvanceAmount] = useState(0)
	const [subTotalAmount, setSubTotalAmount] = useState(0)
	const [totalAmount, setTotalAmount] = useState(0)
	const [deliveryCharge, setDeliveryCharge] = useState(0)
	const [deliveryPartner, setDeliveryPartner] = useState()
	const [trackingId, setTrackingId] = useState()
	const [exchangedInternalId, setExchangedInternalId] = useState('')
	const [header, setHeader] = useState('')
	const [subHeader, setSubHeader] = useState('')
	const [invoiceItems, setInvoiceItems] = useState([])
	const [invoicePayments, setInvoicePayments] = useState([])
	const [facebook, setFacebook] = useState('')
	const [email, setEmail] = useState('')
	const [website, setWebsite] = useState('')
	const [address, setAddress] = useState('')
	const [info1Title, setInfo1Title] = useState('')
	const [info1Text, setInfo1Text] = useState('')
	const [info2Title, setInfo2Title] = useState('')
	const [info2Text, setInfo2Text] = useState('')
	const [info3Title, setInfo3Title] = useState('')
	const [info3Text, setInfo3Text] = useState('')
	const [info4Title, setInfo4Title] = useState('')
	const [info4Text, setInfo4Text] = useState('')
	const [attachmentUrl, setAttachmentUrl] = useState('')
	const [productSpecification, setProductSpecification] = useState(productSpecificationOptions[0].value)
	const [additionalContactInfo, setAdditionalContactInfo] = useState('')
	const [rowType, setRowType] = useState(RowTypes.FIXED)
	const [rowSize, setRowSize] = useState(rowOptions[1].value)
	const [dateType, setDateType] = useState(DateTypes.SHIPPING)
	const [isFooterEnabled, setIsFooterEnabled] = useState(true)
	const [isImageColumnEnabled, setIsImageColumnEnabled] = useState(false)
	const [isSkuColumnEnabled, setIsSkuColumnEnabled] = useState(false)
	const [isItemNameColumnEnabled, setIsItemNameColumnEnabled] = useState(true)
	const [isWeightColumnEnabled, setIsWeightColumnEnabled] = useState(true)
	const [isVolumeColumnEnabled, setIsVolumeColumnEnabled] = useState(false)
	const [posType, setPosType] = useState(POSInvoiceTypes.POS_STICKER)
	const [labelSize, setLabelSize] = useState(LabelSizes.FOUR_BY_TWO)
	const [productColumn, setProductColumn] = useState(ProductColumns.NAME)
	const [rotation, setRotation] = useState(0)
	const [notes, setNotes] = useState()
	const [isSavingInvoiceConfiguration, setIsSavingInvoiceConfiguration] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const printableRef = useRef()
	const invoiceType = useMemo(() => {
		if (companyDetails) {
			return companyDetails.documentTemplates?.invoiceType ?? InvoiceTypes.GENERAL
		} else {
			return null
		}
	}, [companyDetails])
	const invoiceScanType = useMemo(() => {
		if (companyDetails) {
			const invoiceType = companyDetails.documentTemplates?.invoiceType ?? InvoiceTypes.GENERAL
			if (invoiceType === InvoiceTypes.GENERAL && companyDetails.documentTemplates?.isInvoiceScanEnabled) {
				return companyDetails.documentTemplates?.invoiceScanType
			} else {
				return null
			}
		} else {
			return null
		}
	}, [companyDetails])

	useEffect(() => {
		setBackPageLink()
	}, [])

	useEffect(() => {
		if (companyId) {
			getInvoiceData()
		}
	}, [companyId])

	useEffect(() => {
		if (companyDetails) {
			setLogoUrl(companyDetails.logoUrl)
		} else {
			setLogoUrl('/img/logo.svg')
		}
	}, [companyDetails])

	const getInvoiceData = async () => {
		setIsLoading(true)
		const invoiceConfiguration = await Invoices.listenToInvoiceConfigurationOnce(companyId)
		let header = ''
		let address = ''
		let email = ''
		if (userProfile) {
			const { company } = userProfile
			header = company?.name
			address = company?.address
			email = userProfile.email
		}
		if (invoiceConfiguration) {
			header = invoiceConfiguration.header || header
			setSubHeader(invoiceConfiguration.subHeader)
			address = invoiceConfiguration.address || address
			setAddress(address)
			email = invoiceConfiguration.email || email
			setEmail(email || '')
			setFacebook(invoiceConfiguration.facebook || '')
			setWebsite(invoiceConfiguration.website || '')
			setInfo1Title(invoiceConfiguration.info1Title || '')
			setInfo1Text(invoiceConfiguration.info1Text || '')
			setInfo2Title(invoiceConfiguration.info2Title || '')
			setInfo2Text(invoiceConfiguration.info2Text || '')
			setInfo3Title(invoiceConfiguration.info3Title || '')
			setInfo3Text(invoiceConfiguration.info3Text || '')
			setInfo4Title(invoiceConfiguration.info4Title || '')
			setInfo4Text(invoiceConfiguration.info4Text || '')
			setAttachmentUrl(invoiceConfiguration.attachmentUrl || '')
			setProductSpecification(invoiceConfiguration.productSpecification || productSpecificationOptions[0].value || '')
			setAdditionalContactInfo(invoiceConfiguration.additionalContactInfo || '')
			setPosType(invoiceConfiguration.posType || POSInvoiceTypes.POS_STICKER)
			setLabelSize(invoiceConfiguration.labelSize || LabelSizes.FOUR_BY_TWO)
			setRotation(invoiceConfiguration.rotation || 0)
			if (invoiceConfiguration.isFooterEnabled !== null && invoiceConfiguration.isFooterEnabled !== undefined) {
				setIsFooterEnabled(invoiceConfiguration.isFooterEnabled)
			}
			setRowType(invoiceConfiguration.rowType || RowTypes.FIXED)
			setRowSize(invoiceConfiguration.rowSize || rowOptions[1].value)
			setDateType(invoiceConfiguration.dateType || DateTypes.SHIPPING)
			setProductColumn(invoiceConfiguration.productColumn || ProductColumns.NAME)
			setIsItemNameColumnEnabled(isNotEmpty(invoiceConfiguration.isItemNameColumnEnabled) ? invoiceConfiguration.isItemNameColumnEnabled : true)
			setIsImageColumnEnabled(isNotEmpty(invoiceConfiguration.isImageColumnEnabled) ? invoiceConfiguration.isImageColumnEnabled : false)
			setIsSkuColumnEnabled(isNotEmpty(invoiceConfiguration.isSkuColumnEnabled) ? invoiceConfiguration.isSkuColumnEnabled : false)
			setIsWeightColumnEnabled(isNotEmpty(invoiceConfiguration.isWeightColumnEnabled) ? invoiceConfiguration.isWeightColumnEnabled : true)
			setIsVolumeColumnEnabled(isNotEmpty(invoiceConfiguration.isVolumeColumnEnabled) ? invoiceConfiguration.isVolumeColumnEnabled : false)
		}

		setHeader(header)
		await getSalesOrder()
		setIsLoading(false)
	}

	const getSalesOrder = async () => {
		const response = await SalesOrders.single(id, { company: true })
		const { data } = response
		if (data) {
			setRecipientName(data.distributor ? data.distributor.name : '')
			setRecipientPhone(data.distributor ? data.distributor.phone : '')
			if (data.location) {
				setRecipientAddress(data.distributor?.name !== data.location?.label ? data.location?.label || data.location?.address : data.location?.address)
			} else if (data.distributor && data.distributor.locations && data.distributor.locations.length > 0) {
				setRecipientAddress(data.distributor.locations[0].address)
			}
			setOrderDate(data.orderDate)
			setCreationDate(data.createdAt)
			setReferenceNumber(data.internalId)
			setDeliveryPartner(data.deliveryPartner)
			setTrackingId(data.deliveryOrderId || data.deliveryTrackingId)
			const salesOrderItems = data.salesOrderItems || []
			let subTotalAmount = 0
			const invoiceItems = salesOrderItems.map((salesOrderItem, i) => {
				const name = salesOrderItem.product ? salesOrderItem.product.name : '-'
				const sku = salesOrderItem.product ? salesOrderItem.product.sku : '-'
				const imageUrls = salesOrderItem.product ? salesOrderItem.product.imageUrls : []
				const weightSpec = salesOrderItem.product ? salesOrderItem.product.productSpecifications.find(spec => spec.specificationKey === 'weight') : undefined
				let weight = '-'
				if (weightSpec) {
					weight = `${weightSpec.value} ${weightSpec.unit}`
				}
				const volumeSpec = salesOrderItem.product ? salesOrderItem.product.productSpecifications.find(spec => spec.specificationKey === 'volume') : undefined
				let volume = '-'
				if (volumeSpec) {
					volume = `${volumeSpec.value} ${volumeSpec.unit === VolumeUnits.M3 ? 'mᶟ' : volumeSpec.unit}`
				}
				const price = +salesOrderItem.price
				let quantity = salesOrderItem.packageQuantity > 0 ? salesOrderItem.packageQuantity : salesOrderItem.quantity
				if (data.status.toLowerCase() === SALES_ORDER_COMPLETED_STATUS) {
					quantity = salesOrderItem.deliveredPackageQuantity > 0 ? salesOrderItem.deliveredPackageQuantity : salesOrderItem.deliveredQuantity
				}
				const amount = price * quantity
				const discountAmount = +salesOrderItem.discount > 0 ? (price * +salesOrderItem.discount / 100) * quantity : +salesOrderItem.discountAmount
				subTotalAmount += amount - (discountAmount || 0)
				return {
					sl: i + 1,
					imageUrls,
					name,
					sku,
					weight,
					volume,
					price,
					quantity,
					amount,
					discountAmount
				}
			})
			setSubTotalAmount(subTotalAmount)
			setDiscount(data.discount)
			let totalPayments = data.transactions.reduce((acc, transaction) => {
				const amount = +transaction.amount
				return acc + amount
			}, 0)
			const payments = []
			if (totalPayments === 0) {
				totalPayments = +data.distributorAdvancePayment || 0
			}
			if (totalPayments > 0) {
				payments.push({
					name: 'Payment',
					amount: totalPayments
				})
			}
			const discount = +data.discount > 0 ? (subTotalAmount * +data.discount) / 100 : +data.discountAmount
			setDiscountAmount(discount)
			setAdvanceAmount(+data.distributorAdvancePayment)
			const totalAmount = subTotalAmount - discount + +data.deliveryCharge - totalPayments
			setInvoicePayments(payments)
			setInvoiceItems(invoiceItems)
			setTotalAmount(totalAmount)
			setDeliveryCharge(+data.deliveryCharge)
			setNotes(data.additionalNotes)
			if (data.isExchange && data.parentSalesOrder?.internalId) {
				setExchangedInternalId(data.parentSalesOrder?.internalId)
			}
		}
	}

	const onSaveInvoiceConfiguration = async () => {
		try {
			setIsSavingInvoiceConfiguration(true)
			const data = {
				header,
				subHeader,
				facebook,
				email,
				website,
				address,
				info1Title,
				info1Text,
				info2Title,
				info2Text,
				info3Title,
				info3Text,
				info4Title,
				info4Text,
				attachmentUrl: attachmentUrl || null,
				productSpecification,
				additionalContactInfo,
				posType,
				labelSize,
				rotation,
				rowType,
				rowSize,
				dateType,
				isFooterEnabled,
				productColumn,
				isImageColumnEnabled,
				isItemNameColumnEnabled,
				isSkuColumnEnabled,
				isWeightColumnEnabled,
				isVolumeColumnEnabled
			}
			await Invoices.setInvoiceConfiguration(companyId, data)
		} catch (e) {
			notification.error({
				message: 'Error saving invoice configuration.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSavingInvoiceConfiguration(false)
		}
	}

	const onAfterPrint = async () => {
		try {
			await SalesOrders.updatePrintStatuses([id])
		} catch (e) {
			console.error(e)
		}
	}

	const pageStyle = useMemo(() => {
		if (invoiceType === InvoiceTypes.POS) {
			if (posType === POSInvoiceTypes.THREE_IN_ROLLING_PAPER) {
				return '@page { size: 3in auto; margin: 0; }'
			} else {
				switch (labelSize) {
					case LabelSizes.FOUR_BY_TWO:
						return '@page { size: 2in 4in; margin: 0; }'
					case LabelSizes.FOUR_BY_THREE:
						return '@page { size: 3in 4in; margin: 0; }'
					case LabelSizes.FOUR_BY_FOUR:
						return '@page { size: 4in; margin: 0; }'
					case LabelSizes.FOUR_BY_SIX:
						return '@page { size: 6in 4in; margin: 0; }'
					case LabelSizes.FIVE_BY_THREE:
						return '@page { size: 3in 5in; margin: 0; }'
					case LabelSizes.SIX_BY_THREE:
						return '@page { size: 3in 6in; margin: 0; }'
					default:
						return '@page { size: 2in 4in; margin: 0; }'
				}
			}
		} else {
			return undefined
		}
	}, [labelSize, posType, invoiceType, rotation])

	const renderGeneralInvoiceSettings = () => {
		return (
			<>
				<h3 className={styles.editInvoiceHeader}>Edit Invoice</h3>
				<Form layout='vertical'>
					<Form.Item label='Header' tooltip='Add a company heading for every invoice.'>
						<TextInput
							value={header}
							onChange={e => setHeader(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Sub-Header' tooltip='Add a company sub-heading for every invoice.'>
						<TextInput
							value={subHeader}
							onChange={e => setSubHeader(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Facebook' tooltip='Add Facebook link.'>
						<TextInput
							value={facebook}
							onChange={e => setFacebook(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Email' tooltip='Add contact email.'>
						<TextInput
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Website' tooltip='Add company website.'>
						<TextInput
							value={website}
							onChange={e => setWebsite(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Address' tooltip='Add company address.'>
						<TextInput
							value={address}
							onChange={e => setAddress(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Info 1' tooltip='Additional information.'>
						<TextInput
							placeholder='Heading'
							value={info1Title}
							onChange={e => setInfo1Title(e.target.value)}
						/>
						<div style={{ marginTop: 8 }} />
						<TextInput
							placeholder='Content'
							value={info1Text}
							onChange={e => setInfo1Text(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Info 2' tooltip='Additional information.'>
						<TextInput
							placeholder='Heading'
							value={info2Title}
							onChange={e => setInfo2Title(e.target.value)}
						/>
						<div style={{ marginTop: 8 }} />
						<TextInput
							placeholder='Content'
							value={info2Text}
							onChange={e => setInfo2Text(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Info 3' tooltip='Additional information.'>
						<TextInput
							placeholder='Heading'
							value={info3Title}
							onChange={e => setInfo3Title(e.target.value)}
						/>
						<div style={{ marginTop: 8 }} />
						<TextInput
							placeholder='Content'
							value={info3Text}
							onChange={e => setInfo3Text(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Info 4' tooltip='Additional information.'>
						<TextInput
							placeholder='Heading'
							value={info4Title}
							onChange={e => setInfo4Title(e.target.value)}
						/>
						<div style={{ marginTop: 8 }} />
						<TextInput
							placeholder='Content'
							value={info4Text}
							onChange={e => setInfo4Text(e.target.value)}
						/>
					</Form.Item>
					<Form.Item label='Attachments' tooltip='Attach an image.'>
						<ImageUpload
							cropper={false}
							pathname='product-images'
							accept='image/*, .png, .jpg, .jpeg'
							square={true}
							maxFileSize={1}
							maxFiles={1}
							images={attachmentUrl ? [attachmentUrl] : []}
							onImagesUploaded={imageUrls => setAttachmentUrl(imageUrls[0])}
						/>
					</Form.Item>
					<Form.Item label='Additional Notes' tooltip='Add additional notes.'>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 3 }}
							onChange={e => setNotes(e.target.value)}
							value={notes}
						/>
					</Form.Item>
					<Form.Item label='Product Identification' tooltip='Image, SKU & Item Name columns to display.'>
						<div>
							<Checkbox
								checked={isImageColumnEnabled}
								onChange={e => {
									setIsImageColumnEnabled(e.target.checked)
									if (e.target.checked) {
										setRowSize(5)
									}
								}}
							>
							Image
							</Checkbox>
							<Checkbox
								style={{ margin: 0 }}
								checked={isSkuColumnEnabled}
								onChange={e => setIsSkuColumnEnabled(e.target.checked)}
							>
							SKU
							</Checkbox>
							<Checkbox
								style={{ margin: 0 }}
								checked={isItemNameColumnEnabled}
								onChange={e => setIsItemNameColumnEnabled(e.target.checked)}
							>
							Item Name
							</Checkbox>
						</div>
					</Form.Item>
					{
						!isItemNameColumnEnabled && !isSkuColumnEnabled && !isImageColumnEnabled &&
						<Alert message='Check at least one checkbox.' style={{ marginBottom: 12 }} />
					}
					<Form.Item label='Product Specification' tooltip='Weight & Volume column to display.'>
						<div>
							<Checkbox
								checked={isWeightColumnEnabled}
								onChange={e => setIsWeightColumnEnabled(e.target.checked)}
							>
								Weight
							</Checkbox>
							<Checkbox
								style={{ margin: 0 }}
								checked={isVolumeColumnEnabled}
								onChange={e => setIsVolumeColumnEnabled(e.target.checked)}
							>
								Volume
							</Checkbox>
						</div>
					</Form.Item>
					<Form.Item label='Row Type' tooltip='Determine if the rows are fixed or dynamic. When the Row Type is fixed and the Image column is enabled, the row will be fixed to 5 rows and cannot be changed.'>
						<Radio.Group
							value={rowType}
							onChange={({ target: { value } }) => {
								setRowType(value)
							}}
						>
							<Radio style={{ fontSize: 13 }} value={RowTypes.FIXED}>
								Fixed
							</Radio>
							<Radio style={{ fontSize: 13 }} value={RowTypes.DYNAMIC}>
								Dynamic
							</Radio>
						</Radio.Group>
						{
							rowType === RowTypes.FIXED &&
							<Select
								options={isImageColumnEnabled ? [rowOptions[0]] : rowOptions}
								value={rowSize}
								onChange={setRowSize}
							/>
						}
					</Form.Item>
					<Form.Item label='Date Type' tooltip='Creation or Shipping Date to display.'>
						<Radio.Group
							value={dateType}
							onChange={({ target: { value } }) => {
								setDateType(value)
							}}
						>
							<Radio style={{ fontSize: 13 }} value={DateTypes.CREATION}>
								Creation
							</Radio>
							<Radio style={{ fontSize: 13 }} value={DateTypes.SHIPPING}>
								Shipping
							</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item label='Footer' tooltip='Enable/disable the visibility of the invoice footer.'>
						<Checkbox
							checked={isFooterEnabled}
							onChange={e => setIsFooterEnabled(e.target.checked)}
						/>
					</Form.Item>
					{
						isFooterEnabled &&
						<Form.Item label='Contact' tooltip='Add additional contact information.'>
							<Input.TextArea
								maxLength={29}
								autoSize={{ minRows: 2, maxRows: 2 }}
								onChange={e => setAdditionalContactInfo(e.target.value)}
								value={additionalContactInfo}
							/>
						</Form.Item>
					}
					<Row className={styles.buttonContainer}>
						<Space size='middle'>
							<Button
								style={{ width: 98 }}
								onClick={onSaveInvoiceConfiguration}
								loading={isSavingInvoiceConfiguration}
								disabled={permissions?.editOrdersInvoice === false || (!isItemNameColumnEnabled && !isSkuColumnEnabled)}
							>
								Save
							</Button>
							<ReactToPrint
								pageStyle={pageStyle}
								trigger={() => {
									return (
										<Button
											style={{ width: 98 }}
											type='primary'
											loading={isLoading}
										>
											Print
										</Button>
									)
								}}
								content={() => printableRef.current}
								onAfterPrint={onAfterPrint}
								documentTitle={`sales-orders_selected-1_${moment().format('MMM_DD_YYYY')}`}
							/>
						</Space>
					</Row>
				</Form>
			</>
		)
	}

	const renderPosInvoiceSettings = () => {
		return (
			<>
				<h3 className={styles.editInvoiceHeader}>Settings</h3>
				<div className={styles.formContainer}>
					<h4>POS Type</h4>
					<Radio.Group
						value={posType}
						onChange={({ target: { value } }) => {
							setPosType(value)
						}}
					>
						<Radio style={{ fontSize: 13 }} value={POSInvoiceTypes.POS_STICKER}>
							POS Sticker
						</Radio>
						<Radio style={{ fontSize: 13 }} value={POSInvoiceTypes.THREE_IN_ROLLING_PAPER}>
							3inch Rolling Paper
						</Radio>
					</Radio.Group>
					{
						posType === POSInvoiceTypes.POS_STICKER &&
						<div className={styles.formContainer} style={{ marginTop: 16 }}>
							<h4>Label Size</h4>
							<Radio.Group
								value={labelSize}
								onChange={({ target: { value } }) => {
									setLabelSize(value)
								}}
								style={{ display: 'flex', gap: 12, flexWrap: 'wrap' }}
							>
								<Radio style={{ fontSize: 13 }} value={LabelSizes.FOUR_BY_TWO}>
								4x2
								</Radio>
								<Radio style={{ fontSize: 13 }} value={LabelSizes.FOUR_BY_THREE}>
								4x3
								</Radio>
								<Radio style={{ fontSize: 13 }} value={LabelSizes.FOUR_BY_FOUR}>
								4x4
								</Radio>
								<Radio style={{ fontSize: 13 }} value={LabelSizes.FOUR_BY_SIX}>
								4x6
								</Radio>
								<Radio style={{ fontSize: 13 }} value={LabelSizes.FIVE_BY_THREE}>
								5x3
								</Radio>
								<Radio style={{ fontSize: 13 }} value={LabelSizes.SIX_BY_THREE}>
								6x3
								</Radio>
							</Radio.Group>
						</div>
					}
					{
						posType === POSInvoiceTypes.POS_STICKER &&
						<div className={styles.formContainer} style={{ marginTop: 16 }}>
							<h4>Rotate Layout</h4>
							<Button
								style={{ width: 256 }}
								onClick={() => setRotation(0)}
							>
								Default
							</Button>
							<Button
								style={{ marginTop: 8, width: 256 }}
								onClick={() => setRotation(rotation + 90)}
							>
								Clockwise
							</Button>
							<Button
								style={{ marginTop: 8, width: 256 }}
								onClick={() => setRotation(rotation - 90)}
							>
								Counter-clockwise
							</Button>
						</div>
					}
					<div className={styles.formContainer} style={{ marginTop: 16 }}>
						<Form layout='vertical'>
							<Form.Item label='Product Identification' tooltip='SKU & Item Name column to display'>
								<div>
									<Checkbox
										checked={isSkuColumnEnabled}
										onChange={e => setIsSkuColumnEnabled(e.target.checked)}
									>
									SKU
									</Checkbox>
									<Checkbox
										style={{ margin: 0 }}
										checked={isItemNameColumnEnabled}
										onChange={e => setIsItemNameColumnEnabled(e.target.checked)}
									>
									Name
									</Checkbox>
								</div>
							</Form.Item>
							{
								!isItemNameColumnEnabled && !isSkuColumnEnabled &&
								<Alert message='Check at least one checkbox.' style={{ marginBottom: 12 }} />
							}
						</Form>
					</div>
					<div className={styles.formContainer} style={{ marginTop: 16 }}>
						<div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
							<h4>Date Type</h4>
							<Tooltip title='Creation or Shipping Date to display.'>
								<QuestionCircleOutlined />
							</Tooltip>
						</div>
						<Radio.Group
							value={dateType}
							onChange={({ target: { value } }) => {
								setDateType(value)
							}}
						>
							<Radio style={{ fontSize: 13 }} value={DateTypes.CREATION}>
								Creation
							</Radio>
							<Radio style={{ fontSize: 13 }} value={DateTypes.SHIPPING}>
								Shipping
							</Radio>
						</Radio.Group>
					</div>
					<div style={{ marginTop: 24 }} />
					<TextInput
						title='Company Name'
						style={{ width: '100%' }}
						value={header}
						onChange={e => setHeader(e.target.value)}
					/>
					<div style={{ marginTop: 12 }} />
					<TextInput
						title='Help Line'
						style={{ width: '100%' }}
						value={subHeader}
						onChange={e => setSubHeader(e.target.value)}
					/>
					<Row className={styles.buttonContainer}>
						<Space size='middle'>
							<Button
								style={{ width: 98 }}
								onClick={onSaveInvoiceConfiguration}
								loading={isSavingInvoiceConfiguration}
								disabled={permissions?.editOrdersInvoice === false || (!isItemNameColumnEnabled && !isSkuColumnEnabled)}
							>
								Save
							</Button>
							<ReactToPrint
								pageStyle={pageStyle}
								trigger={() => {
									return (
										<Button
											style={{ width: 98 }}
											type='primary'
											loading={isLoading}
										>
											Print
										</Button>
									)
								}}
								content={() => printableRef.current}
								onAfterPrint={onAfterPrint}
								documentTitle={`sales-orders_selected-1_${moment().format('MMM_DD_YYYY')}`}
							/>
						</Space>
					</Row>
				</div>
			</>
		)
	}

	return (
		<>
			<div className={styles.container}>
				<div className={`${styles.editorPanel} editor-panel`}>
					{
						invoiceType === InvoiceTypes.GENERAL &&
							renderGeneralInvoiceSettings()
					}
					{
						invoiceType === InvoiceTypes.POS &&
							renderPosInvoiceSettings()
					}
				</div>
				<div style={{ width: 470 }} />
				<div className={styles.pageContainer}
					style={{ paddingTop: 48, paddingBottom: 48 }}
				>
					<div ref={printableRef}>
						{
							invoiceType === InvoiceTypes.GENERAL &&
							<Invoice
								logoUrl={logoUrl}
								header={header}
								subHeader={subHeader}
								recipientName={recipientName}
								recipientPhone={recipientPhone}
								recipientAddress={recipientAddress}
								additionalNotes={notes}
								orderDate={orderDate}
								creationDate={creationDate}
								referenceNumber={referenceNumber}
								invoiceItems={invoiceItems}
								subTotalAmount={subTotalAmount}
								advanceAmount={advanceAmount}
								discount={discount}
								discountAmount={discountAmount}
								deliveryCharge={deliveryCharge}
								deliveryPartner={deliveryPartner}
								trackingId={trackingId}
								totalAmount={totalAmount}
								invoicePayments={invoicePayments}
								email={email}
								address={address}
								website={website}
								facebook={facebook}
								info1Title={info1Title}
								info1Text={info1Text}
								info2Title={info2Title}
								info2Text={info2Text}
								info3Title={info3Title}
								info3Text={info3Text}
								info4Title={info4Title}
								info4Text={info4Text}
								attachmentUrl={attachmentUrl}
								productSpecification={productSpecification}
								additionalContactInfo={additionalContactInfo}
								rowType={rowType}
								rowSize={rowSize}
								dateType={dateType}
								isFooterEnabled={isFooterEnabled}
								isItemNameColumnEnabled={isItemNameColumnEnabled}
								isSkuColumnEnabled={isSkuColumnEnabled}
								isImageColumnEnabled={isImageColumnEnabled}
								isWeightColumnEnabled={isWeightColumnEnabled}
								isVolumeColumnEnabled={isVolumeColumnEnabled}
								exchangedInternalId={exchangedInternalId}
								invoiceScanType={invoiceScanType}
							/>
						}
						{
							invoiceType === InvoiceTypes.POS &&
							<PosInvoice
								header={header}
								subHeader={subHeader}
								posType={posType}
								labelSize={labelSize}
								productColumn={productColumn}
								rotation={rotation}
								recipientName={recipientName}
								recipientPhone={recipientPhone}
								recipientAddress={recipientAddress}
								additionalNotes={notes}
								orderDate={orderDate}
								creationDate={creationDate}
								referenceNumber={referenceNumber}
								invoiceItems={invoiceItems}
								subTotalAmount={subTotalAmount}
								advanceAmount={advanceAmount}
								dateType={dateType}
								discount={discount}
								discountAmount={discountAmount}
								deliveryCharge={deliveryCharge}
								deliveryPartner={deliveryPartner}
								totalAmount={totalAmount}
								invoicePayments={invoicePayments}
								trackingId={trackingId}
								isItemNameColumnEnabled={isItemNameColumnEnabled}
								isSkuColumnEnabled={isSkuColumnEnabled}
								exchangedInternalId={exchangedInternalId}
							/>
						}
					</div>
				</div>
			</div>
		</>
	)
}

InvoicePreviewPage.getLayout = function getLayout (page) {
	return (
		<Page
			fullPage
			parentTitle='Invoice Preview'
		>
			{page}
		</Page>
	)
}

export default InvoicePreviewPage

export const PosInvoice = ({
	visible = true,
	header,
	subHeader,
	posType,
	labelSize,
	rotation,
	recipientName,
	recipientPhone,
	recipientAddress,
	additionalNotes,
	orderDate,
	creationDate,
	dateType,
	referenceNumber,
	invoiceItems = [],
	subTotalAmount,
	discount,
	discountAmount,
	deliveryCharge,
	deliveryPartner,
	totalAmount,
	invoicePayments = [],
	trackingId,
	isItemNameColumnEnabled,
	isSkuColumnEnabled,
	exchangedInternalId
}) => {

	const modifiedInvoiceItems = useMemo(() => {
		const newInvoiceItems = [...invoiceItems]
		let maxWidth = 40
		let fontSize = 10
		if (posType === POSInvoiceTypes.THREE_IN_ROLLING_PAPER) {
			maxWidth = 60
			fontSize = 10
		} else {
			switch (labelSize) {
				case LabelSizes.FOUR_BY_TWO:
					maxWidth = 50
					fontSize = 10
					break
				case LabelSizes.FOUR_BY_THREE:
					maxWidth = 90
					fontSize = 10
					break
				case LabelSizes.FOUR_BY_FOUR:
					maxWidth = 160
					fontSize = 10
					break
				case LabelSizes.FOUR_BY_SIX:
					maxWidth = 290
					fontSize = 10
					break
				case LabelSizes.FIVE_BY_THREE:
					maxWidth = 90
					fontSize = 10
					break
				case LabelSizes.SIX_BY_THREE:
					maxWidth = 90
					fontSize = 10
					break
			}
		}
		newInvoiceItems.forEach(invoiceItem => {
			invoiceItem.maxWidth = maxWidth
			invoiceItem.fontSize = fontSize
			invoiceItem.isItemNameColumnEnabled = isItemNameColumnEnabled
			invoiceItem.isSkuColumnEnabled = isSkuColumnEnabled
		})
		return newInvoiceItems
	}, [labelSize, posType, invoiceItems, isItemNameColumnEnabled, isSkuColumnEnabled])

	const fontSizes = useMemo(() => {
		const defaultFontSizes = {
			heading: 16,
			helpline: 12,
			invoiceTo: 12,
			table: 10,
			note: 10
		}
		if (posType === POSInvoiceTypes.THREE_IN_ROLLING_PAPER) {
			return defaultFontSizes
		} else {
			switch (labelSize) {
				case LabelSizes.FOUR_BY_TWO:
				case LabelSizes.FOUR_BY_THREE:
				case LabelSizes.FIVE_BY_THREE:
				case LabelSizes.SIX_BY_THREE:
					return {
						heading: 15,
						helpline: 11,
						invoiceTo: 11,
						table: 8,
						note: 8
					}
				case LabelSizes.FOUR_BY_FOUR:
				case LabelSizes.FOUR_BY_SIX:
					return defaultFontSizes
				default:
					return defaultFontSizes
			}
		}

	}, [labelSize, posType])

	const { width, height, padding } = useMemo(() => {
		if (posType === POSInvoiceTypes.THREE_IN_ROLLING_PAPER) {
			return { width: '3in', height: undefined, padding: 16 }
		} else {
			switch (labelSize) {
				case LabelSizes.FOUR_BY_TWO:
					return { width: '2in', height: '4in', padding: 16 }
				case LabelSizes.FOUR_BY_THREE:
					return { width: '3in', height: '4in', padding: 16 }
				case LabelSizes.FOUR_BY_FOUR:
					return { width: '4in', height: '4in', padding: '24px 32px' }
				case LabelSizes.FOUR_BY_SIX:
					return { width: '6in', height: '4in', padding: '35px 48px' }
				case LabelSizes.FIVE_BY_THREE:
					return { width: '3in', height: '5in', padding: 16 }
				case LabelSizes.SIX_BY_THREE:
					return { width: '3in', height: '6in', padding: 16 }
				default:
					return { width: '2in', height: '4in', padding: 16 }
			}
		}
	}, [labelSize, posType])

	const summary = useMemo(() => {
		const data = []
		if (subTotalAmount > 0) {
			data.push({
				title: 'Sub Total',
				amount: getFormattedCurrency(subTotalAmount, false)
			})
		}
		if (discountAmount > 0) {
			data.push({
				title: 'Discount',
				amount: `- ${getFormattedCurrency(discountAmount, false)}`
			})
		}
		if (deliveryCharge > 0) {
			data.push({
				title: 'Delivery Fee',
				amount: getFormattedCurrency(deliveryCharge, false)
			})
		}
		const grandTotal = subTotalAmount - discountAmount + deliveryCharge
		if (grandTotal > 0) {
			data.push({
				title: 'Grand Total',
				amount: getFormattedCurrency(grandTotal, false)
			})
		}
		invoicePayments
			.filter(invoiceFee => invoiceFee.amount > 0)
			.map(invoiceFee => {
				data.push({
					title: invoiceFee.name,
					amount: `- ${getFormattedCurrency(invoiceFee.amount, false)}`
				})
			})
		if (totalAmount > 0) {
			data.push({
				title: 'Due Amount',
				amount: getFormattedCurrency(totalAmount, false)
			})
		}
		return data
	}, [
		invoiceItems,
		totalAmount,
		deliveryCharge,
		discountAmount,
		subTotalAmount,
		discount
	])

	return (
		<div
			style={{ width, height, padding, transform: `rotate(${rotation}deg)` }}
			className={visible ? styles.posPage : styles.posPageInvisible}
		>
			<div style={{ fontSize: fontSizes.heading, color: 'black', fontWeight: 'bold' }}>{header}</div>
			{
				subHeader?.trim() &&
				<div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
					<div style={{ fontSize: fontSizes.helpline, color: 'black', fontWeight: 'bold', marginRight: 4 }}>
					Help Line:
					</div>
					<div style={{ fontSize: fontSizes.helpline }}>
						{subHeader}
					</div>
				</div>
			}
			<div style={{
				display: 'flex',
				flexDirection: labelSize === LabelSizes.FOUR_BY_TWO ||
				labelSize === LabelSizes.FOUR_BY_THREE ||
				labelSize === LabelSizes.FIVE_BY_THREE ||
				labelSize === LabelSizes.SIX_BY_THREE ||
				posType === POSInvoiceTypes.THREE_IN_ROLLING_PAPER ?
					'column' : 'row'
			}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 8 }}>
					<div style={{ display: 'flex', alignItems: 'center', fontSize: 11 }}>
						<div style={{ marginRight: 4, color: 'black' }}>
						Invoice No.:
						</div>
						<div style={{ color: 'black', fontWeight: 'bold' }}>
							{referenceNumber}
						</div>
					</div>
					{
						exchangedInternalId &&
						<div style={{ display: 'flex', alignItems: 'center', fontSize: 11 }}>
							<div style={{ marginRight: 4, color: 'black' }}>
							Exchanged For:
							</div>
							<div style={{ color: 'black', fontWeight: 'bold' }}>
								{exchangedInternalId}
							</div>
						</div>
					}
					<div style={{ display: 'flex', alignItems: 'center', fontSize: 11, marginTop: 2 }}>
						<div style={{ marginRight: 4, color: 'black' }}>
						Invoice Date:
						</div>
						<div style={{ color: 'black' }}>
							{dateType === DateTypes.SHIPPING ? moment(orderDate).format('MMM DD, YYYY') : moment(creationDate).format('MMM DD, YYYY')}
						</div>
					</div>
					{
						deliveryPartner &&
						<div style={{ display: 'flex', alignItems: 'center', fontSize: 11, marginTop: 2 }}>
							<div style={{ marginRight: 4 }}>
							Courier:
							</div>
							<div style={{ color: 'black', fontWeight: 'bold' }}>
								{deliveryPartner.name}
							</div>
						</div>
					}
					{
						trackingId &&
						<div style={{ display: 'flex', alignItems: 'center', fontSize: 11, marginTop: 2 }}>
							<div style={{ marginRight: 4 }}>
							Delivery ID:
							</div>
							<div style={{ color: 'black', fontWeight: 'bold' }}>
								{trackingId}
							</div>
						</div>
					}
				</div>
				<div style={{
					display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 8,
					marginLeft: labelSize === LabelSizes.FOUR_BY_TWO ||
					labelSize === LabelSizes.FOUR_BY_THREE ||
					labelSize === LabelSizes.FIVE_BY_THREE ||
					labelSize === LabelSizes.SIX_BY_THREE ||
					posType === POSInvoiceTypes.THREE_IN_ROLLING_PAPER ?
						0 : 48
				}}
				>
					<div style={{ fontSize: fontSizes.invoiceTo, color: 'black', fontWeight: 'bold' }}>Invoice To:</div>
					<div style={{ display: 'flex', alignItems: 'center', fontSize: 10, color: 'black', marginTop: 2 }}>
						<img src='/img/pos-invoice-person.svg' style={{ marginRight: 6 }} />
						<div style={{ fontWeight: 'bold' }}>{recipientName}</div>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', fontSize: 10, color: 'black', marginTop: 2 }}>
						<img src='/img/pos-invoice-phone.svg' style={{ marginRight: 6 }} />
						<div style={{ fontWeight: 'bold' }}>{recipientPhone}</div>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', fontSize: 10, color: 'black', marginTop: 2 }}>
						<img src='/img/pos-invoice-pin.svg' style={{ marginRight: 6 }} />
						<div style={{ fontWeight: 'bold' }}>{recipientAddress}</div>
					</div>
				</div>
			</div>
			<Table
				style={{ marginTop: 12 }}
				size='small'
				dataSource={modifiedInvoiceItems}
				columns={posInvoiceColumns}
				pagination={false}
				locale={{ emptyText: ' ' }}
				bordered={true}
				className={fontSizes.table === 8 ? 'pos-invoice-table-small' : 'pos-invoice-table'}
				summary={() => {
					return (
						<>
							{
								summary.map(summaryRow => {
									return (
										<Table.Summary.Row key={summaryRow.title} className={styles.summaryRow}>
											<Table.Summary.Cell index={0} colSpan={0} />
											<Table.Summary.Cell index={1} colSpan={2} className='summary-row'>
												{
													summaryRow.title === 'Due Amount' ?
														<div style={{ fontWeight: 'bold', color: 'black' }}>{summaryRow.title}</div> :
														<div>{summaryRow.title}</div>
												}
											</Table.Summary.Cell>
											<Table.Summary.Cell index={2} className='summary-row'>
												{
													summaryRow.title === 'Due Amount' ?
														<div style={{ fontWeight: 'bold', color: 'black' }}>{summaryRow.amount}</div> :
														<div>{summaryRow.amount}</div>
												}
											</Table.Summary.Cell>
										</Table.Summary.Row>
									)
								})
							}
						</>
					)
				}}
			/>
			{
				additionalNotes &&
				<div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
					<div style={{ fontSize: fontSizes.note, color: 'black', fontWeight: 'bold', marginRight: 4 }}>
					Note:
					</div>
					<div style={{ fontSize: fontSizes.note }}>
						{additionalNotes}
					</div>
				</div>
			}
		</div>
	)
}

export const Invoice = ({
	visible = true,
	logoUrl,
	header,
	subHeader,
	recipientName,
	recipientPhone,
	recipientAddress,
	additionalNotes,
	orderDate,
	creationDate,
	referenceNumber,
	invoiceItems = [],
	subTotalAmount,
	discount,
	discountAmount,
	deliveryCharge,
	deliveryPartner,
	trackingId,
	totalAmount,
	invoicePayments = [],
	email,
	address,
	website,
	facebook,
	info1Title,
	info1Text,
	info2Title,
	info2Text,
	info3Title,
	info3Text,
	info4Title,
	info4Text,
	attachmentUrl,
	productSpecification,
	additionalContactInfo,
	rowType,
	rowSize,
	dateType,
	isFooterEnabled,
	isItemNameColumnEnabled,
	isImageColumnEnabled,
	isSkuColumnEnabled,
	isWeightColumnEnabled,
	isVolumeColumnEnabled,
	exchangedInternalId,
	invoiceScanType
}) => {

	const getSummaryClassName = row => {
		if (row.title === 'Sub Total') {
			return 'sub-total'
		} else if (row.title === 'Grand Total') {
			return 'grand-total'
		} else if (row.title === 'Due Amount') {
			return 'due-amount'
		}
		return ''
	}

	const pages = useMemo(() => {
		const pageSize = isImageColumnEnabled ? 5 : rowType === RowTypes.FIXED ? rowSize : 20
		const minRows = isImageColumnEnabled ? 5 : rowType === RowTypes.FIXED ? rowSize : 8
		const pages = []
		for (let i = 0; i < invoiceItems.length; i += pageSize) {
			const chunk = invoiceItems.slice(i, i + pageSize)
			pages.push({
				items: chunk
			})
		}
		if (pages.length > 0) {
			const lastPage = pages[pages.length - 1]
			const lastPageSize = lastPage?.items.length
			if (lastPageSize > minRows && lastPageSize <= 15) {
				lastPage.showSummary = true
				pages.push({
					items: []
				})
			} else if (lastPageSize <= minRows) {
				if (rowType === RowTypes.FIXED) {
					const emptyRowSize = minRows - lastPageSize
					const emptyRows = []
					let lastSl = lastPage.items[lastPage.items.length - 1].sl
					for (let i = 0; i < emptyRowSize; i++) {
						lastSl = lastSl + 1
						emptyRows.push({
							sl: lastSl
						})
					}
					lastPage.items.push(...emptyRows)
				}
				lastPage.showSummary = true
			} else {
				if (lastPageSize > minRows) {
					pages.push({
						items: [],
						showSummary: true
					})
				} else {
					lastPage.showSummary = true
				}
			}
		}
		return pages
	}, [invoiceItems, rowType, rowSize, isImageColumnEnabled])

	const summary = useMemo(() => {
		const data = []
		if (subTotalAmount > 0) {
			data.push({
				title: 'Sub Total',
				amount: getFormattedCurrency(subTotalAmount, false)
			})
		}
		if (discountAmount > 0) {
			data.push({
				title: 'Discount',
				amount: `- ${getFormattedCurrency(discountAmount, false)}`
			})
		}
		if (deliveryCharge > 0) {
			data.push({
				title: 'Delivery Fee',
				amount: getFormattedCurrency(deliveryCharge, false)
			})
		}
		const grandTotal = subTotalAmount - discountAmount + deliveryCharge
		if (grandTotal > 0) {
			data.push({
				title: 'Grand Total',
				amount: getFormattedCurrency(grandTotal, false)
			})
		}
		invoicePayments.map(invoiceFee => {
			data.push({
				title: invoiceFee.name,
				amount: `- ${getFormattedCurrency(invoiceFee.amount, false)}`
			})
		})
		data.push({
			title: 'Due Amount',
			amount: getFormattedCurrency(totalAmount, false)
		})
		return data
	}, [
		invoiceItems,
		totalAmount,
		deliveryCharge,
		discountAmount,
		subTotalAmount,
		discount
	])

	const infoItems = useMemo(() => {
		const data = []
		if (info1Title?.trim() || info1Text?.trim()) {
			data.push({
				heading: info1Title,
				content: info1Text
			})
		}
		if (info2Title?.trim() || info2Text?.trim()) {
			data.push({
				heading: info2Title,
				content: info2Text
			})
		}
		if (info3Title?.trim() || info3Text?.trim()) {
			data.push({
				heading: info3Title,
				content: info3Text
			})
		}
		return data
	}, [
		info1Title,
		info1Text,
		info2Title,
		info2Text,
		info3Title,
		info3Text
	])

	const modifiedInvoiceColumns = useMemo(() => {
		return invoiceColumns.filter(column => {
			if (column.key === 'image') {
				return isImageColumnEnabled
			} else if (column.key === 'sku') {
				return isSkuColumnEnabled
			} else if (column.key === 'itemName') {
				return isItemNameColumnEnabled
			} else if (column.key === 'weight') {
				return isWeightColumnEnabled
			} else if (column.key === 'volume') {
				return isVolumeColumnEnabled
			} else {
				return true
			}
		})
	}, [
		productSpecification,
		isImageColumnEnabled,
		isItemNameColumnEnabled,
		isSkuColumnEnabled,
		isWeightColumnEnabled,
		isVolumeColumnEnabled
	])

	return (
		<>
			{
				pages.map((page, index) => {
					return (
						<div className={visible ? styles.page : styles.pageInvisible} key={index}>
							{
								page.items.length > 0 || (page.items.length === 0 && page.showSummary) ?
									<div className={styles.header}>
										{
											isNotEmpty(logoUrl) ?
												<img className={styles.logo} style={{ marginRight: 12 }} src={logoUrl} alt='Logo' /> :
												<img className={styles.logo} style={{ marginRight: 'auto' }} src='/img/logo.svg' alt='Nuport Logo' />
										}
										<div className={styles.headerContent}>
											<h1 className={styles.topHeader}>
												{header}
											</h1>
											<h4 style={{ fontSize: 16, color: '#414141' }}>
												{subHeader}
											</h4>
										</div>
										{
											isNotEmpty(logoUrl) ?
												<img className={styles.logo} style={{ marginLeft: 12, visibility: 'hidden' }} src={logoUrl} alt='Logo' /> :
												<img className={styles.logo} style={{ marginLeft: 12, visibility: 'hidden' }} src='/img/logo.svg' alt='Nuport Logo' />
										}
									</div> : null
							}
							{
								page.items.length > 0 || (page.items.length === 0 && page.showSummary) ?
									<>
										<div style={{ display: 'flex', marginTop: invoiceScanType === InvoiceScanTypes.BARCODE ? 12 : 24 }}>
											<div className={styles.recipientContainer}>
												<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>Bill To:</div>
												<div style={{ fontWeight: 'bolder', color: 'black', fontSize: 14, marginTop: 4, fontFamily: 'sans-serif' }}>{recipientName}</div>
												<div style={{ marginTop: 6, fontSize: 12, fontWeight: 'bold' }}>{recipientPhone}</div>
												<div style={{ marginTop: 4, fontSize: 12, fontWeight: 'bold' }}>{recipientAddress}</div>
											</div>
											<div className={styles.infoContainer} style={{ alignItems: 'flex-end' }}>
												{
													invoiceScanType === InvoiceScanTypes.BARCODE &&
													<Barcode
														height={32}
														value={referenceNumber}
														displayValue={false}
														fontSize={16}
														margin={2}
													/>
												}
												{
													invoiceScanType === InvoiceScanTypes.QR &&
													<QRCode
														size={64}
														value={referenceNumber}
														viewBox={'0 0 64 64'}
														style={{ marginBottom: 2 }}
													/>
												}
												<div style={{ display: 'flex', alignSelf: 'end' }}>
													<div style={{ textAlign: 'right', marginRight: 6, whiteSpace: 'nowrap' }}>Invoice No: </div>
													<div style={{ fontWeight: 'bolder', color: 'black', fontFamily: 'sans-serif', whiteSpace: 'nowrap' }}>{referenceNumber || '--'}</div>
												</div>
												{
													exchangedInternalId &&
													<div style={{ display: 'flex', alignSelf: 'end' }}>
														<div style={{ textAlign: 'right', marginRight: 6, whiteSpace: 'nowrap' }}>Exchanged For: </div>
														<div style={{ fontWeight: 'bolder', color: 'black', fontFamily: 'sans-serif', whiteSpace: 'nowrap' }}>{exchangedInternalId || '--'}</div>
													</div>
												}
												{
													deliveryPartner &&
													<div style={{ display: 'flex', marginTop: 4 }}>
														<div style={{ textAlign: 'right', marginRight: 6, whiteSpace: 'nowrap' }}>Delivery Partner: </div>
														<div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{deliveryPartner.name}</div>
													</div>
												}
												{
													trackingId &&
													<div style={{ display: 'flex', marginTop: 4 }}>
														<div style={{ textAlign: 'right', marginRight: 6, whiteSpace: 'nowrap' }}>Delivery ID: </div>
														<div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{trackingId}</div>
													</div>
												}
												<div style={{ display: 'flex', marginTop: 4 }}>
													<div style={{ textAlign: 'right', marginRight: 6, whiteSpace: 'nowrap' }}>Date: </div>
													{
														dateType === DateTypes.SHIPPING ?
															<div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{moment(orderDate).format('MMMM DD, YYYY')}</div> :
															<div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{moment(creationDate).format('MMMM DD, YYYY')}</div>
													}
												</div>
											</div>
										</div>
										{
											additionalNotes?.trim() &&
											<div style={{ marginTop: 8 }}>
												<span className={styles.noteTitle}>Notes</span>
												<span className={styles.noteText}>{additionalNotes}</span>
											</div>
										}
									</> : null
							}
							{
								page.items.length > 0 &&
								<Table
									style={{ marginTop: 12, width: '186mm' }}
									size='small'
									dataSource={page.items}
									columns={modifiedInvoiceColumns}
									pagination={false}
									locale={{ emptyText: ' ' }}
									bordered={true}
									className='invoice-table'
								/>
							}
							{
								page.showSummary &&
								<div style={{ display: 'flex' }}>
									<div style={{ display: 'flex', flexDirection: 'column', marginTop: 26 }}>
										{
											facebook?.trim() &&
										<div className={styles.contactRow}>
											<img src='/img/invoice-facebook.svg' className={styles.contactIcon} />
											<div className={styles.contactText}>{facebook}</div>
										</div>
										}
										{
											email?.trim() &&
										<div className={styles.contactRow}>
											<img src='/img/invoice-email.svg' className={styles.contactIcon} />
											<div className={styles.contactText}>{email}</div>
										</div>
										}
										{
											website?.trim() &&
										<div className={styles.contactRow}>
											<img src='/img/invoice-globe.svg' className={styles.contactIcon} />
											<div className={styles.contactText}>{website}</div>
										</div>
										}
										{
											address?.trim() &&
										<div className={styles.contactRow}>
											<img src='/img/invoice-pin.svg' className={styles.contactIcon} />
											<div className={styles.contactText}>{address}</div>
										</div>
										}
									</div>
									<Table
										style={{ marginLeft: 'auto' }}
										size='small'
										dataSource={summary}
										columns={summaryColumns}
										pagination={false}
										locale={{ emptyText: ' ' }}
										scroll={{ x: 'max-content' }}
										bordered={true}
										rowClassName={getSummaryClassName}
										className={'invoice-table hidden-header'}
									/>
								</div>
							}
							{
								page.showSummary && infoItems.length > 0 ?
									<div className={styles.infoItemContainer}>
										{
											infoItems.map((infoItem, index) => {
												return (
													<div key={index} className={styles.infoItem}>
														<span className={styles.infoItemHeading}>
															{infoItem.heading}
														</span>
														<span className={styles.infoItemContent}>
															{infoItem.content}
														</span>
													</div>
												)
											})
										}
									</div> : null
							}
							{
								page.showSummary ?
									<div className={styles.infoItemContainerAlt}>
										{
											info4Title || info4Text ?
												<div className={styles.infoItemAlt} style={{ padding: 24 }}>
													<span className={styles.infoItemHeading} style={{ fontSize: 12 }}>
														{info4Title}
													</span>
													<span className={styles.infoItemContent} style={{ fontSize: 12, color: 'black', fontWeight: 'bold' }}>
														{info4Text}
													</span>
												</div> : null
										}
										{
											attachmentUrl &&
											<div className={styles.attachmentImageContainer}>
												<img src={attachmentUrl} />
											</div>
										}
									</div> : null
							}
							{
								index === pages.length - 1 && isFooterEnabled ?
									<div className={!page.showSummary && page.items.length === 0 ? styles.topFooter : styles.footer}>
										{
											page.showSummary || page.items.length !== 0 ?
												<img src='/img/invoice-scissor.svg' className={styles.scissorIcon} /> : null
										}
										<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
											<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'right' }}>
												{
													isNotEmpty(logoUrl) ?
														<img className={styles.logoSmall} style={{ marginRight: 'auto' }} src={logoUrl} alt='Logo' /> :
														<img className={styles.logoSmall} style={{ marginRight: 'auto' }} src='/img/logo.svg' alt='Nuport Logo' />
												}
												<div className={styles.recipientContainer} style={{ marginTop: 12 }}>
													<div style={{ fontWeight: 'bolder', color: 'black', fontSize: 10, fontFamily: 'sans-serif' }}>Bill To:</div>
													<div style={{ fontWeight: 'bolder', color: 'black', fontSize: 12, marginTop: 4, fontFamily: 'sans-serif' }}>{recipientName}</div>
													<div style={{ marginTop: 4, fontSize: 10, fontWeight: 'bold' }}>{recipientPhone}</div>
													<div style={{ marginTop: 4, fontSize: 10, fontWeight: 'bold' }}>{recipientAddress}</div>
													{
														additionalNotes?.trim() &&
											<div style={{ marginTop: 8, maxWidth: 250 }}>
												<span className={styles.noteTitle}>Notes</span>
												<span className={styles.noteText}>{additionalNotes}</span>
											</div>
													}
												</div>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
												<h2 style={{ fontSize: 19, marginBottom: 2, fontWeight: 'bolder', fontFamily: 'sans-serif', textAlign: 'center' }}>
													{header}
												</h2>
												<h4 style={{ fontSize: 12, color: '#414141', textAlign: 'center' }}>
													{subHeader}
												</h4>
											</div>
											<div style={{ display: 'flex', marginLeft: 'auto', marginTop: 32 }}>
												<div className={styles.footerInfo}>
													<div className={styles.infoContainer} style={{ alignItems: 'flex-end' }}>
														{
															invoiceScanType === InvoiceScanTypes.BARCODE &&
															<Barcode
																height={32}
																value={referenceNumber}
																displayValue={false}
																fontSize={16}
																margin={2}
															/>
														}
														{
															invoiceScanType === InvoiceScanTypes.QR &&
															<QRCode
																size={64}
																value={referenceNumber}
																style={{ marginBottom: 2 }}
																viewBox={'0 0 64 64'}
															/>
														}
														<div style={{ display: 'flex', alignSelf: 'end' }}>
															<div style={{ textAlign: 'right', marginRight: 6, fontSize: 12 }}>Invoice No: </div>
															<div style={{ fontWeight: 'bolder', color: 'black', fontSize: 12, fontFamily: 'sans-serif' }}>{referenceNumber || '--'}</div>
														</div>
														{
															deliveryPartner &&
															<div style={{ display: 'flex', marginTop: 4 }}>
																<div style={{ textAlign: 'right', marginRight: 6, fontSize: 10, fontWeight: 'bolder', fontFamily: 'sans-serif' }}>Delivery Partner: </div>
																<div style={{ fontWeight: 'bolder', color: 'black', fontSize: 10 }}>{deliveryPartner.name}</div>
															</div>
														}
														{
															trackingId &&
															<div style={{ display: 'flex', marginTop: 4 }}>
																<div style={{ textAlign: 'right', marginRight: 6, fontSize: 10, fontWeight: 'bolder', fontFamily: 'sans-serif' }}>Delivery ID: </div>
																<div style={{ fontWeight: 'bolder', color: 'black', fontSize: 10 }}>{trackingId}</div>
															</div>
														}
														<div style={{ display: 'flex', marginTop: 4 }}>
															<div style={{ textAlign: 'right', marginRight: 6, fontSize: 10 }}>Date: </div>
															{
																dateType === DateTypes.SHIPPING ?
																	<div style={{ fontWeight: 'bold', fontSize: 10 }}>{moment(orderDate).format('MMMM DD, YYYY')}</div> :
																	<div style={{ fontWeight: 'bold', fontSize: 10 }}>{moment(creationDate).format('MMMM DD, YYYY')}</div>
															}
														</div>
														<div className={styles.dueAmount}>
															<div style={{ flex: 2, textAlign: 'right', color: 'white', fontWeight: 'bold', marginRight: 6 }}>Due Amount: </div>
															<div style={{ flex: 2, color: 'white', fontWeight: 'bold' }}>{getFormattedCurrency(totalAmount)}</div>
														</div>
														{
															additionalContactInfo &&
														<div style={{ display: 'flex', marginTop: 12 }}>
															<div style={{ textAlign: 'right', marginRight: 6, fontSize: 10, fontWeight: 'bold', color: 'black' }}>Contact: </div>
															<div style={{ fontSize: 10, whiteSpace: 'pre-wrap' }}>{additionalContactInfo}</div>
														</div>
														}
													</div>
												</div>
											</div>
										</div>
									</div> : null
							}
							{
								isFooterEnabled && pages.length > 1 ?
									<div className={styles.pageNumber}>{`Page ${`${index + 1}`.padStart(2, '0')}`}</div> : null
							}
						</div>
					)
				})
			}
		</>
	)
}
