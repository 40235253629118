import { Button, Image, Modal, Space, Tooltip } from 'antd'
require('./ScanSalesOrdersModal.less')
import styles from './ScanSalesOrdersModal.module.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomTable from '../table'
import { convertEnumToString, getDeliveryPartnerLogo, getFormattedCurrency } from '../../utils'
import { dateTimeDisplayFormats, eCommerceIntegrations, integrationTypes, orderStatuses, PartnerType, sourceTagColors } from '../../utils/constants'
import moment from 'moment'
import { SALES_ORDER_APPROVED_STATUS, SALES_ORDER_CANCELLED_STATUS, SALES_ORDER_COMPLETED_STATUS, SALES_ORDER_FLAGGED_STATUS, SALES_ORDER_IN_TRANSIT_STATUS, SALES_ORDER_ON_HOLD_STATUS, SALES_ORDER_PROCESSING_STATUS, SALES_ORDER_SHIPPED_STATUS } from '../../pages/sales-orders'
import CustomerTag from '../customer-tag'
import CopyToClipboard from '../copy-to-clipboard'
import TrackDeliveryStatusButton from '../track-delivery-status-button'
import SalesOrders from '../../services/api/sales-orders'
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'
import ECommerceUpdateOrderStatusModal from '../e-commerce-update-order-status-modal'

const allOrdersStatus = 'all'

const ScanSalesOrdersModal = ({
	visible,
	onCancel,
	status,
	subStatus,
	onComplete
}) => {
	const { preferences, permissions } = useSelector(state => state.authReducer)
	const [logs, setLogs] = useState([])
	const [scannedOrders, setScannedOrders] = useState([])
	const [buffer, setBuffer] = useState('')
	const [lastScanTime, setLastScanTime] = useState(0)
	const [isShiftPressed, setIsShiftPressed] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] = useState(false)

	const renderDate = (title, date, highlighted) => {
		return (
			date &&
			<div className={`${styles.dateBadge} ${highlighted ? styles.highlighted : ''}`}>
				<span>{title}</span>
				{preferences.dateTimeDisplayFormat === dateTimeDisplayFormats.DATE ? moment(date).format('MMM D, YYYY') : moment(date).format('MMM D, YYYY hh:mm A')}
			</div>
		)
	}

	const renderDateByStatus = (status, salesOrder) => {
		const {
			onHoldAt,
			approvedAt,
			processedAt,
			shippedAt,
			inTransitAt,
			deliveredAt,
			cancelledAt,
			flaggedAt
		} = salesOrder
		switch (status) {
			case SALES_ORDER_ON_HOLD_STATUS:
				return renderDate('On Hold', onHoldAt)
			case SALES_ORDER_APPROVED_STATUS:
				return renderDate('Approved', approvedAt)
			case SALES_ORDER_PROCESSING_STATUS:
				return renderDate('Processing', processedAt)
			case SALES_ORDER_SHIPPED_STATUS:
				return renderDate('Shipped', shippedAt)
			case SALES_ORDER_IN_TRANSIT_STATUS:
				return renderDate('In-Transit', inTransitAt)
			case SALES_ORDER_CANCELLED_STATUS:
				return renderDate('Cancelled', cancelledAt)
			case SALES_ORDER_COMPLETED_STATUS:
				return renderDate('Delivered', deliveredAt)
			case SALES_ORDER_FLAGGED_STATUS:
				return renderDate('Flagged', flaggedAt)
		}
	}

	const columns = [
		{
			title: 'Invoice No',
			key: 'invoiceNo',
			className: 'invoice-column',
			render: (salesOrder) => {
				return (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div className={styles.invoiceNoContainer}>
							{salesOrder.internalId}
						</div>
						{
							salesOrder.source &&
							<div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
								<div
									className={styles.sourceTag}
									style={{
										background: sourceTagColors[salesOrder.source]?.background,
										color: sourceTagColors[salesOrder.source]?.color
									}}
								>
									{convertEnumToString(salesOrder.source.toLowerCase())}
								</div>
								{
									salesOrder.integration ?
										(salesOrder.integration.type === integrationTypes.WOOCOMMERCE || salesOrder.integration.type === integrationTypes.SHOPIFY) && salesOrder.metadata?.sourceUrl ?
											<Tooltip title={salesOrder.metadata?.sourceUrl}>
												<img
													style={{ height: 32 }}
													src={eCommerceIntegrations[salesOrder.integration.type]}
												/>
											</Tooltip> :
											<img
												style={{ height: 32 }}
												src={eCommerceIntegrations[salesOrder.integration.type]}
											/> :
										null
								}
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Date',
			key: 'creationDate',
			render: (salesOrder) => {
				const {
					createdAt,
					orderDate,
					returnedAt,
					damagedAt
				} = salesOrder
				if (status === SALES_ORDER_FLAGGED_STATUS && subStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS) {
					return (
						<div className={styles.datesContainer}>
							{renderDate('Created', createdAt)}
							{renderDate('Returned', returnedAt)}
							{renderDate('Damaged', damagedAt)}
						</div>
					)
				} else if (status === allOrdersStatus) {
					return (
						<div className={styles.datesContainer}>
							{renderDate('Created', createdAt)}
							{renderDate('Shipping', orderDate)}
							{renderDateByStatus(salesOrder.status.toLowerCase(), salesOrder)}
						</div>
					)
				} else {
					return (
						<div className={styles.datesContainer}>
							{renderDate('Created', createdAt)}
							{renderDate('Shipping', orderDate)}
							{renderDateByStatus(status, salesOrder)}
						</div>
					)
				}
			}
		},
		{
			title: 'Customer',
			key: 'customer',
			render: (salesOrder) => {
				const distributor = salesOrder.distributor
				const location = salesOrder.location || {}
				const { label, address } = location

				return (
					<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
						<div className={styles.customerNameContainer}>
							<div className={styles.customerName}>
								{distributor?.name}
							</div>
							<Space>
								{
									distributor && distributor.customerTag &&
									<CustomerTag name={distributor?.customerTag?.name} />
								}
							</Space>
						</div>
						<div className={styles.customerPhoneContainer}>
							<div className={styles.customerPhone}>
								{distributor?.phone}
							</div>
							<CopyToClipboard text={distributor?.phone} style={{ width: 20, height: 20 }} />
						</div>
						<div className={styles.customerLocation}>{distributor?.name !== label ? label || address : address}</div>
					</div>
				)
			}
		},
		{
			title: 'Pick Up Address',
			key: 'pickUpAddress',
			render: (salesOrder) => {
				const { pickUpLocation, branchLocation } = salesOrder
				return (
					<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<div className={styles.badge}>
								{convertEnumToString(pickUpLocation.type.toLowerCase())}
							</div>
							{
								pickUpLocation.deletedAt ?
									<div>{pickUpLocation?.label || pickUpLocation?.address}</div> :
									<div>
										<a>{pickUpLocation?.label || pickUpLocation?.address}</a>
									</div>
							}
						</div>
						{
							branchLocation &&
							<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
								<div className={styles.badge}>
									{convertEnumToString(branchLocation.type.toLowerCase())}
								</div>
								<div>{branchLocation?.label || branchLocation?.address}</div>
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Payments Info',
			key: 'paymentsInfo',
			render: (salesOrder) => {
				const dueAmount = +salesOrder.totalDueAmount - +salesOrder.totalPaidAmount

				return (
					<div style={{ display: 'grid', gap: '3px' }}>
						<p>
							<span style={{ fontWeight: 500 }}>Sales Amount:</span> {getFormattedCurrency(+salesOrder.totalAmount)}
						</p>
						<p>
							<span style={{ fontWeight: 500 }}>Paid Amount:</span> {getFormattedCurrency(+salesOrder.totalPaidAmount)}
						</p>
						<p>
							<span style={{ fontWeight: 500 }}>Due Amount:</span> {getFormattedCurrency(dueAmount)}
						</p>
					</div>
				)
			}
		},
		{
			title: 'Delivery Partner',
			key: 'preferredDeliveryPartner',
			render: (salesOrder) => {
				const preferredDeliveryPartner = salesOrder.deliveryPartner
				const consignment = salesOrder.deliveryConsignment
				const deliveryStatus = consignment ? consignment.status : salesOrder.deliveryStatus
				const deliveryId = consignment?.id || salesOrder.deliveryOrderId || salesOrder.deliveryTrackingId
				if (!preferredDeliveryPartner) {
					return
				}
				const { id, name, type } = preferredDeliveryPartner
				const { src, alt } = getDeliveryPartnerLogo(type)
				return (
					<div>
						{
							id ?
								<>
									<div className={styles.deliveryPartnerNameColumn}>
										<Image
											src={src}
											width={24}
											height={24}
											alt={alt}
										/>
										{name}
									</div>
									{
										deliveryStatus &&
										<div className={styles.deliveryStatus}>
											<span style={{ fontWeight: 500 }}>Status:&nbsp;</span>
											<TrackDeliveryStatusButton
												status={deliveryStatus}
												partnerType={type}
												trackingCode={consignment?.trackingCode}
												trackingUrl={consignment?.trackingUrl}
												phone={salesOrder?.distributor?.phone}
												disabled={[PartnerType.POST_EX, PartnerType.MP_COURIER, PartnerType.LEOPARDS].includes(type)}
											/>
										</div>
									}
									{
										deliveryId &&
										<div className={styles.deliveryStatus}>
											<span style={{ fontWeight: 500 }}>
												{
													type === PartnerType.PAKISTAN_POST ?
														'VPL:' :
														'ID:'
												}
												&nbsp;
											</span>
											{deliveryId}
										</div>
									}
								</> :
								null
						}
					</div>
				)
			}
		},
		{
			title: 'Delivery Fee',
			key: 'deliveryFee',
			render: (salesOrder) => {
				const {
					deliveryCharge
				} = salesOrder
				return (
					+deliveryCharge ? getFormattedCurrency(+deliveryCharge) : null
				)
			}
		},
		{
			title: 'Action',
			key: 'action',
			width: 100,
			render: (salesOrder) => {
				return (
					<Button
						icon={<DeleteOutlined />}
						onClick={() => {
							setLogs(prev => [`Removed: ${salesOrder.internalId}`, ...prev])
							setScannedOrders(prev => prev.filter(order => order.id !== salesOrder.id))
						}}
					/>
				)
			}
		}
	]

	useEffect(() => {
		const handleKeyPress = (event) => {
			const currentTime = new Date().getTime()

			if (event.key === 'Shift') {
				setIsShiftPressed(true)
				return
			}

			// Most barcode scanners complete their input within 100ms
			// If it's been more than 100ms since the last input, start a new buffer
			if (currentTime - lastScanTime > 100) {
				setBuffer(event.key)
			} else {
				const newChar = isShiftPressed ? event.key.toUpperCase() : event.key
				setBuffer(prev => prev + newChar)
			}

			setIsShiftPressed(false)
			setLastScanTime(currentTime)

			// If we receive an Enter key, assume it's the end of a barcode scan
			if (event.key === 'Enter') {
				processScannedData(buffer)
				setBuffer('')
			}
		}

		window.addEventListener('keydown', handleKeyPress)
		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [buffer, lastScanTime])

	const processScannedData = (data) => {
		setLogs(prev => [`Scanned: ${data}`, ...prev])
		getSalesOrder(data)
	}

	const getSalesOrder = async (internalId) => {
		try {
			if (scannedOrders.find(order => order.internalId === internalId)) {
				setLogs(prev => [`${internalId}: has already been scanned.`, ...prev])
				return
			}
			setIsLoading(true)
			const response = await SalesOrders.single(
				internalId,
				{},
				undefined,
				undefined,
				true
			)
			const { data } = response
			if (data) {
				let statusLabel = convertEnumToString(status)
				if (status === 'requested') {
					statusLabel = 'pending'
				} else if (status === 'completed') {
					statusLabel = 'delivered'
				}
				if (data.status?.toLowerCase() !== status) {
					setLogs(prev => [`${internalId} is not available in ${statusLabel} orders list`, ...prev])
					return
				} else if (data.subStatus && subStatus && data.subStatus?.toLowerCase() !== subStatus) {
					setLogs(prev => [`${internalId} is not available in ${statusLabel} -> ${convertEnumToString(subStatus)} orders list`, ...prev])
					return
				}

				setScannedOrders(prev => [data, ...prev])
			} else {
				setLogs(prev => ['Scanned Order is not found in the system.', ...prev])
			}
		} catch (e) {
			setLogs(prev => ['Scanned Order is not found in the system.', ...prev])
		} finally {
			setIsLoading(false)
		}
	}

	const cancel = () => {
		if (scannedOrders.length > 0) {
			Modal.confirm({
				title: 'Are you sure you want to cancel processing the scanned items?',
				okText: 'Yes',
				cancelText: 'No',
				onOk: () => {
					onCancel()
					setScannedOrders([])
					setLogs([])
				}
			})
		} else {
			onCancel()
		}
	}

	const onOk = () => {
		setIsUpdateStatusModalVisible(true)
	}

	return (
		<Modal
			title={
				<div className={styles.countContainer}>
				Scanned Orders
					<div className={styles.count}>
						{scannedOrders.length}
					</div>
				</div>
			}
			visible={visible}
			onCancel={cancel}
			maskClosable={false}
			okText='Confirm'
			onOk={onOk}
			okButtonProps={{
				loading: isLoading,
				disabled: scannedOrders.length === 0
			}}
			width={'85%'}
			className='scanned-sales-orders-modal'
		>
			<div>
				<div className={styles.uploadContainer}>
					<div style={{ fontSize: 12, display: 'flex', alignItems: 'center', gap: 8 }}>
						<InfoCircleOutlined />
					Make sure your scanner is connected and focused on the barcode or QR code of your invoice.
					</div>
					<CustomTable
						columns={columns}
						dataSource={scannedOrders}
						fallback='asdas'
						rowKey='id'
						isDataSourceEmpty={scannedOrders.length === 0}
						loading={isLoading}
						pagination={{
							pageSize: 50,
							total: scannedOrders.length,
							position: ['topRight'],
							showSizeChanger: false
						}}
					/>
				</div>
				{
					logs && logs.length > 0 ?
						<div className={styles.output}>
							{
								logs.map((data, index) => {
									return (
										<div key={index} className={styles.outputRow}>
											{data}
										</div>
									)
								})
							}
						</div> : null
				}
				{
					isUpdateStatusModalVisible &&
					<ECommerceUpdateOrderStatusModal
						visible={isUpdateStatusModalVisible}
						salesOrderIds={scannedOrders.map(order => order.id)}
						status={status === allOrdersStatus ? null : status}
						subStatus={subStatus}
						onComplete={(previousStatus, currentStatus, updatedSalesOrderIds) => onComplete(previousStatus, currentStatus, updatedSalesOrderIds)}
						onCancel={() => setIsUpdateStatusModalVisible(false)}
						permissions={permissions}
					/>
				}
			</div>
		</Modal>
	)
}

export default ScanSalesOrdersModal
