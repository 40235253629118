require('./ECommerceSalesOrderPage.less')
import styles from './ECommerceSalesOrderPage.module.css'
import { Checkbox, Menu, notification, Popover, Tabs, Tooltip, Space, Button, Dropdown } from 'antd'
import { ExportOutlined, UploadOutlined, PrinterOutlined, SwapOutlined, InfoCircleOutlined, CalendarOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons'
import { createRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import HeaderTab from '../../header-tab'
import { eCommerceOrderTabs, industries, orderStatuses, paths, returnOrCancelReasons, EXPORT_TYPE, eCommerceIntegrations, dateTimeDisplayFormats, PartnerType, integrationTypes, onHoldReasons, inventoryCheckModes, partnerTypeNames, viewLocationTagModes, sourceTagColors } from '../../../utils/constants'
import useSearchParams from '../../../hooks/useSearchParams'
import { selectSalesOrderIds, updateSalesOrderCounts, updateSalesOrders } from '../../../store/sales-orders/actions'
import CustomTable from '../../table'
import moment from 'moment'
import { convertEnumToString, getDeliveryPartnerLogo, getFormattedCurrency, isOverflowing } from '../../../utils'
import SalesOrdersExportModal from '../../sales-orders-export-modal'
import UploadOrdersModal from '../../upload-orders-modal'
import PrintSalesOrderChallansModal from '../../print-sales-order-challans-modal'
import { SalesOrders } from '../../../services/api/sales-orders'
import ECommerceUpdateOrderStatusModal from '../../e-commerce-update-order-status-modal'
import Link from 'next/link'
import { DeliveryPartners } from '../../../services/api/delivery-partner'
import PrintSalesOrderInvoicesModal from '../../../components/print-sales-order-invoices-modal'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import UpdateOrderShippingDateModal from '../../update-order-shipping-date-modal'
import SubTab from '../../sub-tab'
import Image from 'next/image'
import InvoicePopoverContent from '../../invoice-popover-content'
import CopyToClipboard from '../../copy-to-clipboard'
import SalesOrderInternalNotesPopover from '../../sales-order-internal-notes-popover'
import { Pdf } from '../../../services/api/pdf'
import { internalNotesModifier } from '../../../utils/sales-orders'
import CustomEmptySecondary from '../../custom-empty-secondary'
import UpdateOrdersDeliveryPartnerModal from '../../update-orders-delivery-partner-modal'
import axios from 'axios'
import { usePage } from '../../../hooks/usePage'
import CustomerDataPopover from '../../customer-data-popover'
import CustomerTag from '../../customer-tag'
import SalesOrdersPreferenceModal from '../../sales-orders-preference-modal'
import ExportDeliveryOrdersModal from '../../export-delivery-orders-modal'
import TrackDeliveryStatusButton from '../../track-delivery-status-button'
import PresenceIndicator from '../../presence-indicator'
import { usePrevious } from '../../../hooks/usePrevious'
import PrintSalesOrderPostalOrderFormsModal from '../../print-sales-order-postal-orders-modal'
import PrintSalesOrderPakistanPostSlipsModal from '../../print-sales-order-pakistan-post-modal'
import ProductPickingModal from '../../product-picking-modal'
import ScanSalesOrdersModal from '../../scan-sales-orders-modal'

const {
	SALES_ORDER_REQUESTED_STATUS,
	SALES_ORDER_ON_HOLD_STATUS,
	SALES_ORDER_FLAGGED_STATUS,
	SALES_ORDER_APPROVED_STATUS,
	SALES_ORDER_PROCESSING_STATUS,
	SALES_ORDER_COMPLETED_STATUS,
	SALES_ORDER_SHIPPED_STATUS,
	SALES_ORDER_IN_TRANSIT_STATUS,
	SALES_ORDER_CANCELLED_STATUS
} = orderStatuses

const flaggedSubTabs = [
	{
		title: 'Returned',
		query: 'subStatus',
		value: 'returned'
	},
	{
		title: 'Damaged',
		query: 'subStatus',
		value: 'damaged'
	}
]

const completedSubTabs = [
	{
		title: 'Payment Due',
		query: 'subStatus',
		value: 'payment_due'
	},
	{
		title: 'Payment Collected',
		query: 'subStatus',
		value: 'payment_collected'
	}
]

const allOrdersStatus = 'all'
const allPartiallyDeliveredStatus = 'all_partially_delivered'

const cancelledSubTabs = [
	{
		title: returnOrCancelReasons.HIGH_PRICE,
		query: 'cancellationReason',
		value: returnOrCancelReasons.HIGH_PRICE
	},
	{
		title: returnOrCancelReasons.SHORT_TIME_DELIVERY,
		query: 'cancellationReason',
		value: returnOrCancelReasons.SHORT_TIME_DELIVERY
	},
	{
		title: returnOrCancelReasons.OUT_OF_ZONE,
		query: 'cancellationReason',
		value: returnOrCancelReasons.OUT_OF_ZONE
	},
	{
		title: returnOrCancelReasons.DUPLICATE_ORDER,
		query: 'cancellationReason',
		value: returnOrCancelReasons.DUPLICATE_ORDER
	},
	{
		title: returnOrCancelReasons.FAKE_ORDER,
		query: 'cancellationReason',
		value: returnOrCancelReasons.FAKE_ORDER
	},
	{
		title: returnOrCancelReasons.CHANGED_MIND,
		query: 'cancellationReason',
		value: returnOrCancelReasons.CHANGED_MIND
	},
	{
		title: returnOrCancelReasons.OTHER_REASON,
		query: 'cancellationReason',
		value: returnOrCancelReasons.OTHER_REASON
	}
]

const ECommerceSalesOrderPage = () => {
	const router = useRouter()
	const { isReady } = router
	const { status, subStatus, deliveryStatus, preferredDeliveryPartner, district, division, cancellationReason } = router.query
	const statusRef = useRef()
	const subStatusRef = useRef()
	const dropdownRef = useRef()
	const dispatch = useDispatch()
	const { permissions, companyDetails, enabledOrderStatuses, userProfile, preferences } = useSelector(state => state.authReducer)
	const {
		totalCount,
		page,
		allSalesOrders,
		requestedSalesOrders,
		onHoldSalesOrders,
		flaggedSalesOrders,
		approvedSalesOrders,
		processingSalesOrders,
		completedSalesOrders,
		cancelledSalesOrders,
		shippedSalesOrders,
		inTransitSalesOrders,
		salesOrderCounts,
		allSelectedSalesOrderIds,
		selectedSalesOrderIds: statusSelectedSalesOrderIds,
		selectedSalesOrders
	} = useSelector(state => state.salesOrdersReducer)
	const { setBackPageLink } = usePage()
	const [salesAmounts, setSalesAmounts] = useState({})
	const [deliveryPartners, setDeliveryPartners] = useState([])
	const [isPrintSalesOrderInvoicesModalVisible, setIsPrintSalesOrderInvoicesModalVisible] = useState(false)
	const [salesOrderIdToPrint, setSalesOrderIdToPrint] = useState()
	const [isPrintSalesOrderPostalFormModalVisible, setIsPrintSalesOrderPostalFormModalVisible] = useState(false)
	const [isPrintSalesOrderPakistanPostSlipModalVisible, setIsPrintSalesOrderPakistanPostSlipModalVisible] = useState(false)
	const { searchParams, isSearchApplied } = useSearchParams()
	const [shippedSubTabs, setShippedSubTabs] = useState([])
	const [inTransitSubTabs, setInTransitSubTabs] = useState([])
	const [customDeliveryPartners, setCustomDeliveryPartners] = useState([])
	const [exportType, setExportType] = useState()
	const [tableData, setTableData] = useState([])
	const [tableColumns, setTableColumns] = useState([])
	const [filteredTableColumns, setFilteredTableColumns] = useState([])
	const [duplicateCustomerIds, setDuplicateCustomerIds] = useState(new Set([]))
	const [currentStatus, setCurrentStatus] = useState(null)
	const [currentSubStatus, setCurrentSubStatus] = useState(null)
	const [filterColumnsMap, setFilterColumnsMap] = useLocalStorage('nuportEcommerceOrderTableFilterColumnsMap', {})
	const currentFilterColumnsName = useMemo(() => `${status}OrdersFilterColumns`, [isReady, status])
	const currentFilterColumns = filterColumnsMap[currentFilterColumnsName]
	const [duplicateOrdersCount, setDuplicateOrdersCount] = useState(0)
	const [showDuplicateOrders, setShowDuplicateOrders] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)
	const [isSalesOrdersExportModalVisible, setIsSalesOrdersExportModalVisible] = useState(false)
	const [isSalesOrdersExportSummaryModalVisible, setIsSalesOrdersExportSummaryModalVisible] = useState(false)
	const [isUploadOrdersModalVisible, setIsUploadOrdersModalVisible] = useState(false)
	const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] = useState(false)
	const [isScanSalesOrdersModalVisible, setIsScanSalesOrdersModalVisible] = useState(false)
	const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
	const [isUpdateOrdersDeliveryPartnerModalVisible, setIsUpdateOrdersDeliverPartnerModalVisible] = useState(false)
	const [isPickingModalVisible, setIsPickingModalVisible] = useState(false)
	const [isPrintSalesOrderChallansModalVisible, setIsPrintSalesOrderChallansModalVisible] = useState(false)
	const [isUpdateShippingDateModalVisible, setIsUpdateShippingDateModalVisible] = useState(false)
	const [subTabsExpanded, setSubTabsExpanded] = useState(false)
	const [isSubTabsContainerOverflowing, setIsSubTabsContainerOverflowing] = useState(false)
	const [isPreferenceModalVisible, setIsPreferenceModalVisible] = useState(false)
	const [isExportDeliveryOrdersModalVisible, setIsExportDeliveryOrdersModalVisible] = useState(false)
	const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false)
	const [isTableDataEmpty, setIsTableDataEmpty] = useState(false)
	const [showDeliveryExport, setShowDeliveryExport] = useState(false)
	const [pageSize, setPageSize] = useState(50)
	const [isLoadingCounts, setIsLoadingCounts] = useState(false)
	const [isFastActionLoading, setIsFastActionLoading] = useState(false)
	const [partiallyDeliveredDeliveryPartners, setPartiallyDeliveredDeliveryPartners] = useState([])
	const [isPartiallyDeliveredDeliveryPartnersLoading, setIsPartiallyDeliveredDeliveryPartnersLoading] = useState(false)
	const [partiallyDeliveredDeliveryPartnersCount, setPartiallyDeliveredDeliveryPartnersCount] = useState(0)
	const [isDeliveryPartnersDropdownVisible, setIsDeliveryPartnersDropdownVisible] = useState(false)
	const subTabsContainerRef = createRef()
	statusRef.current = currentStatus
	subStatusRef.current = currentSubStatus
	const selectedSalesOrderIds = currentStatus === allOrdersStatus ? allSelectedSalesOrderIds : statusSelectedSalesOrderIds

	const onUploadOrder = () => setIsUploadOrdersModalVisible(true)
	const onUpdateOrderStatuses = () => setIsUpdateStatusModalVisible(true)

	const isUploadEnabled = useMemo(() => {
		if (companyDetails?.industry === industries.E_COMMERCE) {
			if (companyDetails.isBulkUploadEnabled && companyDetails.bulkUploadFlags?.isOrderUploadEnabled) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}, [companyDetails])

	const shouldShowDuplicateOrderToggleButton = useMemo(() => {
		if (status === SALES_ORDER_REQUESTED_STATUS && preferences?.isDuplicatePendingOrderCheckEnabled && requestedSalesOrders.length > 1) {
			return true
		}
		if (status === SALES_ORDER_PROCESSING_STATUS && preferences?.isDuplicateProcessingOrderCheckEnabled && processingSalesOrders.length > 1) {
			return true
		}
		return false
	}, [status, preferences, requestedSalesOrders, processingSalesOrders])

	const { selectedDeliveryPartner, noSelectedDeliveryPartners } = useMemo(() => {
		if (status !== orderStatuses.SALES_ORDER_APPROVED_STATUS &&
			status !== orderStatuses.SALES_ORDER_PROCESSING_STATUS &&
			status !== orderStatuses.SALES_ORDER_SHIPPED_STATUS) {
			return {
				selectedDeliveryPartner: undefined,
				noSelectedDeliveryPartners: true
			}
		}
		const filteredSelectedSalesOrder = selectedSalesOrders.filter(salesOrder => salesOrder)
		if (filteredSelectedSalesOrder.length === 0) {
			return {
				selectedDeliveryPartner: undefined,
				noSelectedDeliveryPartners: true
			}
		}
		let firstDeliveryPartner = undefined
		for (const selectedSalesOrder of filteredSelectedSalesOrder) {
			const preferredDeliveryPartner = selectedSalesOrder?.deliveryPartner
			if (preferredDeliveryPartner && preferredDeliveryPartner.type !== PartnerType.CUSTOM) {
				firstDeliveryPartner = preferredDeliveryPartner
			}
		}
		const noSelectedDeliveryPartners = filteredSelectedSalesOrder.every(salesOrder => !salesOrder?.deliveryPartner)
		const allSame = filteredSelectedSalesOrder.every(salesOrder => {
			const preferredDeliveryPartner = salesOrder?.deliveryPartner
			if (preferredDeliveryPartner) {
				const { id } = preferredDeliveryPartner
				return id === firstDeliveryPartner?.id
			} else {
				return true
			}
		})
		return {
			selectedDeliveryPartner: allSame ? firstDeliveryPartner : undefined,
			noSelectedDeliveryPartners
		}
	}, [selectedSalesOrders, status])

	const {
		showFastAction,
		showApproveAction,
		showInTransitAction,
		showDeliveredPaymentCollectedAction
	} = useMemo(() => {
		let showFastAction = false
		let showApproveAction = false
		let showInTransitAction = false
		let showDeliveredPaymentCollectedAction = false
		switch (status) {
			case orderStatuses.SALES_ORDER_REQUESTED_STATUS: {
				if (preferences?.isFastActionApprovedEnabled) {
					showFastAction = true
					showApproveAction = true
				}
				break
			}
			case orderStatuses.SALES_ORDER_APPROVED_STATUS: {
				if (preferences?.isFastActionInTransitEnabled && preferences?.isFastActionInTransitFromApprovedEnabled) {
					showFastAction = true
					showInTransitAction = true
				}
				break
			}
			case orderStatuses.SALES_ORDER_PROCESSING_STATUS: {
				if (preferences?.isFastActionInTransitEnabled && preferences?.isFastActionInTransitFromProcessingEnabled) {
					showFastAction = true
					showInTransitAction = true
				}
				break
			}
			case orderStatuses.SALES_ORDER_SHIPPED_STATUS: {
				if (preferences?.isFastActionInTransitEnabled && preferences?.isFastActionInTransitFromShippedEnabled) {
					showFastAction = true
					showInTransitAction = true
				}
				break
			}
			case orderStatuses.SALES_ORDER_COMPLETED_STATUS: {
				if (preferences?.isFastActionDeliveredPaymentCollectedEnabled && subStatus !== orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS) {
					showFastAction = true
					showDeliveredPaymentCollectedAction = true
				}
				break
			}
		}
		return { showFastAction, showApproveAction, showInTransitAction, showDeliveredPaymentCollectedAction }
	}, [status, subStatus, preferences])

	const isPrintSalesOrderPostalFormEnabled = useMemo(() => {
		return deliveryPartners.some(deliveryPartner => deliveryPartner.type === PartnerType.PAKISTAN_POST)
	}, [deliveryPartners])

	const isInvoiceScanEnabled = useMemo(() => {
		if (companyDetails) {
			return companyDetails.documentTemplates?.isInvoiceScanEnabled
		} else {
			return false
		}
	}, [companyDetails])

	const onInternalNotesUpdate = async (orderId, notes) => {
		const { data: { internalNotes } } = await SalesOrders.update(orderId, {
			internalNotes: internalNotesModifier(notes, userProfile?.name)
		})
		const updatedTableData = [...tableData]
		const salesOrder = tableData.find(({ id }) => orderId === id)
		salesOrder.internalNotes = internalNotes
		setTableData(updatedTableData)
	}

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				setIsActionDropdownOpen(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [dropdownRef])

	useEffect(() => {
		const handleClick = ({ target }) => {
			const table = target.closest('.e-commerce-sales-order-table')
			const checkboxElement = target.closest('.ant-checkbox-input')

			if (!table) {
				return
			}
			if (!checkboxElement) {
				return setIsActionDropdownOpen(false)
			}
			if (selectedSalesOrderIds.length > 0) {
				setIsActionDropdownOpen(true)
			} else {
				setIsActionDropdownOpen(false)
			}
		}

		window.addEventListener('click', handleClick)

		return () => {
			window.removeEventListener('click', handleClick)
		}
	}, [selectedSalesOrderIds])

	useEffect(() => {
		getDeliveryPartners()
	}, [])

	const previousViewLocationTagsMode = usePrevious(preferences.viewLocationTagsMode)
	useEffect(() => {
		if (previousViewLocationTagsMode !== preferences.viewLocationTagsMode) {
			getAdministrativeLevels()
			if (previousViewLocationTagsMode) {
				onPageChanged(1)
			}
		}
	}, [preferences.viewLocationTagsMode])

	const initialTableColumns = [
		{
			title: 'Invoice No',
			key: 'invoiceNo',
			className: 'invoice-column',
			render: (salesOrder) => {
				return (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
							<Popover
								overlayClassName='item-details-popover'
								placement='rightTop'
								arrowPointAtCenter
								trigger={['hover', 'click']}
								content={<InvoicePopoverContent id={salesOrder.id} status={status} />}
								destroyTooltipOnHide={true}
								defaultVisible={false}
							>
								<InfoCircleOutlined
									style={{ fontSize: 16, cursor: 'pointer' }}
								/>
							</Popover>
							<CopyToClipboard text={salesOrder.internalId} style={{ width: 20, height: 20 }} />
							<Tooltip title={salesOrder.printedAt ? 'Already Printed' : 'Print Invoice'}>
								<PrinterOutlined
									style={{
										cursor: 'pointer',
										fontSize: 18,
										color: salesOrder.printedAt ? '#288ea5' : '#158A74'
									}}
									onClick={() => setSalesOrderIdToPrint(salesOrder.id)}
								/>
							</Tooltip>
							{
								[SALES_ORDER_REQUESTED_STATUS, SALES_ORDER_APPROVED_STATUS, SALES_ORDER_PROCESSING_STATUS, SALES_ORDER_ON_HOLD_STATUS].includes(salesOrder.status.toLowerCase()) &&
								<EditOutlined
									style={{
										fontSize: 18,
										cursor: permissions.editOrders ? 'pointer' : 'not-allowed',
										color: permissions.editOrders ? '#288ea5' : 'rgba(0, 0, 0, 0.25)'
									}}
									onClick={() => {
										if (!permissions.editOrders) {
											return
										}
										router.push(`/create-sales-order?salesOrderId=${salesOrder.id}&fromDataTable=true${salesOrder.isStockTransfer ? '&stockTransfer=true' : ''}`)
									}}
								/>
							}
						</div>
						<div className={styles.invoiceNoContainer}>
							<Link href={{
								pathname: `${paths.SALES_ORDERS}/${salesOrder.id}`,
								query: {
									status,
									subStatus,
									district,
									division,
									preferredDeliveryPartner,
									cancellationReason
								}
							}}
							>
								<a>{salesOrder.internalId}</a>
							</Link>
						</div>
						{
							salesOrder.source &&
							<div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
								<div
									className={styles.sourceTag}
									style={{
										background: sourceTagColors[salesOrder.source]?.background,
										color: sourceTagColors[salesOrder.source]?.color
									}}
								>
									{convertEnumToString(salesOrder.source.toLowerCase())}
								</div>
								{
									salesOrder.integration ?
										(salesOrder.integration.type === integrationTypes.WOOCOMMERCE || salesOrder.integration.type === integrationTypes.SHOPIFY) && salesOrder.metadata?.sourceUrl ?
											<Tooltip title={salesOrder.metadata?.sourceUrl}>
												<img
													style={{ height: 32 }}
													src={eCommerceIntegrations[salesOrder.integration.type]}
												/>
											</Tooltip> :
											<img
												style={{ height: 32 }}
												src={eCommerceIntegrations[salesOrder.integration.type]}
											/> :
										null
								}
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Date',
			key: 'creationDate',
			width: preferences.dateTimeDisplayFormat === dateTimeDisplayFormats.DATE ? '160px' : '240px',
			render: (salesOrder) => {
				const {
					createdAt,
					orderDate,
					returnedAt,
					damagedAt
				} = salesOrder
				if (status === SALES_ORDER_FLAGGED_STATUS && subStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS) {
					return (
						<div className={styles.datesContainer}>
							{renderDate('Created', createdAt)}
							{renderDate('Returned', returnedAt)}
							{renderDate('Damaged', damagedAt)}
						</div>
					)
				} else if (status === allOrdersStatus) {
					return (
						<div className={styles.datesContainer}>
							{renderDate('Created', createdAt)}
							{renderDate('Shipping', orderDate)}
							{renderDateByStatus(salesOrder.status.toLowerCase(), salesOrder)}
						</div>
					)
				} else {
					return (
						<div className={styles.datesContainer}>
							{renderDate('Created', createdAt)}
							{renderDate('Shipping', orderDate)}
							{renderDateByStatus(status, salesOrder)}
						</div>
					)
				}
			}
		},
		{
			title: 'Follow Up Date',
			key: 'followUpDate',
			render: (salesOrder) => {
				return (
					salesOrder.followUpDate ?
						moment(salesOrder.followUpDate).format('MMM D, YYYY') : null
				)
			}
		},
		{
			title: 'Customer',
			key: 'customer',
			render: (salesOrder) => {
				const distributor = salesOrder.distributor
				const location = salesOrder.location || {}
				const { label, address } = location

				return (
					<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
						<div className={styles.customerNameContainer}>
							<Link href={`${paths.CUSTOMERS}/${distributor.id}`}>
								<a className={styles.customerName}>
									{distributor?.name}
								</a>
							</Link>
							<Space>
								{
									distributor && distributor.customerTag &&
									<CustomerTag name={distributor?.customerTag?.name} />
								}
								<Popover
									overlayClassName='customer-data-popover'
									placement='rightTop'
									arrowPointAtCenter
									trigger={['hover', 'click']}
									content={<CustomerDataPopover customerId={distributor.id} />}
									destroyTooltipOnHide={true}
									defaultVisible={false}
								>
									<InfoCircleOutlined
										style={{ fontSize: 16, cursor: 'pointer' }}
									/>
								</Popover>
							</Space>
						</div>
						<div className={styles.customerPhoneContainer}>
							<p className={`${styles.customerPhone} duplicate-phone`}>
								{distributor?.phone}
							</p>
							<CopyToClipboard text={distributor?.phone} style={{ width: 20, height: 20 }} />
						</div>
						<div className={styles.customerLocation}>{distributor?.name !== label ? label || address : address}</div>
					</div>
				)
			}
		},
		{
			title: 'Pick Up Address',
			key: 'pickUpAddress',
			render: (salesOrder) => {
				const { pickUpLocation, branchLocation } = salesOrder
				return (
					<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<div className={styles.badge}>
								{convertEnumToString(pickUpLocation.type.toLowerCase())}
							</div>
							{
								pickUpLocation.deletedAt ?
									<div>{pickUpLocation?.label || pickUpLocation?.address}</div> :
									<Link href={`${paths.WAREHOUSES}/${pickUpLocation?.id}`}>
										<a>{pickUpLocation?.label || pickUpLocation?.address}</a>
									</Link>
							}
						</div>
						{
							branchLocation &&
							<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
								<div className={styles.badge}>
									{convertEnumToString(branchLocation.type.toLowerCase())}
								</div>
								<div>{branchLocation?.label || branchLocation?.address}</div>
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Payments Info',
			key: 'paymentsInfo',
			render: (salesOrder) => {
				const dueAmount = +salesOrder.totalDueAmount - +salesOrder.totalPaidAmount

				return (
					<div style={{ display: 'grid', gap: '3px' }}>
						<p>
							<span style={{ fontWeight: 500 }}>Sales Amount:</span> {getFormattedCurrency(+salesOrder.totalAmount)}
						</p>
						<p>
							<span style={{ fontWeight: 500 }}>Paid Amount:</span> {getFormattedCurrency(+salesOrder.totalPaidAmount)}
						</p>
						<p>
							<span style={{ fontWeight: 500 }}>Due Amount:</span> {getFormattedCurrency(dueAmount)}
						</p>
					</div>
				)
			}
		},
		{
			title: 'Order Status',
			key: 'orderStatus',
			render: (salesOrder) => {
				const { status } = salesOrder
				return convertEnumToString(
					status.toLowerCase() === SALES_ORDER_REQUESTED_STATUS ?
						orderStatuses.SALES_ORDER_PENDING_STATUS :
						status.toLowerCase() === orderStatuses.SALES_ORDER_COMPLETED_STATUS ?
							orderStatuses.SALES_ORDER_DELIVERED_STATUS :
							status.toLowerCase()
				)
			}
		},
		{
			title: 'Delivery Partner',
			key: 'preferredDeliveryPartner',
			render: (salesOrder) => {
				const preferredDeliveryPartner = salesOrder.deliveryPartner
				const consignment = salesOrder.deliveryConsignment
				const deliveryStatus = consignment ? consignment.status : salesOrder.deliveryStatus
				const deliveryId = consignment?.id || salesOrder.deliveryOrderId || salesOrder.deliveryTrackingId
				if (!preferredDeliveryPartner) {
					return
				}
				const { id, name, type } = preferredDeliveryPartner
				const { src, alt } = getDeliveryPartnerLogo(type)
				return (
					<div>
						{
							id ?
								<>
									<div className={styles.deliveryPartnerNameColumn}>
										<Image
											src={src}
											width={24}
											height={24}
											alt={alt}
										/>
										{name}
									</div>
									{
										deliveryStatus &&
										<div className={styles.deliveryStatus}>
											<span style={{ fontWeight: 500 }}>Status:&nbsp;</span>
											<TrackDeliveryStatusButton
												status={deliveryStatus}
												partnerType={type}
												trackingCode={consignment?.trackingCode}
												trackingUrl={consignment?.trackingUrl}
												phone={salesOrder?.distributor?.phone}
												disabled={[PartnerType.POST_EX, PartnerType.MP_COURIER, PartnerType.LEOPARDS].includes(type)}
											/>
										</div>
									}
									{
										deliveryId &&
										<div className={styles.deliveryStatus}>
											<span style={{ fontWeight: 500 }}>
												{
													type === PartnerType.PAKISTAN_POST ?
														'VPL:' :
														'ID:'
												}
												&nbsp;
											</span>
											{deliveryId}
										</div>
									}
								</> :
								null
						}
					</div>
				)
			}
		},
		{
			title: 'Delivery Fee',
			key: 'deliveryFee',
			render: (salesOrder) => {
				const {
					deliveryCharge
				} = salesOrder
				return (
					+deliveryCharge ? getFormattedCurrency(+deliveryCharge) : null
				)
			}
		},
		{
			title: 'On Hold Reason',
			key: 'onHoldReason',
			render: ({ onHoldReason }) => {
				const isOtherReason = !Object.values(onHoldReasons).includes(onHoldReason)
				const isLongerThan30Chars = onHoldReason?.length > 30

				return (
					onHoldReason ?
						<Tooltip
							title={onHoldReason}
							trigger={isOtherReason && isLongerThan30Chars ? undefined : [] }
						>
							{isOtherReason && isLongerThan30Chars ? `${onHoldReason.slice(0, 30)}...` : onHoldReason}
						</Tooltip> :
						null
				)
			}
		},
		{
			title: subStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS ? 'Return Reason' : 'Cancel Reason',
			key: 'flagReason',
			render: (salesOrder) => {
				const {
					flagReason
				} = salesOrder
				return (
					<Tooltip
						title={flagReason}
						placement='left'
					>
						<div className={styles.reason}>
							{flagReason}
						</div>
					</Tooltip>
				)
			}
		},
		{
			title: 'Internal Notes',
			key: 'internalNotes',
			width: 100,
			render: (salesOrder) => {
				return (
					<SalesOrderInternalNotesPopover
						id={salesOrder.id}
						notes={salesOrder.internalNotes}
						onSave={onInternalNotesUpdate}
					/>
				)
			}
		}
	]

	useEffect(() => {
		setBackPageLink(`${paths.SALES_ORDERS}?status=${status}${subStatus ? `&subStatus=${subStatus}` : ''}`)

		return () => {
			setBackPageLink()
		}
	}, [status, subStatus])

	useLayoutEffect(() => {
		setIsSubTabsContainerOverflowing(isOverflowing(subTabsContainerRef.current, { y: true }))
	}, [subTabsContainerRef])

	useEffect(() => subTabsExpanded && setSubTabsExpanded(false), [status])

	useEffect(() => {
		showDuplicateOrders && setShowDuplicateOrders(false)
	}, [status, district, division])

	useEffect(() => {
		if (!permissions.viewOrders) {
			return
		}
		if (!status) {
			return router.push(`${paths.SALES_ORDERS}?status=${companyDetails?.orderStatusFlow?.defaultStatus.toLowerCase() ?? SALES_ORDER_REQUESTED_STATUS.toLowerCase()}`)
		}
		const controller = new AbortController()
		let salesOrderStatus = allOrdersStatus
		let salesOrderSubStatus = null
		let salesOrderDeliveryStatus = null
		switch (status) {
			case SALES_ORDER_REQUESTED_STATUS:
				salesOrderStatus = SALES_ORDER_REQUESTED_STATUS
				break
			case SALES_ORDER_ON_HOLD_STATUS:
				salesOrderStatus = SALES_ORDER_ON_HOLD_STATUS
				break
			case SALES_ORDER_FLAGGED_STATUS:
				salesOrderStatus = SALES_ORDER_FLAGGED_STATUS
				salesOrderSubStatus = subStatus
				break
			case SALES_ORDER_APPROVED_STATUS:
				salesOrderStatus = SALES_ORDER_APPROVED_STATUS
				getAdministrativeLevels(controller.signal)
				break
			case SALES_ORDER_PROCESSING_STATUS:
				salesOrderStatus = SALES_ORDER_PROCESSING_STATUS
				getAdministrativeLevels(controller.signal)
				break
			case SALES_ORDER_SHIPPED_STATUS:
				salesOrderStatus = SALES_ORDER_SHIPPED_STATUS
				getAdministrativeLevels(controller.signal)
				break
			case SALES_ORDER_IN_TRANSIT_STATUS:
				salesOrderStatus = SALES_ORDER_IN_TRANSIT_STATUS
				break
			case SALES_ORDER_COMPLETED_STATUS:
				salesOrderStatus = SALES_ORDER_COMPLETED_STATUS
				salesOrderSubStatus = subStatus
				salesOrderDeliveryStatus = deliveryStatus
				break
			case SALES_ORDER_CANCELLED_STATUS:
				salesOrderStatus = SALES_ORDER_CANCELLED_STATUS
				break
		}
		setCurrentStatus(salesOrderStatus)
		setCurrentSubStatus(salesOrderSubStatus)
		if ((currentStatus !== null && currentStatus !== salesOrderStatus) || salesOrderSubStatus) {
			setIsTableDataEmpty(false)
		}

		// Make the API call using the cancel token
		getSalesOrders(
			{
				apiStatus: salesOrderStatus === allOrdersStatus ? null : salesOrderStatus,
				subStatus: salesOrderSubStatus,
				deliveryStatus: salesOrderDeliveryStatus,
				flagReason: cancellationReason,
				page: 0
			},
			controller.signal
		)

		// Cleanup function to cancel the request when the component unmounts or the effect re-runs
		return () => {
			controller?.abort?.()
		}
	},
	[
		status,
		subStatus,
		deliveryStatus,
		preferredDeliveryPartner,
		district,
		division,
		cancellationReason,
		pageSize,
		showDuplicateOrders,
		searchParams
	])

	useEffect(() => {
		if (!Object.keys(enabledOrderStatuses).length || isUpdatingStatus) {
			return
		}
		switch (status) {
			case SALES_ORDER_REQUESTED_STATUS: {
				setTableData(requestedSalesOrders)
				setTableColumns(getCommonTableColumns())
				break
			}
			case SALES_ORDER_ON_HOLD_STATUS: {
				setTableData(onHoldSalesOrders)
				setTableColumns(initialTableColumns.filter(({ key }) =>
					key !== 'deliveryDate' &&
					key !== 'orderStatus' &&
					key !== 'flagReason'
				))
				break
			}
			case SALES_ORDER_FLAGGED_STATUS: {
				setTableData(flaggedSalesOrders)
				if (subStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS) {
					setTableColumns(initialTableColumns.filter(({ key }) =>
						key !== 'deliveryDate' &&
						key !== 'orderStatus' &&
						key !== 'followUpDate' &&
						key !== 'onHoldReason'
					))
				} else {
					setTableColumns(getCommonTableColumns())
				}
				break
			}
			case SALES_ORDER_APPROVED_STATUS: {
				setTableData(approvedSalesOrders)
				setTableColumns(getCommonTableColumns())
				break
			}
			case SALES_ORDER_PROCESSING_STATUS: {
				setTableData(processingSalesOrders)
				setTableColumns(getCommonTableColumns())
				break
			}
			case SALES_ORDER_SHIPPED_STATUS: {
				setTableData(shippedSalesOrders)
				setTableColumns(getCommonTableColumns())
				break
			}
			case SALES_ORDER_IN_TRANSIT_STATUS: {
				setTableData(inTransitSalesOrders)
				setTableColumns(initialTableColumns.filter(({ key }) =>
					key !== 'deliveryDate' &&
					key !== 'orderStatus' &&
					key !== 'deliveredBy' &&
					key !== 'followUpDate' &&
					key !== 'flagReason' &&
					key !== 'onHoldReason'
				))
				break
			}
			case SALES_ORDER_COMPLETED_STATUS: {
				setTableData(completedSalesOrders)
				setTableColumns(initialTableColumns.filter(({ key }) =>
					key !== 'orderStatus' &&
					key !== 'followUpDate' &&
					key !== 'flagReason' &&
					key !== 'onHoldReason'
				))
				break
			}
			case SALES_ORDER_CANCELLED_STATUS: {
				setTableData(cancelledSalesOrders)
				setTableColumns(initialTableColumns.filter(({ key }) =>
					key !== 'deliveryDate' &&
					key !== 'orderStatus' &&
					key !== 'followUpDate' &&
					key !== 'onHoldReason'
				))
				break
			}
			case allOrdersStatus: {
				setTableData(allSalesOrders)
				setTableColumns(initialTableColumns.filter(({ key }) => key !== 'onHoldReason'))
				break
			}
		}
		if ((!enabledOrderStatuses[status] && status !== allOrdersStatus) || !permissions.viewOrders) {
			setTableData([])
		}
	}, [
		status,
		subStatus,
		allSalesOrders,
		requestedSalesOrders,
		onHoldSalesOrders,
		flaggedSalesOrders,
		approvedSalesOrders,
		processingSalesOrders,
		completedSalesOrders,
		cancelledSalesOrders,
		shippedSalesOrders,
		inTransitSalesOrders,
		salesOrderCounts,
		enabledOrderStatuses
	])

	useEffect(() => {
		if (shouldShowDuplicateOrderToggleButton) {
			if (status === orderStatuses.SALES_ORDER_REQUESTED_STATUS) {
				getDuplicateCustomerIds(orderStatuses.SALES_ORDER_REQUESTED_STATUS)
			}
			if (status === orderStatuses.SALES_ORDER_PROCESSING_STATUS) {
				getDuplicateCustomerIds(orderStatuses.SALES_ORDER_PROCESSING_STATUS)
			}
		}
		if (!shouldShowDuplicateOrderToggleButton) {
			setDuplicateCustomerIds(new Set([]))
			setDuplicateOrdersCount(0)
		}
	}, [status, district, shouldShowDuplicateOrderToggleButton])

	useEffect(() => {
		!Object.keys(currentFilterColumns || {}).length &&
		setFilterColumnsMap({ ...filterColumnsMap, [currentFilterColumnsName]: getInitialFilterColumns(true) })
	}, [tableColumns])

	useEffect(() => filterTableColumns(), [
		filterColumnsMap,
		status,
		duplicateCustomerIds,
		pageSize,
		page,
		showDuplicateOrders,
		tableData,
		searchParams,
		userProfile,
		preferences
	])

	useEffect(() => {
		const inTransitSubTabs = []
		const customDeliveryPartners = []
		for (const deliveryPartner of deliveryPartners) {
			const { id, name, type } = deliveryPartner
			if (deliveryPartner.type === PartnerType.CUSTOM) {
				customDeliveryPartners.push({
					id,
					title: name,
					query: 'preferredDeliveryPartner',
					value: id,
					type
				})
			} else {
				inTransitSubTabs.push({
					id,
					title: name,
					query: 'preferredDeliveryPartner',
					value: id,
					type
				})
			}
		}
		setInTransitSubTabs(inTransitSubTabs)
		setCustomDeliveryPartners(customDeliveryPartners)
	}, [deliveryPartners])

	useEffect(() => {
		if (shouldShowDuplicateOrderToggleButton) {
			return
		}
		setShowDuplicateOrders(false)
	}, [shouldShowDuplicateOrderToggleButton])

	useEffect(() => {
		for (const deliveryPartner of deliveryPartners) {
			if (salesOrderCounts[`${SALES_ORDER_IN_TRANSIT_STATUS}_${deliveryPartner.id}`] && deliveryPartner.type !== PartnerType.CUSTOM) {
				setShowDeliveryExport(true)
				break
			}
		}
	}, [deliveryPartners, salesOrderCounts])

	const bulkApproveOrders = async () => {
		try {
			setIsFastActionLoading(true)
			if (isFastActionLoading) {
				return
			}
			await SalesOrders.approveOrders({
				ids: selectedSalesOrderIds,
				checkInventory: preferences?.fastActionApprovedInventoryMode === inventoryCheckModes.CHECK_INVENTORY
			})
			dispatch(selectSalesOrderIds([], []))
			onPageChanged(1)
			notification.success({
				message: 'Updated Order Statuses',
				description: 'Successfully updated order statuses to Approved.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Update Order Statuses: At least one of the orders does not have sufficient inventory.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFastActionLoading(false)
		}
	}

	const bulkInTransitOrders = async () => {
		try {
			setIsFastActionLoading(true)
			if (isFastActionLoading) {
				return
			}
			const orders = selectedSalesOrders
				.filter(salesOrder => salesOrder.deliveryPartner)
				.map(salesOrder => {
					return {
						id: salesOrder.id,
						status: orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS
					}
				})
			await SalesOrders.updateOrders(orders)
			dispatch(selectSalesOrderIds([], []))
			onPageChanged(1)
			notification.success({
				message: 'Sent Orders to Courier(s)',
				description: 'Successfully updated order statuses to In-Transit.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Send Orders to Courier(s).',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFastActionLoading(false)
		}
	}

	const bulkCompleteOrders = async () => {
		try {
			setIsFastActionLoading(true)
			if (isFastActionLoading) {
				return
			}
			const orders = selectedSalesOrders
				.filter(salesOrder => salesOrder.status?.toLowerCase() === orderStatuses.SALES_ORDER_COMPLETED_STATUS && salesOrder.subStatus?.toLowerCase() === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS)
				.map(salesOrder => {
					return {
						id: salesOrder.id,
						status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
						subStatus: orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS,
						totalPaidAmount: +salesOrder.totalDueAmount - (+salesOrder.totalPaidAmount || 0)
					}
				})
			await SalesOrders.updateOrders(orders)
			dispatch(selectSalesOrderIds([], []))
			onPageChanged(1)
			notification.success({
				message: 'Updated Order Statuses',
				description: 'Successfully updated order statuses to Delivered - Payment Collected.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Update Order Statuses',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFastActionLoading(false)
		}
	}

	const getDeliveryPartners = async () => {
		try {
			const { data: { results } } = await DeliveryPartners.index({ page: -1 })
			setDeliveryPartners(results)
		} catch (e) {
			notification.error({
				message: 'Couldn\'t get delivery partners',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const getAdministrativeLevels = async (abortSignal) => {
		try {
			const { data } = await SalesOrders.administrativeLevelsBySalesOrders({
				statuses: [status || orderStatuses.SALES_ORDER_SHIPPED_STATUS],
				adminLevel: preferences.viewLocationTagsMode ?? viewLocationTagModes.DISTRICT
			}, abortSignal)
			if (data.results) {
				setShippedSubTabs(data.results.map(({ adminLevel }) => ({
					id: adminLevel,
					title: adminLevel,
					query: preferences.viewLocationTagsMode ?? viewLocationTagModes.DISTRICT,
					value: adminLevel
				})))
			}
		} catch (e) {
			if (!axios.isCancel(e)) {
				notification.error({
					message: 'Couldn\'t get district counts',
					description: e.message,
					placement: 'bottomLeft'
				})
			}
		}
	}

	const getPartiallyDeliveredAggregates = async () => {
		try {
			setIsPartiallyDeliveredDeliveryPartnersLoading(true)
			const { data } = await SalesOrders.aggregatesByPartiallyDeliveredSalesOrders()
			setPartiallyDeliveredDeliveryPartners(data)
			setPartiallyDeliveredDeliveryPartnersCount(data.reduce((acc, { count }) => acc + count, 0))
		} catch (e) {
			notification.error({
				message: 'Couldn\'t get partially delivered order counts.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsPartiallyDeliveredDeliveryPartnersLoading(false)
		}
	}

	const getSalesOrdersParams = ({ apiStatus, subStatus, deliveryStatus, flagReason, page }) => {
		return {
			...searchParams,
			status: apiStatus,
			subStatus: subStatus,
			deliveryStatus,
			page,
			preferredDeliveryPartner: searchParams.preferredDeliveryPartner !== undefined ? searchParams.preferredDeliveryPartner : preferredDeliveryPartner,
			district,
			division,
			flagReason,
			pageSize,
			showSameDistributorOrders: showDuplicateOrders || false
		}
	}

	const getSalesOrders = async ({ apiStatus, subStatus, deliveryStatus, flagReason, page }, abortSignal) => {
		try {
			setIsLoading(true)
			const { data } = await SalesOrders.index(
				getSalesOrdersParams({ apiStatus, subStatus, deliveryStatus, flagReason, page }),
				abortSignal
			)
			dispatch(updateSalesOrders(data, apiStatus))
			getSalesOrderCountsAndAmounts(apiStatus)
			if (apiStatus === SALES_ORDER_COMPLETED_STATUS) {
				getPartiallyDeliveredAggregates()
			}
			setIsLoading(false)
		} catch (e) {
			if (!axios.isCancel(e)) {
				notification.error({
					message: `Unable to get ${status || 'all'} orders`,
					description: e.message,
					placement: 'bottomLeft'
				})
				setIsLoading(false)
			}
		}
	}

	const getSalesOrderCountsAndAmounts = async (apiStatus) => {
		setIsLoadingCounts(true)
		const { data } = await SalesOrders.aggregates({
			...searchParams,
			aggregateStatus: apiStatus,
			adminLevel: preferences.viewLocationTagsMode ?? viewLocationTagModes.DISTRICT
		})
		const salesOrderCounts = {
			[allOrdersStatus]: 0
		}
		const salesAmounts = {}
		for (const count of data) {
			const status = count.status?.toLowerCase()
			const subStatus = count.subStatus?.toLowerCase()
			const preferredDeliveryPartner = count.preferredDeliveryPartner?.toLowerCase()
			const district = count.district?.toLowerCase()
			const division = count.division?.toLowerCase()
			const deliveryStatus = count.deliveryConsignment?.status?.toLowerCase() || count.deliveryStatus
			const flagReason = count.flagReason ?
				cancelledSubTabs.find(({ title }) => title === count.flagReason) ? count.flagReason.toLowerCase() :
					returnOrCancelReasons.OTHER_REASON.toLowerCase() :
				undefined
			const countIncrement = count._count.id
			const totalAmountIncrement = +count._sum.totalAmount

			salesOrderCounts[allOrdersStatus] = salesOrderCounts[allOrdersStatus] + countIncrement

			if (status) {
				salesOrderCounts[status] = salesOrderCounts[status] ? salesOrderCounts[status] + countIncrement : countIncrement
				salesAmounts[status] = salesAmounts[status] ? salesAmounts[status] + totalAmountIncrement : totalAmountIncrement
			}
			if (subStatus) {
				const key = `${status}_${subStatus}`
				salesOrderCounts[key] = salesOrderCounts[key] ? salesOrderCounts[key] + countIncrement : countIncrement
				salesAmounts[key] = salesAmounts[key] ? salesAmounts[key] + totalAmountIncrement : totalAmountIncrement
			}
			if (preferredDeliveryPartner) {
				const key = `${status}_${preferredDeliveryPartner}`
				salesOrderCounts[key] = salesOrderCounts[key] ? salesOrderCounts[key] + countIncrement : countIncrement
				salesAmounts[key] = salesAmounts[key] ? salesAmounts[key] + totalAmountIncrement : totalAmountIncrement
			}
			if (district) {
				const key = `${status}_${district}`
				salesOrderCounts[key] = salesOrderCounts[key] ? salesOrderCounts[key] + countIncrement : countIncrement
				salesAmounts[key] = salesAmounts[key] ? salesAmounts[key] + totalAmountIncrement : totalAmountIncrement
			}
			if (division) {
				const key = `${status}_${division}`
				salesOrderCounts[key] = salesOrderCounts[key] ? salesOrderCounts[key] + countIncrement : countIncrement
				salesAmounts[key] = salesAmounts[key] ? salesAmounts[key] + totalAmountIncrement : totalAmountIncrement
			}
			if (flagReason) {
				const key = `${status}_${flagReason}`
				salesOrderCounts[key] = salesOrderCounts[key] ? salesOrderCounts[key] + countIncrement : countIncrement
				salesAmounts[key] = salesAmounts[key] ? salesAmounts[key] + totalAmountIncrement : totalAmountIncrement
			}
			if (deliveryStatus) {
				const key = `${status}_${deliveryStatus}`
				salesOrderCounts[key] = salesOrderCounts[key] ? salesOrderCounts[key] + countIncrement : countIncrement
				salesAmounts[key] = salesAmounts[key] ? salesAmounts[key] + totalAmountIncrement : totalAmountIncrement
			}
		}
		dispatch(updateSalesOrderCounts(salesOrderCounts))
		setSalesAmounts(salesAmounts)
		setIsLoadingCounts(false)
	}

	const getDuplicateCustomerIds = async (status, abortSignal) => {
		try {
			setIsLoading(true)
			const { data } = await SalesOrders.duplicateDistributorIds(getSalesOrdersParams({ apiStatus: status }), abortSignal)
			setDuplicateCustomerIds(new Set(data.distributorIds))
			setDuplicateOrdersCount(data.count)
			setIsLoading(false)
		} catch (e) {
			if (!axios.isCancel(e)) {
				notification.error({
					message: 'Unable to get duplicate orders.',
					description: e.message,
					placement: 'bottomLeft'
				})
			} else {
				return
			}
		}
		setIsLoading(false)
	}

	const onPageChanged = async (page) => {
		let pageNumber = page
		const totalPage = Math.ceil(salesOrderCounts[currentStatus] / pageSize)
		if (page > totalPage && tableData.length === selectedSalesOrderIds.length) {
			pageNumber = page - 2
		} else if (page === totalPage - 1 && tableData.length === selectedSalesOrderIds.length) {
			pageNumber = page - 1
		} else if (tableData.length === 0 && tableData.length === selectedSalesOrderIds.length) {
			pageNumber = page - 2
		} else {
			pageNumber = page - 1
		}
		await getSalesOrders({
			apiStatus: statusRef.current === allOrdersStatus ? null : statusRef.current,
			subStatus: subStatusRef.current,
			page: pageNumber,
			flagReason: cancellationReason,
			pageSize,
			deliveryStatus
		})
	}

	const onUploadOrderComplete = async () => {
		getSalesOrders({
			apiStatus: currentStatus === allOrdersStatus ? null : currentStatus,
			subStatus: currentSubStatus,
			page: page,
			flagReason: cancellationReason,
			count: pageSize
		})
		getAdministrativeLevels()
	}

	const onInvoicePrinted = async () => {
		setSalesOrderIdToPrint(null)
		getSalesOrders({
			apiStatus: currentStatus === allOrdersStatus ? null : currentStatus,
			subStatus: currentSubStatus,
			page: page,
			flagReason: cancellationReason,
			count: pageSize
		})
	}

	const onInvoicesPrinted = async () => {
		setIsPrintSalesOrderInvoicesModalVisible(false)
		getSalesOrders({
			apiStatus: currentStatus === allOrdersStatus ? null : currentStatus,
			subStatus: currentSubStatus,
			page: page,
			flagReason: cancellationReason,
			count: pageSize
		})
	}

	const getCommonTableColumns = () =>
		initialTableColumns.filter(({ key }) =>
			key !== 'deliveryDate' &&
			key !== 'orderStatus' &&
			key !== 'followUpDate' &&
			key !== 'flagReason' &&
			key !== 'onHoldReason'
		)

	const filterTableColumns = () =>
		setFilteredTableColumns(initialTableColumns.filter(({ key }) => currentFilterColumns?.[key]))

	const getInitialFilterColumns = (isChecked) => tableColumns.reduce((acc, { key }) => ({ ...acc, [key]: isChecked }), {})

	const onExportCSV = async (isDownloadingSummary = false) => {
		if (selectedSalesOrderIds && selectedSalesOrderIds.length) {
			setIsLoading(true)
			const columns = Object.keys(currentFilterColumns).filter((key) => currentFilterColumns[key]).join(',')
			await SalesOrders.downloadCSV({
				ids: selectedSalesOrderIds,
				columns,
				industry: companyDetails?.industry,
				isDownloadingSummary
			})
			setIsLoading(false)
		} else {
			setExportType(EXPORT_TYPE.CSV)
			setIsSalesOrdersExportModalVisible(true)
		}
	}

	const onExportPDF = async () => {
		if (selectedSalesOrderIds && selectedSalesOrderIds.length) {
			setIsLoading(true)
			const columns = Object.keys(currentFilterColumns).filter((key) => currentFilterColumns[key]).join(',')
			await Pdf.downloadSalesOrders({
				ids: selectedSalesOrderIds,
				columns,
				industry: companyDetails?.industry
			})
			setIsLoading(false)
		} else {
			setExportType(EXPORT_TYPE.PDF)
			setIsSalesOrdersExportModalVisible(true)
		}
	}

	const onUpdateOrderStatus = async (previousStatus, status, updatedSalesOrderIds = selectedSalesOrderIds) => {
		setIsUpdatingStatus(true)
		setIsUpdateStatusModalVisible(false)
		await getSalesOrderCountsAndAmounts(status)
		setTableData(prevData => {
			const updatedTableData = prevData.filter(({ id }) => !updatedSalesOrderIds.includes(id))
			if (updatedTableData.length === 0) {
				setIsTableDataEmpty(true)
			}
			return updatedTableData
		})
		dispatch(selectSalesOrderIds([], []))
		setIsUpdatingStatus(false)
		if (previousStatus === orderStatuses.SALES_ORDER_REQUESTED_STATUS) {
			getDuplicateCustomerIds(orderStatuses.SALES_ORDER_REQUESTED_STATUS)
		}
		if (previousStatus === orderStatuses.SALES_ORDER_PROCESSING_STATUS) {
			getDuplicateCustomerIds(orderStatuses.SALES_ORDER_PROCESSING_STATUS)
		}
		if (
			previousStatus !== orderStatuses.SALES_ORDER_SHIPPED_STATUS &&
			status !== orderStatuses.SALES_ORDER_SHIPPED_STATUS
		) {
			return
		}
		getAdministrativeLevels()
	}

	const isAllColumnChecked = (filteredColumns = {}) => Object.values(filteredColumns).every((value) => value === true)

	const onPrintOrderInvoices = () => setIsPrintSalesOrderInvoicesModalVisible(true)

	const onPrintPostalOrderForm = () => setIsPrintSalesOrderPostalFormModalVisible(true)

	const onPrintPostalOrderSlip = () => setIsPrintSalesOrderPakistanPostSlipModalVisible(true)

	const renderDateByStatus = (status, salesOrder) => {
		const {
			onHoldAt,
			approvedAt,
			processedAt,
			shippedAt,
			inTransitAt,
			deliveredAt,
			cancelledAt,
			flaggedAt
		} = salesOrder
		switch (status) {
			case SALES_ORDER_ON_HOLD_STATUS:
				return renderDate('On Hold', onHoldAt)
			case SALES_ORDER_APPROVED_STATUS:
				return renderDate('Approved', approvedAt)
			case SALES_ORDER_PROCESSING_STATUS:
				return renderDate('Processing', processedAt)
			case SALES_ORDER_SHIPPED_STATUS:
				return renderDate('Shipped', shippedAt)
			case SALES_ORDER_IN_TRANSIT_STATUS:
				return renderDate('In-Transit', inTransitAt)
			case SALES_ORDER_CANCELLED_STATUS:
				return renderDate('Cancelled', cancelledAt)
			case SALES_ORDER_COMPLETED_STATUS:
				return renderDate('Delivered', deliveredAt)
			case SALES_ORDER_FLAGGED_STATUS:
				return renderDate('Flagged', flaggedAt)
		}
	}

	const renderDate = (title, date, highlighted) => {
		return (
			date &&
			<div className={`${styles.dateBadge} ${highlighted ? styles.highlighted : ''}`}>
				<span>{title}</span>
				{preferences.dateTimeDisplayFormat === dateTimeDisplayFormats.DATE ? moment(date).format('MMM D, YYYY') : moment(date).format('MMM D, YYYY hh:mm A')}
			</div>
		)
	}

	const renderActionMenu = () => {
		const isFastActionApproveDisabled = !selectedSalesOrderIds.length || !permissions.editSalesOrdersStatusRequestedToApproved
		let isFastActionInTransitDisabled = false
		switch (status) {
			case orderStatuses.SALES_ORDER_APPROVED_STATUS:
				isFastActionInTransitDisabled = !selectedSalesOrderIds.length || !permissions.editSalesOrdersStatusApprovedToInTransit
				break
			case orderStatuses.SALES_ORDER_PROCESSING_STATUS:
				isFastActionInTransitDisabled = !selectedSalesOrderIds.length || !permissions.editSalesOrdersStatusProcessingToInTransit
				break
			case orderStatuses.SALES_ORDER_SHIPPED_STATUS:
				isFastActionInTransitDisabled = !selectedSalesOrderIds.length || !permissions.editSalesOrdersStatusShippedToInTransit
				break
		}
		const isFastActionDeliveredPaymentCollectedDisabled = !selectedSalesOrderIds.length || !permissions.editSalesOrdersStatusCompletedPaymentDueToCompletedPaymentCollected
		return (
			<Menu className='order-dropdown' selectable={false}>
				{
					status === orderStatuses.SALES_ORDER_SHIPPED_STATUS &&
					<Menu.Item
						icon={<SwapOutlined />}
						onClick={() => {
							setIsActionDropdownOpen(false)
							setIsUpdateOrdersDeliverPartnerModalVisible(true)
						}}
						key='update-delivery-partner'
						disabled={!permissions.editOrders || !selectedSalesOrderIds.length}
					>
						Update Delivery Partner
					</Menu.Item>
				}
				<Menu.Item
					icon={<SwapOutlined />}
					onClick={() => {
						setIsActionDropdownOpen(false)
						if (isInvoiceScanEnabled && !selectedSalesOrderIds.length) {
							setIsScanSalesOrdersModalVisible(true)
						} else if (selectedSalesOrderIds.length) {
							onUpdateOrderStatuses()
						}
					}}
					key='change-status'
					disabled={!permissions.editOrdersStatus ||
						!status ||
						(status === orderStatuses.SALES_ORDER_FLAGGED_STATUS && subStatus === undefined) ||
						status === allOrdersStatus ||
						(!isInvoiceScanEnabled && !selectedSalesOrderIds.length)}
				>
					Change Status
				</Menu.Item>
				{
					companyDetails?.documentTemplates === undefined || companyDetails?.documentTemplates?.isInvoiceEnabled === true &&
					<Menu.Item
						icon={<PrinterOutlined />}
						onClick={() => {
							setIsActionDropdownOpen(false)
							onPrintOrderInvoices()
						}}
						key='print-invoice'
					>
						Print Invoice
					</Menu.Item>
				}
				{
					isPrintSalesOrderPostalFormEnabled &&
					<Menu.Item
						icon={<PrinterOutlined />}
						onClick={() => {
							setIsActionDropdownOpen(false)
							onPrintPostalOrderForm()
						}}
						key='print-postal-order-form'
					>
					Print Postal Order Form
					</Menu.Item>
				}
				{
					isPrintSalesOrderPostalFormEnabled &&
					<Menu.Item
						icon={<PrinterOutlined />}
						onClick={() => {
							setIsActionDropdownOpen(false)
							onPrintPostalOrderSlip()
						}}
						key='print-postal-order-slip'
					>
					Print Pakistan Post Order Slip
					</Menu.Item>
				}
				<Menu.Item
					icon={<ExportOutlined />}
					disabled={!permissions.exportOrders}
					onClick={() => {
						setIsActionDropdownOpen(false)
						onExportCSV()
					}}
					key='export-as-csv'
				>
					Export As CSV
				</Menu.Item>
				<Menu.Item
					icon={<ExportOutlined />}
					disabled={!permissions.exportOrders}
					onClick={() => {
						setIsActionDropdownOpen(false)
						setExportType(EXPORT_TYPE.CSV)
						setIsSalesOrdersExportSummaryModalVisible(true)
					}}
					key='export-summary'
				>
					Export Summary
				</Menu.Item>
				<Menu.Item
					icon={<ExportOutlined />}
					disabled={!permissions.exportOrders}
					onClick={() => {
						setIsActionDropdownOpen(false)
						onExportPDF()
					}}
					key='export-as-pdf'
				>
					Export As PDF
				</Menu.Item>
				<Menu.Item
					icon={<UploadOutlined />}
					onClick={() => {
						setIsActionDropdownOpen(false)
						onUploadOrder()
					}}
					key='upload-orders'
					disabled={!permissions.uploadOrders || !isUploadEnabled}
				>
					Upload Orders
				</Menu.Item>
				{
					status !== orderStatuses.SALES_ORDER_SHIPPED_STATUS &&
					status !== orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS &&
					status !== orderStatuses.SALES_ORDER_COMPLETED_STATUS &&
					status !== orderStatuses.SALES_ORDER_FLAGGED_STATUS &&
					status !== orderStatuses.SALES_ORDER_CANCELLED_STATUS ?
						<Menu.Item
							icon={<CalendarOutlined />}
							onClick={() => {
								setIsActionDropdownOpen(false)
								setIsUpdateShippingDateModalVisible(true)
							}}
							key='update-shipping-date'
							disabled={!permissions.editOrders || !selectedSalesOrderIds.length}
						>
							Update Shipping Date
						</Menu.Item> : null
				}
				{
					showDeliveryExport &&
					status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS &&
						<Menu.Item
							icon={<ExportOutlined />}
							onClick={() => {
								setIsActionDropdownOpen(false)
								setIsExportDeliveryOrdersModalVisible(true)
							}}
							key='export-delivery'
							disabled={false}
						>
							Export Delivery
						</Menu.Item>
				}
				{
					showFastAction ?
						<div>
							<div className={styles.divider} />
							<Menu.Item
								icon={<img src='/img/double-arrow.svg' />}
								key='fast-action'
								className='disabled-menu'
							>
							Fast Action
							</Menu.Item>
							{
								showApproveAction &&
								<Button
									loading={isFastActionLoading}
									onClick={bulkApproveOrders}
									style={{ width: '100%', borderColor: '#288ea5' }}
									icon={
										<img
											src='/img/checkmark-circle.svg'
											style={{
												marginRight: 8,
												filter: isFastActionApproveDisabled ? 'brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(7488%) hue-rotate(102deg) brightness(104%) contrast(107%)' : undefined
											}}
										/>
									}
									disabled={isFastActionApproveDisabled}
								>
									Approve Order(s)
								</Button>
							}
							{
								showInTransitAction &&
								<Button
									loading={isFastActionLoading}
									onClick={bulkInTransitOrders}
									style={{ width: '100%', borderColor: '#288ea5' }}
									icon={
										<img
											src='/img/car.svg'
											style={{
												marginRight: 8,
												filter: isFastActionInTransitDisabled || noSelectedDeliveryPartners ? 'brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(7488%) hue-rotate(102deg) brightness(104%) contrast(107%)' : undefined
											}}
										/>
									}
									disabled={isFastActionInTransitDisabled || noSelectedDeliveryPartners}
								>
									{
										selectedDeliveryPartner ?
											`Send to ${partnerTypeNames[selectedDeliveryPartner.type] || convertEnumToString(selectedDeliveryPartner.type.toLowerCase())}` :
											'Send to Respective Courier(s)'
									}
								</Button>
							}
							{
								showDeliveredPaymentCollectedAction &&
								<Button
									loading={isFastActionLoading}
									onClick={bulkCompleteOrders}
									style={{ width: '100%', borderColor: '#288ea5' }}
									icon={
										<img
											src='/img/checkmark-circle.svg'
											style={{
												marginRight: 8,
												filter: isFastActionDeliveredPaymentCollectedDisabled ? 'brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(7488%) hue-rotate(102deg) brightness(104%) contrast(107%)' : undefined
											}}
										/>
									}
									disabled={isFastActionDeliveredPaymentCollectedDisabled}
								>
									Update All Payment Due
								</Button>
							}
						</div> : null
				}
			</Menu>
		)
	}

	const renderSubTabs = (subTabs) => {
		return (
			<div className={styles.subTabs}>
				<div
					style={{ justifyContent: isSubTabsContainerOverflowing || subTabsExpanded ? 'center' : 'initial' }}
					className={`${styles.subTabsContainer} ${subTabsExpanded && styles.subTabsContainerExpanded}`}
					ref={subTabsContainerRef}
				>
					{
						subTabs.map(({ title, query, value, type: deliveryPartnerType }) => {
							const count = salesOrderCounts[`${currentStatus}_${value?.toLowerCase()}`] || 0
							const salesAmount = getFormattedCurrency(salesAmounts[`${currentStatus}_${value?.toLowerCase()}`] || 0)
							const { src, alt } = getDeliveryPartnerLogo(deliveryPartnerType)
							const isTabSelected = (subStatus || district || division || preferredDeliveryPartner || cancellationReason || deliveryStatus) === value
							if (query === 'preferredDeliveryPartner' && count === 0) {
								return
							}
							const onClickSubTab = () => {
								dispatch(selectSalesOrderIds([], []))
								if (isTabSelected) {
									router.push(`?status=${status}`)
								} else {
									router.push(`?status=${status}&${query}=${value}`)
								}
							}

							return (
								permissions.viewOrdersAmountTooltip ?
									<Tooltip key={value} title={salesAmount}>
										<div>
											<SubTab
												className={status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ? styles.inTransitSubTabButton : null}
												key={value}
												active={isTabSelected}
												count={permissions.viewOrdersCount ? count : undefined}
												loading={isLoadingCounts}
												onClick={onClickSubTab}
											>
												{
													status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ?
														<div className={styles.inTransitSubTabButtonContent}>
															<Image
																src={src}
																width={20}
																height={20}
																alt={alt}
															/>
															{title}
														</div> :
														title
												}
											</SubTab>
										</div>
									</Tooltip> :
									<div key={value}>
										<SubTab
											className={status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ? styles.inTransitSubTabButton : null}
											key={value}
											active={isTabSelected}
											count={count}
											loading={isLoadingCounts}
											onClick={onClickSubTab}
										>
											{
												status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ?
													<div className={styles.inTransitSubTabButtonContent}>
														<Image
															src={src}
															width={20}
															height={20}
															alt={alt}
														/>
														{title}
													</div> :
													title
											}
										</SubTab>
									</div>
							)
						})
					}
				</div>
				<button
					className={`${styles.subTabsToggleButton} ${(isSubTabsContainerOverflowing || subTabsExpanded) && styles.subTabsToggleButtonVisible}`}
					onClick={() => setSubTabsExpanded(!subTabsExpanded)}
				>
					<img
						style={{ rotate: subTabsExpanded ? '180deg' : 'initial' }}
						src='/img/chevron-down-teal.svg'
						alt='Chevron down icon'
					/>
				</button>
			</div>
		)
	}

	const renderDeliveryPartners = () => {
		return (
			<div className={styles.subTabs} style={{ border: 'none' }}>
				<Dropdown
					visible={isDeliveryPartnersDropdownVisible}
					onVisibleChange={setIsDeliveryPartnersDropdownVisible}
					overlay={() => {
						return (
							<Menu className={styles.smsPeriodSelectMenu}>
								{
									partiallyDeliveredDeliveryPartners.map((deliveryPartner) => {
										const { src, alt } = getDeliveryPartnerLogo(deliveryPartner.type)
										const isSelected = deliveryStatus === allPartiallyDeliveredStatus && preferredDeliveryPartner === deliveryPartner.id
										return (
											<Menu.Item
												className={isSelected ? styles.selectedDeliveryPartner : styles.deliveryPartner}
												key={deliveryPartner.id}
												onClick={() => {
													dispatch(selectSalesOrderIds([], []))
													if (isSelected) {
														router.push(`?status=${status}`)
													} else {
														router.push(`?status=${status}&deliveryStatus=${allPartiallyDeliveredStatus}&preferredDeliveryPartner=${deliveryPartner.id}`)
													}
												}}
											>
												<div
													style={{ display: 'flex', alignItems: 'center', gap: 8 }}
												>
													<img
														style={{ width: 20, height: 20 }}
														src={src}
														alt={alt}
													/>
													<div>
														{deliveryPartner.name}
													</div>
													<span className={isSelected ? styles.countActive : styles.count}>
														{deliveryPartner.count}
													</span>
												</div>
											</Menu.Item>
										)
									})
								}
							</Menu>
						)
					}}
				>
					<SubTab
						count={partiallyDeliveredDeliveryPartnersCount}
						loading={isPartiallyDeliveredDeliveryPartnersLoading}
						onClick={() => {
							dispatch(selectSalesOrderIds([], []))
							if (deliveryStatus === allPartiallyDeliveredStatus) {
								router.push(`?status=${status}`)
							} else {
								router.push(`?status=${status}&deliveryStatus=${allPartiallyDeliveredStatus}`)
							}
						}}
						active={deliveryStatus === allPartiallyDeliveredStatus}
						hasChevron={true}
						onChevronClicked={(e) => {
							e.stopPropagation()
							setIsDeliveryPartnersDropdownVisible(true)
						}}
					>
						<div style={{ color: 'black' }}>Partial Delivered</div>
					</SubTab>
				</Dropdown>
			</div>
		)
	}

	const renderCustomDeliveryPartners = () => {
		return (
			<div className={styles.subTabs} style={{ border: 'none' }}>
				<Dropdown
					trigger={['click']}
					overlay={() => {
						return (
							<Menu className={styles.smsPeriodSelectMenu}>
								{
									customDeliveryPartners.map((deliveryPartner) => {
										const { query, value } = deliveryPartner
										const count = salesOrderCounts[`${currentStatus}_${value?.toLowerCase()}`] || 0
										const isSelected = preferredDeliveryPartner === value
										if (query === 'preferredDeliveryPartner' && count === 0) {
											return
										}
										const onClickSubTab = () => {
											dispatch(selectSalesOrderIds([], []))
											if (isSelected) {
												router.push(`?status=${status}`)
											} else {
												router.push(`?status=${status}&${query}=${value}`)
											}
										}
										return (
											<Menu.Item
												className={isSelected ? styles.selectedDeliveryPartner : styles.deliveryPartner}
												key={deliveryPartner.id}
												onClick={onClickSubTab}
											>
												<div
													style={{ display: 'flex', alignItems: 'center', gap: 8 }}
												>
													<img
														style={{ width: 20, height: 20 }}
														src={'/img/delivery-partner.svg'}
													/>
													<div>
														{deliveryPartner.title}
													</div>
													<span className={isSelected ? styles.countActive : styles.count}>
														{count}
													</span>
												</div>
											</Menu.Item>
										)
									})
								}
							</Menu>
						)
					}}
				>
					<SubTab
						count={customDeliveryPartners.reduce((acc, { value }) => acc + (salesOrderCounts[`${currentStatus}_${value?.toLowerCase()}`] || 0), 0)}
						loading={isLoadingCounts}
						hasChevron={true}
						active={preferredDeliveryPartner !== undefined && customDeliveryPartners.some(({ value }) => value === preferredDeliveryPartner)}
					>
						<div style={{ color: 'black' }}>Custom</div>
					</SubTab>
				</Dropdown>
			</div>
		)
	}

	const onCountChange = (value) => {
		setPageSize(value)
	}

	const renderSelectedItems = () => {
		return selectedSalesOrderIds.length > 0 || shouldShowDuplicateOrderToggleButton ?
			<div className={styles.rowSelection} style={{ padding: '8px 16px' }}>
				<div className={styles.duplicateOrderIndicator}>
					{
						shouldShowDuplicateOrderToggleButton ?
							<Button
								className={`${styles.duplicateOrdersButton} ${showDuplicateOrders && styles.duplicateOrdersButtonActive}`}
								onClick={() => setShowDuplicateOrders(!showDuplicateOrders)}
							>
								<span className={styles.yellowRectangle} />
								<p className={styles.duplicateOrderIndicatorText}>
									Multiple orders{' '}
									<span className={styles.duplicateOrderCount}>
										{duplicateOrdersCount}
									</span>{' '}
									with the same phone number{' '}
									<span className={styles.duplicatePhoneCount}>
										{duplicateCustomerIds.size}
									</span>
								</p>
							</Button> : null
					}
				</div>
				{
					selectedSalesOrderIds.length > 0 &&
					<p className={styles.rowSelectionText}>
						You have selected {selectedSalesOrderIds.length} order(s).
						<span onClick={() => dispatch(selectSalesOrderIds([]))}> Clear all selections</span>
					</p>
				}
			</div> : null
	}

	const modifiedSalesOrderCounts = useMemo(() => {
		const modifiedSalesOrderCounts = { ...salesOrderCounts }
		modifiedSalesOrderCounts[allOrdersStatus] = 0
		modifiedSalesOrderCounts['flagged'] = 0
		for (const status in enabledOrderStatuses) {
			if (enabledOrderStatuses[status]) {
				if (status !== 'flagged') {
					modifiedSalesOrderCounts[allOrdersStatus] += modifiedSalesOrderCounts[status] || 0
				}
				if (status === 'returned') {
					modifiedSalesOrderCounts['flagged'] += modifiedSalesOrderCounts['flagged_returned'] || 0
				}
				if (status === 'damaged') {
					modifiedSalesOrderCounts['flagged'] += modifiedSalesOrderCounts['flagged_damaged'] || 0
				}
			}
		}
		if (enabledOrderStatuses['flagged']) {
			modifiedSalesOrderCounts[allOrdersStatus] += modifiedSalesOrderCounts['flagged']
		}
		return modifiedSalesOrderCounts
	}, [enabledOrderStatuses, salesOrderCounts])

	const modifiedECommerceOrderTabs = useMemo(() => {
		const tabs = [...eCommerceOrderTabs]
		return tabs.filter(tab => enabledOrderStatuses[tab.status] || tab.status === allOrdersStatus)
	}, [enabledOrderStatuses])

	const subStatusTabs = useMemo(() => {
		let tabsToRender = null

		switch (status) {
			case SALES_ORDER_FLAGGED_STATUS:
				tabsToRender = flaggedSubTabs.filter(tab => enabledOrderStatuses[tab.value])
				break
			case SALES_ORDER_APPROVED_STATUS:
			case SALES_ORDER_SHIPPED_STATUS:
			case SALES_ORDER_PROCESSING_STATUS: {
				if (preferences.viewLocationTagsMode !== viewLocationTagModes.NONE) {
					if (status === SALES_ORDER_APPROVED_STATUS && preferences.viewLocationTagsApprovedEnabled) {
						tabsToRender = shippedSubTabs
					} else if (status === SALES_ORDER_SHIPPED_STATUS && preferences.viewLocationTagsShippedEnabled) {
						tabsToRender = shippedSubTabs
					} else if (status === SALES_ORDER_PROCESSING_STATUS && preferences.viewLocationTagsProcessingEnabled) {
						tabsToRender = shippedSubTabs
					}
				}
				break
			}
			case SALES_ORDER_IN_TRANSIT_STATUS: {
				if (preferences.viewDeliveryPartnerTags) {
					tabsToRender = inTransitSubTabs
				}
				break
			}
			case SALES_ORDER_COMPLETED_STATUS:
				tabsToRender = completedSubTabs
				break
			case SALES_ORDER_CANCELLED_STATUS:
				if (preferences.viewCancelReasonTags) {
					tabsToRender = cancelledSubTabs
				}
		}
		return tabsToRender
	}, [
		status,
		inTransitSubTabs,
		shippedSubTabs,
		enabledOrderStatuses,
		preferences
	])

	return (
		<div className={`${styles.eCommerceSalesOrderPage} e-commerce-sales-order-page`}>
			<div className={styles.container}>
				<div className={styles.titleContainer}>
					<h1 className={styles.pageTitle}>Orders</h1>
					<div className={styles.topButtons}>
						<PresenceIndicator
							pageName='orders'
						/>
						{
							permissions.viewOrderPreference &&
							<Button
								icon={
									<img
										style={{ width: 24, height: 24, marginTop: -2 }}
										src='/img/preference-icon.svg'
									/>
								}
								onClick={() => setIsPreferenceModalVisible(true)}
							/>
						}
						<div className={styles.dropdownContainer}>
							<Button
								onClick={(e) => {
									e.stopPropagation()
									setIsActionDropdownOpen((prev) => !prev)
								}}
							>
								Action
							</Button>
							<div ref={dropdownRef} className={`${styles.dropdown} ${isActionDropdownOpen ? styles.show : ''}`}>
								{renderActionMenu()}
							</div>
						</div>
						<Button
							type='primary'
							onClick={() => router.push('/create-sales-order')}
							disabled={!permissions.addOrders}
						>
							Create Order
						</Button>
					</div>
				</div>
				<div className={styles.tabs}>
					<Tabs type='card'>
						{
							modifiedECommerceOrderTabs?.map((tab) => {
								return (
									<Tabs.TabPane
										key={tab.path}
										tab={
											permissions.viewOrdersAmountTooltip ?
												<Tooltip
													key={tab.path}
													title={getFormattedCurrency(salesAmounts[tab.status] || 0)}
													trigger={tab.status === allOrdersStatus ? [] : undefined}
												>
													<div>
														<HeaderTab
															title={tab.title}
															count={permissions.viewOrdersCount ? modifiedSalesOrderCounts[tab.status] || 0 : undefined}
															isSelected={() => tab.status === status}
															onClick={() => {
																dispatch(selectSalesOrderIds([], []))
																router.push(tab.path)
															}}
															loading={isLoadingCounts}
														/>
													</div>
												</Tooltip> :
												<div
													key={tab.path}
												>
													<HeaderTab
														title={tab.title}
														count={permissions.viewOrdersCount ? modifiedSalesOrderCounts[tab.status] || 0 : undefined}
														isSelected={() => tab.status === status}
														onClick={() => {
															dispatch(selectSalesOrderIds([], []))
															router.push(tab.path)
														}}
														loading={isLoadingCounts}
													/>
												</div>
										}
									/>
								)
							})
						}
					</Tabs>
				</div>
				{
					status === orderStatuses.SALES_ORDER_COMPLETED_STATUS ?
						<div style={{ display:'flex', justifyContent:'space-between' }}>
							{subStatusTabs && renderSubTabs(subStatusTabs)}
							{renderDeliveryPartners()}
						</div> :
						status === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS ?
							<div style={{ display:'flex', justifyContent:'space-between' }}>
								{subStatusTabs && renderSubTabs(subStatusTabs)}
								{customDeliveryPartners.length > 0 && preferences.viewDeliveryPartnerTags && renderCustomDeliveryPartners()}
							</div> :
							<>
								{subStatusTabs && renderSubTabs(subStatusTabs)}
							</>
				}
				{renderSelectedItems()}
				<CustomTable
					refresh={() => onPageChanged(page + 1)}
					loading={isLoading}
					columns={filteredTableColumns}
					dataSource={tableData}
					isDataSourceEmpty={isTableDataEmpty}
					pagination={{
						pageSize: pageSize,
						total: totalCount,
						position: ['topRight'],
						onChange: onPageChanged,
						showSizeChanger: false,
						current: page + 1
					}}
					rowKey='id'
					rowClassName={salesOrder => {
						const id = salesOrder.distributor?.id
						return (showDuplicateOrders || duplicateCustomerIds.has(id)) && 'duplicate-order-row'
					}}
					rowSelection={
						filteredTableColumns.length ? {
							preserveSelectedRowKeys: true,
							selectedRowKeys: selectedSalesOrderIds,
							onChange: (selectedRowKeys, selectedRows) => {
								if (selectedRowKeys.length > 0) {
									setIsActionDropdownOpen(true)
								} else {
									setIsActionDropdownOpen(false)
								}
								dispatch(selectSalesOrderIds(selectedRowKeys, selectedRows))
							}
						} : null
					}
					scroll={{ y: `calc(100vh - ${subStatusTabs ? '410px' : '340px'} - 53px - ${subStatusTabs && selectedSalesOrderIds.length > 0 ? '51px' : '0px' })` }}
					onPageSizeChange={(size) => onCountChange(size)}
					empty={isSearchApplied ? <CustomEmptySecondary showFallbackAction /> : null}
					headerChildren={
						<div className={styles.tableButtons}>
							{
								status === orderStatuses.SALES_ORDER_PROCESSING_STATUS ||
								status === orderStatuses.SALES_ORDER_REQUESTED_STATUS ||
								status === orderStatuses.SALES_ORDER_APPROVED_STATUS ||
								status === orderStatuses.SALES_ORDER_SHIPPED_STATUS ?
									<Button
										type='primary'
										onClick={() => {
											setIsPickingModalVisible(true)
										}}
									>
										Picking
									</Button> : null
							}
							{
								selectedSalesOrderIds.length > 0 ?
									<Button
										type='primary'
										onClick={() => onExportCSV(true)}
										disabled={!permissions.exportOrders}
										icon={<DownloadOutlined />}
									>
										Export Summary
									</Button>
									: null
							}
						</div>
					}
					filter={true}
					filterTrigger='click'
					filterPlacement='bottomLeft'
					filterVisible={isFilterControlVisible}
					onFilterVisibleChange={setIsFilterControlVisible}
					filterContent={
						<div className={styles.filterOptions}>
							<h4>Columns</h4>
							<div>
								<Checkbox
									checked={isAllColumnChecked(currentFilterColumns)}
									value='isAllChecked'
									onChange={(e) => {
										if (e.target.checked) {
											return setFilterColumnsMap({ ...filterColumnsMap, [currentFilterColumnsName]: getInitialFilterColumns(true) })
										}
										setFilterColumnsMap({ ...filterColumnsMap, [currentFilterColumnsName]: getInitialFilterColumns(false) })
									}}
								>
									Select All
								</Checkbox>
							</div>
							{
								tableColumns.map(column => {
									return (
										<div key={column.key}>
											<Checkbox
												checked={currentFilterColumns?.[column.key]}
												value={column.key}
												onChange={(e) => setFilterColumnsMap({
													...filterColumnsMap, [currentFilterColumnsName]: { ...currentFilterColumns, [column.key]: e.target.checked }
												})}
											>
												{column.title}
											</Checkbox>
										</div>
									)
								})
							}
						</div>
					}
				/>
			</div>
			{
				isPrintSalesOrderInvoicesModalVisible &&
					<PrintSalesOrderInvoicesModal
						visible={isPrintSalesOrderInvoicesModalVisible}
						salesOrderIds={selectedSalesOrderIds}
						onCancel={onInvoicesPrinted}
					/>
			}
			{
				isPrintSalesOrderPostalFormModalVisible &&
					<PrintSalesOrderPostalOrderFormsModal
						visible={isPrintSalesOrderPostalFormModalVisible}
						salesOrderIds={selectedSalesOrderIds}
						onCancel={() => setIsPrintSalesOrderPostalFormModalVisible(false)}
					/>
			}
			{
				isPrintSalesOrderPakistanPostSlipModalVisible &&
				<PrintSalesOrderPakistanPostSlipsModal
					visible={isPrintSalesOrderPakistanPostSlipModalVisible}
					salesOrderIds={selectedSalesOrderIds}
					onCancel={() => setIsPrintSalesOrderPakistanPostSlipModalVisible(false)}
				/>
			}
			{
				isSalesOrdersExportModalVisible ?
					<SalesOrdersExportModal
						filteredColumns={currentFilterColumns}
						exportType={exportType}
						onCancel={() => setIsSalesOrdersExportModalVisible(false)}
						visible={isSalesOrdersExportModalVisible}
					/> : null
			}
			{
				isSalesOrdersExportSummaryModalVisible ?
					<SalesOrdersExportModal
						filteredColumns={currentFilterColumns}
						exportType={exportType}
						onCancel={() => setIsSalesOrdersExportSummaryModalVisible(false)}
						visible={isSalesOrdersExportSummaryModalVisible}
						isDownloadingSummary={true}
					/> : null
			}
			{
				isUploadOrdersModalVisible &&
					<UploadOrdersModal
						visible={isUploadOrdersModalVisible}
						onCancel={() => setIsUploadOrdersModalVisible(false)}
						onComplete={onUploadOrderComplete}
					/>
			}
			{
				isPrintSalesOrderChallansModalVisible &&
					<PrintSalesOrderChallansModal
						visible={isPrintSalesOrderChallansModalVisible}
						salesOrderIds={selectedSalesOrderIds}
						onCancel={() => setIsPrintSalesOrderChallansModalVisible(false)}
					/>
			}
			{
				salesOrderIdToPrint &&
				<PrintSalesOrderInvoicesModal
					visible={!!salesOrderIdToPrint}
					salesOrderIds={[salesOrderIdToPrint]}
					onCancel={onInvoicePrinted}
				/>
			}
			{
				isUpdateStatusModalVisible &&
					<ECommerceUpdateOrderStatusModal
						visible={isUpdateStatusModalVisible}
						salesOrderIds={selectedSalesOrderIds}
						status={status === allOrdersStatus ? null : status}
						subStatus={subStatus}
						onComplete={(previousStatus, currentStatus, updatedSalesOrderIds) => onUpdateOrderStatus(previousStatus, currentStatus, updatedSalesOrderIds)}
						onCancel={() => setIsUpdateStatusModalVisible(false)}
						permissions={permissions}
					/>
			}
			{
				isUpdateOrdersDeliveryPartnerModalVisible &&
				<UpdateOrdersDeliveryPartnerModal
					visible={isUpdateOrdersDeliveryPartnerModalVisible}
					salesOrderIds={selectedSalesOrderIds}
					onComplete={() => {
						onPageChanged(1)
					}}
					onCancel={() => setIsUpdateOrdersDeliverPartnerModalVisible(false)}
				/>
			}
			{
				isUpdateShippingDateModalVisible &&
					<UpdateOrderShippingDateModal
						visible={isUpdateShippingDateModalVisible}
						salesOrderIds={selectedSalesOrderIds}
						onComplete={() => {
							dispatch(selectSalesOrderIds([], []))
							setIsUpdateShippingDateModalVisible(false)
							onPageChanged(1)
						}}
						onCancel={() => setIsUpdateShippingDateModalVisible(false)}
					/>
			}
			{
				isPreferenceModalVisible && permissions.viewOrderPreference ?
					<SalesOrdersPreferenceModal
						visible={isPreferenceModalVisible && permissions.viewOrderPreference}
						onCancel={() => setIsPreferenceModalVisible(false)}
					/> :
					null
			}
			{
				isPickingModalVisible &&
				<ProductPickingModal
					visible={isPickingModalVisible}
					onCancel={() => setIsPickingModalVisible(false)}
					status={status}
				/>
			}
			{
				isExportDeliveryOrdersModalVisible &&
				<ExportDeliveryOrdersModal
					visible={isExportDeliveryOrdersModalVisible}
					onCancel={() => setIsExportDeliveryOrdersModalVisible(false)}
				/>
			}
			{
				isScanSalesOrdersModalVisible &&
				<ScanSalesOrdersModal
					visible={isScanSalesOrdersModalVisible}
					onCancel={() => setIsScanSalesOrdersModalVisible(false)}
					status={status}
					subStatus={subStatus}
					onComplete={(previousStatus, currentStatus, updatedSalesOrderIds) => {
						onUpdateOrderStatus(previousStatus, currentStatus, updatedSalesOrderIds)
						setIsScanSalesOrdersModalVisible(false)
					}}
				/>
			}
		</div>
	)
}

export default ECommerceSalesOrderPage
