import moment from 'moment'
import { get, post, patch, destroy } from './index'

export const SalesOrders = {
	index: (
		{
			ids,
			page = 0,
			pageSize,
			status,
			subStatus,
			searchTerm,
			minOrderValue,
			maxOrderValue,
			minProductCount,
			maxProductCount,
			sortByOrderValue,
			startingInvoice,
			endingInvoice,
			fromDate,
			toDate,
			creationFromDate,
			creationToDate,
			shippingFromDate,
			shippingToDate,
			deliveryFromDate,
			deliveryToDate,
			deliveredAtFromDate,
			deliveredAtToDate,
			followUpFromDate,
			followUpToDate,
			statuses,
			selectedInternalNotes,
			selectedDiscounts,
			selectedUpsells,
			selectedAdvancePayments,
			accessToken,
			unassigned,
			deliveryPlanId,
			isStockTransfer,
			dateRangeType,
			salesOrderItems,
			salesOrderItemsWithProduct,
			pickUpLocationIds,
			branchLocationIds,
			preferredDeliveryPartner,
			shippingArea,
			invoicePrintStatus,
			distributorId,
			accountManagerId,
			sources,
			district,
			division,
			showSameDistributorOrders,
			flagReason,
			deliveryStatus,
			deliveryStatuses,
			excludeFullyDamagedReturnOrders,
			statusUpdateUserId,
			statusUpdate,
			includeCompany,
			sourceUrl,
			statusTransitionFromDate,
			statusTransitionToDate,
			transitionStatuses,
			customerTagId,
			customerTagIds,
			referrerId
		} = {},
		abortSignal
	) =>
		get('/sales-orders', {
			headers: {
				authorization: accessToken
			},
			params: {
				ids,
				page,
				pageSize,
				status,
				subStatus,
				searchTerm,
				minOrderValue,
				maxOrderValue,
				sortByOrderValue,
				minProductCount,
				maxProductCount,
				startingInvoice,
				endingInvoice,
				fromDate,
				toDate,
				creationFromDate,
				creationToDate,
				shippingFromDate,
				shippingToDate,
				deliveryFromDate,
				deliveryToDate,
				deliveredAtFromDate,
				deliveredAtToDate,
				followUpFromDate,
				followUpToDate,
				statuses,
				selectedInternalNotes,
				selectedDiscounts,
				selectedUpsells,
				selectedAdvancePayments,
				unassigned,
				deliveryPlanId,
				invoicePrintStatus,
				isStockTransfer,
				dateRangeType,
				salesOrderItems,
				salesOrderItemsWithProduct,
				pickUpLocationIds,
				branchLocationIds,
				preferredDeliveryPartner,
				shippingArea,
				distributorId,
				accountManagerId,
				sources,
				district,
				division,
				showSameDistributorOrders,
				flagReason,
				deliveryStatus,
				deliveryStatuses,
				excludeFullyDamagedReturnOrders,
				statusUpdateUserId,
				statusUpdate,
				includeCompany,
				sourceUrl,
				statusTransitionFromDate,
				statusTransitionToDate,
				transitionStatuses,
				customerTagId,
				customerTagIds,
				referrerId
			},
			signal: abortSignal
		}),
	single: (
		id, {
			accessToken,
			company
		} = {},
		inventory,
		inventoryItems,
		byInternalId,
		abortSignal
	) =>
		get(`/sales-orders/${id}`, {
			headers: {
				authorization: accessToken
			},
			params: {
				company,
				inventory,
				inventoryItems,
				byInternalId
			},
			signal: abortSignal
		}),
	getPagination: (
		id,
		{
			status,
			subStatus
		} = {}
	) =>
		get(`/sales-orders/${id}/pagination`, {
			params: {
				status,
				subStatus
			}
		}),
	multiple: (
		ids,
		inventory,
		{
			accessToken
		} = {},
	) =>
		post('/sales-orders/multiple',
			{
				ids,
				inventory
			},
			{
				headers: {
					'X-HTTP-Method-Override': 'GET',
					authorization: accessToken
				}
			}
		),
	counts: () =>
		get('/sales-orders/counts'),
	aggregates: ({
		ids,
		page = 0,
		pageSize,
		status,
		subStatus,
		searchTerm,
		minOrderValue,
		maxOrderValue,
		startingInvoice,
		endingInvoice,
		minProductCount,
		maxProductCount,
		fromDate,
		toDate,
		creationFromDate,
		creationToDate,
		shippingFromDate,
		shippingToDate,
		deliveryFromDate,
		deliveryToDate,
		deliveredAtFromDate,
		deliveredAtToDate,
		followUpFromDate,
		followUpToDate,
		statuses,
		invoicePrintStatus,
		selectedInternalNotes,
		selectedDiscounts,
		selectedUpsells,
		selectedAdvancePayments,
		accessToken,
		unassigned,
		deliveryPlanId,
		isStockTransfer,
		dateRangeType,
		salesOrderItems,
		salesOrderItemsWithProduct,
		pickUpLocationIds,
		branchLocationIds,
		preferredDeliveryPartner,
		shippingArea,
		distributorId,
		accountManagerId,
		sources,
		district,
		division,
		showSameDistributorOrders,
		flagReason,
		deliveryStatus,
		deliveryStatuses,
		excludeFullyDamagedReturnOrders,
		statusUpdateUserId,
		statusUpdate,
		sourceUrl,
		aggregateStatus,
		statusTransitionFromDate,
		statusTransitionToDate,
		transitionStatuses,
		adminLevel = 'district',
		customerTagId,
		customerTagIds,
		referrerId
	}, abortSignal) =>
		get('/sales-orders/aggregates', {
			headers: {
				authorization: accessToken
			},
			params: {
				ids,
				page,
				pageSize,
				status,
				subStatus,
				searchTerm,
				minOrderValue,
				maxOrderValue,
				startingInvoice,
				endingInvoice,
				minProductCount,
				maxProductCount,
				fromDate,
				toDate,
				creationFromDate,
				creationToDate,
				shippingFromDate,
				shippingToDate,
				deliveryFromDate,
				deliveryToDate,
				deliveredAtFromDate,
				deliveredAtToDate,
				followUpFromDate,
				followUpToDate,
				statuses,
				invoicePrintStatus,
				selectedInternalNotes,
				selectedDiscounts,
				selectedUpsells,
				selectedAdvancePayments,
				accessToken,
				unassigned,
				deliveryPlanId,
				isStockTransfer,
				dateRangeType,
				salesOrderItems,
				salesOrderItemsWithProduct,
				pickUpLocationIds,
				branchLocationIds,
				preferredDeliveryPartner,
				shippingArea,
				distributorId,
				accountManagerId,
				sources,
				district,
				division,
				showSameDistributorOrders,
				flagReason,
				deliveryStatus,
				deliveryStatuses,
				excludeFullyDamagedReturnOrders,
				statusUpdateUserId,
				statusUpdate,
				sourceUrl,
				aggregateStatus,
				statusTransitionFromDate,
				statusTransitionToDate,
				transitionStatuses,
				adminLevel,
				customerTagId,
				customerTagIds,
				referrerId
			},
			signal: abortSignal
		}),
	aggregatesByPartiallyDeliveredSalesOrders: () =>
		get('/sales-orders/aggregates/delivery-partners/partial-delivery'),
	districtsBySalesOrders: ({
		adminLevel = 'district',
		statuses,
		status,
		sortBy,
		sortOrder,
		maxRows,
		fromDate,
		toDate,
		timezone,
		locationIds
	} = {}, abortSignal) =>
		get('/sales-orders/districts', {
			params: {
				adminLevel,
				statuses,
				status,
				sortBy,
				sortOrder,
				maxRows,
				fromDate,
				toDate,
				timezone,
				locationIds
			},
			signal: abortSignal
		}),
	administrativeLevelsBySalesOrders: ({
		adminLevel = 'district',
		statuses,
		status,
		sortBy,
		sortOrder,
		maxRows,
		fromDate,
		toDate,
		timezone,
		locationIds
	} = {}, abortSignal) =>
		get('/sales-orders/administrative-levels', {
			params: {
				adminLevel,
				statuses,
				status,
				sortBy,
				sortOrder,
				maxRows,
				fromDate,
				toDate,
				timezone,
				locationIds
			},
			signal: abortSignal
		}),
	customerOrderCounts: (id) =>
		get(`/sales-orders/counts?distributorId=${id}`),
	updateStatus: (ids, status, subStatus, flagReason = null) =>
		patch('/sales-orders/status', {
			ids,
			status,
			subStatus,
			flagReason
		}),
	create: (params) =>
		post('/sales-orders', params),
	update: (id, {
		status,
		subStatus,
		flagReason,
		customerId,
		deliveryDate,
		orderDate,
		paymentMethod,
		inventoryBatchItemId,
		approvedQuantity,
		approvedPackageQuantity,
		distributorAdvancePayment,
		transportFareAmount,
		additionalNotes,
		internalNotes,
		salesOrderItems,
		internalId,
		locationId,
		location,
		pickUpLocationId,
		branchLocationId,
		attachments,
		discount,
		discountAmount,
		deliveryCharge,
		createSubSalesOrder,
		disableAutoApproveSubSalesOrder,
		preferredDeliveryPartner,
		createReturnSalesOrder,
		createDamagedSalesOrder,
		createExpiredSalesOrder,
		source,
		deliveryArea,
		deliveryConsignment,
		autoApprove,
		totalPaidAmount,
		followUpDate,
		onHoldReason,
		isDeliveryFree,
		createDeliveryOrder,
		returnedSalesOrderItems,
		referrerId,
		bankName,
		bankAccountName,
		bankAccountNumber,
		metadata
	} = {}) =>
		patch(`/sales-orders/${id}`, {
			status,
			subStatus,
			flagReason,
			customerId,
			deliveryDate,
			orderDate,
			paymentMethod,
			inventoryBatchItemId,
			approvedPackageQuantity,
			approvedQuantity,
			distributorAdvancePayment,
			transportFareAmount,
			additionalNotes,
			internalNotes,
			salesOrderItems,
			internalId,
			locationId,
			location,
			pickUpLocationId,
			branchLocationId,
			attachments,
			discount,
			discountAmount,
			deliveryCharge,
			createSubSalesOrder,
			disableAutoApproveSubSalesOrder,
			preferredDeliveryPartner,
			createReturnSalesOrder,
			createDamagedSalesOrder,
			createExpiredSalesOrder,
			source,
			deliveryArea,
			deliveryConsignment,
			autoApprove,
			totalPaidAmount,
			followUpDate,
			onHoldReason,
			isDeliveryFree,
			createDeliveryOrder,
			returnedSalesOrderItems,
			referrerId,
			bankName,
			bankAccountName,
			bankAccountNumber,
			metadata
		}),
	updateOrders: (orders = []) =>
		patch('sales-orders/multiple', { orders }),
	approveOrders: ({ ids, checkInventory }) =>
		patch('sales-orders/multiple/approve', { ids, checkInventory }),
	remove: (id) =>
		destroy(`/sales-orders/${id}`),
	duplicateDistributorIds: (params, abortSignal) =>
		get('/sales-orders/duplicate-distributors', {
			params,
			abortSignal
		}),
	downloadAttachment: async (downloadUrl, fileName) => {
		const response = await get(downloadUrl, {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = fileName
		link.click()
		link.remove()
	},
	downloadCSV: async ({
		ids,
		statuses,
		toDate,
		fromDate,
		isStockTransfer,
		dateRangeType,
		columns,
		distributorId,
		preferredDeliveryPartner,
		isDownloadingSummary,
		isDownloadingPickingProducts,
		preferredDeliveryPartners,
		sources
	}) => {
		const response = await post('/sales-orders/csv', {
			ids,
			statuses,
			sources,
			toDate,
			fromDate,
			isStockTransfer,
			dateRangeType,
			columns,
			distributorId,
			preferredDeliveryPartner,
			preferredDeliveryPartners,
			isDownloadingSummary,
			isDownloadingPickingProducts,
			timezone: moment.tz.guess()
		})
		const { data } = response
		const link = document.createElement('a')
		link.href = data.downloadUrl
		let fileName = 'sales-orders.csv'
		if (ids) {
			fileName = `sales-orders_selected-${ids.length}_${moment().format('MMM_DD_YYYY')}.csv`
		}
		if (statuses) {
			fileName = `sales-orders_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}_${statuses.join('_')}.csv`
		}
		if (distributorId) {
			fileName = `customer_sales_report_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}_${distributorId}.csv`
		}
		link.download = fileName
		link.click()
		link.remove()
	},
	fetchOrderStatusFlows: () => {
		return get('/sales-orders/status-flows')
	},
	sales: ({
		page = 0,
		pageSize,
		status,
		subStatus,
		searchTerm,
		minOrderValue,
		maxOrderValue,
		fromDate,
		toDate,
		creationFromDate,
		creationToDate,
		shippingFromDate,
		shippingToDate,
		deliveryFromDate,
		deliveryToDate,
		statuses,
		selectedInternalNotes,
		accessToken,
		unassigned,
		deliveryPlanId,
		isStockTransfer,
		dateRangeType,
		salesOrderItems,
		salesOrderItemsWithProduct,
		pickUpLocationIds,
		preferredDeliveryPartner,
		shippingArea,
		distributorId,
		accountManagerId,
		sources,
		district,
		showSameDistributorOrders,
		flagReason
	}) =>
		get('sales-orders/sales', {
			params: {
				page,
				pageSize,
				status,
				subStatus,
				searchTerm,
				minOrderValue,
				maxOrderValue,
				fromDate,
				toDate,
				creationFromDate,
				creationToDate,
				shippingFromDate,
				shippingToDate,
				deliveryFromDate,
				deliveryToDate,
				statuses: statuses?.toString(),
				selectedInternalNotes,
				accessToken,
				unassigned,
				deliveryPlanId,
				isStockTransfer,
				dateRangeType,
				salesOrderItems,
				salesOrderItemsWithProduct,
				pickUpLocationIds,
				preferredDeliveryPartner,
				shippingArea,
				distributorId,
				accountManagerId,
				sources,
				district,
				showSameDistributorOrders,
				flagReason
			}
		}),
	updatePrintStatuses: (ids) =>
		patch('/sales-orders/print', {
			ids
		}),
	getSourceUrls: () =>
		get('sales-orders/source-urls'),
	getPickingProducts: ({ status }) =>
		get('sales-orders/picking', { params: { status } })
}

export default SalesOrders
