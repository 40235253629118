import moment from 'moment'
import { destroy, get, patch, post } from './index'

export const Distributors = {
	index: (
		{
			page = 0,
			disabled,
			searchTerm,
			fromDate,
			toDate,
			lastOrderFromDate,
			lastOrderToDate,
			sentSmsFromDate,
			sentSmsToDate,
			sentSmsStatuses,
			noOrderSinceDate,
			type,
			types,
			accountManagerId,
			topCustomers,
			interval,
			customerTagId,
			tag,
			district,
			division,
			minOrderFrequency,
			maxOrderFrequency,
			minSmsCount,
			maxSmsCount,
			minSalesAmount,
			maxSalesAmount,
			sortLastOrderCreatedAt,
			salesOrderCount,
			pageSize,
			statuses,
			phone,
			productId,
			sources
		} = {},
		abortSignal
	) =>
		get('/distributors', {
			params: {
				searchTerm,
				page,
				disabled,
				fromDate,
				toDate,
				lastOrderFromDate,
				lastOrderToDate,
				sentSmsFromDate,
				sentSmsToDate,
				sentSmsStatuses,
				noOrderSinceDate,
				type,
				types,
				accountManagerId,
				topCustomers,
				interval,
				customerTagId,
				tag,
				district,
				division,
				minOrderFrequency,
				maxOrderFrequency,
				minSmsCount,
				maxSmsCount,
				minSalesAmount,
				maxSalesAmount,
				sortLastOrderCreatedAt,
				salesOrderCount,
				pageSize,
				statuses,
				phone,
				productId,
				sources
			},
			signal: abortSignal
		}),
	single: (id) =>
		get(`/distributors/${id}`),
	create: (params) =>
		post('/distributors', params),
	update: (id, params) =>
		patch(`/distributors/${id}`, params),
	remove: (id) =>
		destroy(`/distributors/${id}`),
	metrics: ({ type } = {}) =>
		get('/distributors/metrics', {
			params: {
				type
			}
		}),
	trend: ({
		year
	} = {}) =>
		get('/distributors/metrics/trend', {
			params: {
				year
			}
		}),
	distributorMetrics: (id, timezone = 'Asia/Dhaka') =>
		get(`/distributors/${id}/metrics`, {
			params: {
				timezone
			}
		}),
	topCustomers: (filter, fromDate, toDate, status, locationIds) =>
		get('/distributors/top-customers', {
			params: {
				filter,
				fromDate,
				toDate,
				status,
				locationIds
			}
		}),
	downloadTopCustomers: async (filter, fromDate, toDate, status, locationIds) => {
		const response = await get('/distributors/top-customers/csv', {
			responseType: 'blob',
			params: {
				filter,
				fromDate,
				toDate,
				status,
				locationIds,
				timezone: moment.tz.guess()
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `top_customers_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadCSV: async ({
		type,
		fromDate,
		toDate,
		lastOrderFromDate,
		lastOrderToDate,
		noOrderSinceDate,
		customerTagIds,
		districts,
		minOrderFrequency,
		maxOrderFrequency,
		minSalesAmount,
		maxSalesAmount,
		statuses,
		smsCount,
		productId,
		sources
	} = {}) => {
		const response = await get('/distributors/csv', {
			responseType: 'blob',
			params: {
				fromDate,
				toDate,
				lastOrderFromDate,
				lastOrderToDate,
				noOrderSinceDate,
				customerTagIds,
				type,
				districts,
				minOrderFrequency,
				maxOrderFrequency,
				minSalesAmount,
				maxSalesAmount,
				statuses,
				smsCount,
				productId,
				sources
			}
		})
		const link = document.createElement('a')
		var binaryData = []
		binaryData.push(response.data)
		link.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }))
		let fileName = `customers_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}.csv`
		link.download = fileName
		link.click()
		link.remove()
	},
	customerTags: ({ name, includeDefaultTags, defaultTagsOnly }) =>
		get('/distributors/customer-tags', {
			params: { name, includeDefaultTags, defaultTagsOnly }
		}),
	createCustomerTag: ({ name }) => post('/distributors/customer-tags', { name }),
	updateCustomerTags: ({ tags }) => patch('/distributors/customer-tags', { tags }),
	sendSms: ({ ids, message }) => post('/distributors/sms', { ids, message }),
	districts: () => get('/distributors/districts')
}
